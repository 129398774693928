<template>
  <div class="language-selector" v-if="selectedLang">
    <div class="selected-language lang-item" @click="expandSelector">
      <span class="lang-flag"><img :src="'/data/flags/' + selectedLang.code + '.png'"/></span>
      <span class="lang-title">{{ selectedLang.name }}</span>
      </div>
      
      <div class="expanded-selector" :class="{visible: selectorExpanded}">
       <header class="selector-header">
          <a href="javascript:;" class="back-arrow-btn" @click="selectorBack">{{ $t('common_back') }}</a>
        </header>
        <custom-scrollbar  class="languages-scroll" :class="{visible: selectorExpanded}" :autoHide="$isMobile() ? true : false">
          <ul class="root-list" v-show="!expandedLanguage">
            <li class="root-list__item lang-item lang-item--inlist"
                v-for="(lang, index ) in languages" :key="'lang_' + index"
                @click="clickLanguage(lang)"
            >
              <span class="lang-flag"><img :src="'/data/flags/' + getLangFlag(lang) + '.png'"/></span>
              <span class="lang-title">{{ lang.name }}</span>
              <span class="lang-arrow" v-if="hasDialects(lang)"><svg width="12" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.2325 0L0 2.2325L7.25167 9.5L0 16.7675L2.2325 19L11.7325 9.5L2.2325 0Z" fill="#505050"/>
                </svg>
              </span>
            </li>
          </ul>

          <div class="expanded-language"
               v-for="(lang, index) in languages"
               :key="'dialects_' + index"
               :class="{ visible: expandedLanguage && expandedLanguage == lang.code }"
               v-show="expandedLanguage && expandedLanguage == lang.code"
          >
            <ul class="child-list" v-if="hasDialects(lang)" :class="{visible: expandedLanguage && expandedLanguage == lang.code}"
            >
              <li class="root-list__item lang-item lang-item--inlist"
                  v-for="(dialect, index ) in lang.dialects" :key="'dialect_' + index"
                  @click.stop="clickLanguage(dialect)"
              >
                <span class="lang-flag"><img :src="'/data/flags/' + dialect.code + '.png'"/></span>
                <span class="lang-title">{{ dialect.name }}</span>
              </li>
            </ul>
          </div>
        </custom-scrollbar>
        

      </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
// @ is an alias to /src
//import { mapState } from 'vuex';

export default {
  name: 'LanguageSelector',
  props: {
    initial: {
      type: String,
      required: true,
      default: "en-US"
    },
    open: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  components: {
    
  },
  data() {
    return {
      selected: false,
      selectorExpanded: this.open,
      expandedLanguage: false,
    }
  },
  methods: {
    expandSelector() {
      this.selectorExpanded = true;
      this.$emit('expanded');
    },
    clickLanguage(lang) {
      if (this.hasDialects(lang)) {
        this.setExpandedLanguage(lang.code);
      } else {
        if (lang.dialects) {
          this.selected = lang.dialects[0].code;
        } else {
          this.selected = lang.code;
        }
        this.$emit('select', this.selected)
        this.selectorExpanded = false;
        this.setExpandedLanguage(false);
      }
    },
    getLangFlag(lang) {
      return lang.code;
    },
    selectorBack() {
      if (this.expandedLanguage) {
        this.expandedLanguage = false;
        
      } else {
        this.selectorExpanded = false;
        this.$emit('back');

        if (this.open) {
          this.$emit('globalback');
        }
      }
    },
    hasDialects(lang) {
      return lang.dialects && lang.dialects.length > 1;
    },
    setExpandedLanguage(expanded) {
      this.expandedLanguage = expanded;
    }
  },
  mounted() {
  },
  computed: {
    ...mapState('lang', ['languages']),
    
     selectedLang() {
      let selectedLang = false;
      //let selectedLangCode = this.selected;
      
      this.languages.forEach(lang => {
        if (lang.code == this.selectedLangCode && lang.dialects.length <= 1) {
          selectedLang = lang;
          return;
        } else {
          lang.dialects.forEach(dialect => {
            if (dialect.code == this.selectedLangCode) {
              selectedLang = dialect;
              return;
            }
          });
        }
      });
      return selectedLang;
     },
     selectedLangCode() {
      return this.selected ? this.selected : this.initial
     }
  }
}
</script>

<style lang="scss" scoped>
  .lang-item {
    display: flex;
    align-items: center;
    cursor: pointer;

    &--inlist {
      border-bottom: 1px solid #505050;
      height: 44px;
      
    }
  }

  .lang-item.selected-language {
    border: 2px solid #505050;
    border-radius: 10px;
    height: 44px;
    padding-left: 10px;
  }

  .lang-flag {
    height: 20px;
    display: flex;
    max-width: 30px;

    img {
      max-width: 100%;
    }
  }

  .lang-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.18rem;
    color: #fff;
    margin-left: 10px;
  }

  .lang-arrow {
    display: flex;
    margin-left: auto;
    margin-right: 10px;
  }

  .expanded-selector {
    position: absolute;
    top: 0;
    z-index: 2;
    background: #1D1C1C;
    width:115%;
    height: 100%;
    opacity: 0;
    visibility: hidden;

    @media all and (max-width: 1100px) {
      width: 100%;
    }

    &.visible {
      opacity: 1;
      visibility: visible;
    }

    ul {
      margin: 0;
      padding: 0;

      li:last-child {
        border-bottom: none;
      }
    }
  }

  .ps {
    height: 100%;
    opacity: 0;
    visibility: hidden;
    border: 1px solid #505050;
    border-radius: 10px;
    padding: 0 0 0 10px;

    &.visible {
      opacity: 1;
      visibility: visible;
    }
  }



  .root-list {
    /*margin-right: 10px !important;*/
  }

  .child-list {
    z-index: 2;
    /*background: #1D1C1C;*/
    width:100%;
    height: 100%;
    left: 0;
    padding-right: 10px !important;
  }

  .profile__container {
    padding-top: 70px;
  }

  .profile-form {
  }

  .expanded-language {
    width: 100%;
    visibility: hidden;

    &.visible {
      opacity: 1;
      visibility: visible;
    }
  }


  .selector-header {
    margin-bottom: 15px;
  }

  @media all and (max-width: 1100px) {
    .selector-header {
      padding-left: 10px;
      padding: 15px 10px 15px;
      position: sticky;
      top: 0;
      z-index: 1000;
      background: #1D1C1C;
      margin: 0;
    }
  }
  

</style>
