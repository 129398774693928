import { i18n } from "@/plugins/i18n";
import Swal from 'sweetalert2';

export default {
  install: (app, config) => {
    app.config.globalProperties.$notify = (key, opts = {}) => {
      const defaults = {
        icon: "warning",
        toast: false,
        backdrop: true,
        allowOutsideClick: true,
        allowEscapeKey: true,
        showConfirmButton: true,
        showCancelButton: false,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        focusCancel: false,
        position: "center",
        messageOverride: null,
      };
      const params = { ...defaults, ...opts }
      let message = i18n.global.t('error_unknown');
      if (i18n.global.te(key)) {
        message = i18n.global.t(key);
      } else if (i18n.global.te('error_' + key)) {
        message = i18n.global.t('error_' + key);
      } else {
        message += " (" + key + ")";
      }



      if (params.toast) {
        params.position = "bottom-end";
        params.timer = 1500;
        params.showConfirmButton = false;
        params.backdrop = false;
      }

      let promise = Swal.fire({
        ...params,
        text: params.messageOverride || message,
        allowOutsideClick: params.allowOutsideClick,
        allowEscapeKey: params.allowOutsideClick,
        icon: params.icon,
        focusCancel: params.focusCancel,
        backdrop: params.backdrop,
        toast: params.toast,
        timer: params.timer,
        position: params.position,
        showConfirmButton: params.showConfirmButton,
        showCancelButton: params.showCancelButton,
        cancelButtonText: params.cancelButtonText,
        allowEnterKey: false,
        confirmButtonText: params.confirmButtonText,
      }).then((result) => {
        if (!params.allowOutsideClick) {
          console.log("blocking")
        }
        if (params.confirmRedirect) {
          config.router.push(params.confirmRedirect);
        }

        if (params.confirmReload) {
          location.reload();
        }

        return Promise.resolve(result);
      });

      return promise;
    };

    app.config.globalProperties.$notificationClose = () => {
      Swal.close();
    };
  },
}
