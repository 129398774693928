import LanguagesService from '../services/languages.service';

export const lang = {
  namespaced: true,
  state: {
    languages: []
  },
  actions: {
    get({ commit, rootState }) {
      const asr_version = rootState.hidden.hidden_settings.asr_version ? rootState.hidden.hidden_settings.asr_version : "v1";

      return LanguagesService.getArray(asr_version).then(
        response => {
          commit('setLanguages', response.data.result);
        },
        error => {
          commit('loginFailure', error);
        }
      );
    },
  },
  getters: {
    getLanguages(state) {
      return state.languages;
    }
  },
  mutations: {
    setLanguages(state, languages) {
      state.languages = languages;
    }
  }
}