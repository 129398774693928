import AuthService from '../services/auth.service';
//import { createLocal } from 'the-storages';
import router from "@/router/index";
import { sha256 } from 'js-sha256';

export const auth = {
  namespaced: true,
  state: {
    user: {},
    authorized: false,
    agreed: false,
    token: "",
    client_user: {},
    priceId: null,
    tutorialShown: true,
    passwordResetStatus: "",
  },
  actions: {
    login({ commit }, user) {
      return AuthService.vanillaLogin(user).then(
        response => {
          commit('loginSuccess', { 
            email: user.email, 
            token: response.data.result.token,
            authorized: true
          }, true);
          commit('event/setSettings', false, { root: true });
          this.dispatch('auth/userMe', {
            token: response.data.result.token,
            room: false
          });
          return Promise.resolve(user);
        },
        error => {
          commit('loginFailure');
          return Promise.reject(error);
        }
      );
    },
    logout({ commit }) {
      commit('logout');
      commit('event/setSettings', false, { root: true });
    },
    register({ commit }, user) {
      return AuthService.vanillaRegister(user).then(
        response => {
          commit('registerSuccess', { 
            email: user.email, 
            name: user.name,
            email_verification_required: response.data.result.email_verification_required,
            authorized: false
          });
          commit('setToken', response.data.result.token);
          return Promise.resolve(response.data);
        },
        error => {
          commit('registerFailure', error);
          return Promise.reject(error);
        }
      );
    },
    googleLogin({ commit }, { googleResponse, room}) {
      return AuthService.googleLogin(googleResponse).then(
        response => {
            //console.log(response);
            if (!room) {
              commit('loginSuccess', {
                  authorized: true,
                }
              );
              commit('event/setSettings', false, { root: true });
            } else {
              commit('roomLoginSuccess', room);
              commit('event/setSettings', false, { root: true });
            }
            
            this.dispatch('auth/userMe', {
              token: response.data.result.token,
              room: room
            });
          return Promise.resolve(response.data);
        },
        error => {
          commit('registerFailure', error);
          return Promise.reject(error);
        }
      );
    },
    appleLogin({ commit }, { appleResponse, room}) {
      return AuthService.appleLogin(appleResponse).then(
        response => {
            //console.log(response);
            if (!room) {
              commit('loginSuccess', {
                  authorized: true,
                }
              );
              commit('event/setSettings', false, { root: true });
            } else {
              commit('roomLoginSuccess');
              commit('event/setSettings', false, { root: true });
            }
            this.dispatch('auth/userMe', {
              token: response.data.result.token,
              room: room
            });
          return Promise.resolve(response.data);
        },
        error => {
          commit('registerFailure');
          return Promise.reject(error);
        }
      );
    },
    userMe({ commit, rootState}, { token, room}) {
      return AuthService.userMe(token).then(
        response => {
          if (response.data.result.is_activated) {
            commit('setAuthorized', true);
          }
          
          commit('nonpersist/setUser', response.data.result, { root: true });
          commit('setToken', response.data.result.token);
          commit('nonpersist/setPrefetched', true, { root: true});
          commit('nonpersist/setAsrTimeLeft', response.data.result.features.asr.left_allowed_items, { root: true});

            const shortLang = rootState.locale.locale ? rootState.locale.locale : "en";

            window.pendo.identify({
                visitor: {
                    id: response.data.result.email,
                    "forum_language": shortLang,
                },
                account: {}
            });

          if (room) {
            router.go();
          }
          return Promise.resolve(response.data);
        },
        error => {
          this.dispatch("auth/logout");
          commit('nonpersist/setPrefetched', true, { root: true});

          return Promise.reject(error);
        }
      );
    },
    clearUserMe({ commit }) {
      commit('clearUser');
    },
    update({commit}, user) {
      return AuthService.updateProfile(user).then(
        response => {
          commit('nonpersist/setUser', user, { root: true });
          return Promise.resolve(response.data);
        },
        error => {
          
          return Promise.reject(error);
        }
      );
    },
    getResetPasswordVerifyCode({commit}, {email, code}) {
      return AuthService.resetPasswordVerifyCode(email, code).then(
        response => {
          commit('resetPasswordTokenCreated');
          return Promise.resolve(response);
        },
        error => {
          commit('resetPasswordTokenCreationFailure');
          return Promise.reject(error);
        }
      );
    },
    resetNewPassword({commit}, user) {
      return AuthService.setNewPassword(user).then(
        response => {
          commit('passwordResetSuccess');
          return Promise.resolve(response.data);
        },
        error => {
          commit('passwordResetFailure');
          return Promise.reject(error);
        }
      );
    },
    delete({commit}, user) {
        return AuthService.deleteAccount(user).then(
            response => {
                commit('logout');
                return Promise.resolve(response.data);
            },
            error => {

                return Promise.reject(error);
            }
        );
    }
  },
  mutations: {
    loginSuccess(state, user) {
      state.authorized = true;
      state.client_user = user;
      const pathToGo = router.currentRoute.value.query.from ? "/" + router.currentRoute.value.query.from : "/";
      router.push({ path: pathToGo});
      
    },
    loginFailure(state) {
      state.authorized = false;
    },
    roomLoginSuccess(state) {
      state.authorized = true;
    },
    logout(state) {
      state.authorized = false;
      state.user = null;
      state.token = null;
      state.client_user = null;
      
      router.push({ path: '/'});
    },
    clearUser(state) {
      state.user = {};
    },
    registerSuccess(state, userDetails) {
      state.authorized = false;
      state.client_user = userDetails;
      if (!userDetails.email_verification_required) {
          const pathToGo = router.currentRoute.value.query.from ? "/" + router.currentRoute.value.query.from : "/";
          router.push({ path: pathToGo});
      }
    },
    registerFailure(state) {
      state.authorized = false;
      /*this.$notify(error.response.data.error, {
        icon: "error",
        toast: true
      });*/
    },
    setToken(state, token) {
      state.token = token;
      //storage.set('token', token);
    },
    setAuthorized(state, status) {
      state.authorized = status;
    },
    setAgreed(state, agreed) {
      state.agreed = agreed;
    },
    setPriceId(state, priceId) {
        state.priceId = priceId;
    },
    setTutorialShown(state, bool) {
      state.tutorialShown = bool;
    },
    passwordResetSuccess(state) {
      state.passwordResetStatus = "success";
    },
    passwordResetFailure(state) {
      state.passwordResetStatus = "failure";
    },
    resetPasswordTokenCreated(state) {
      state.passwordTokenCreatedStatus = "success";
    },
    resetPasswordTokenCreatedFailure(state) {
      state.passwordTokenCreatedStatus = "failure";
    },
  },
  getters: {
    isAuthorized(state) {
      return state.authorized
    },

    udid() {
      return sha256('web' + new Date().getTime());
    }
  }
}