<template>

  <background-video />
  <div class="z-10 flex m-auto flex-col items-center justify-center gap-8">
    <div class="max-w-7/10">
      <img src="../assets/logo.svg" alt="Sorenson Forum" />
    </div>
    <div class="flex flex-col items-center justify-center gap-6">
      <router-link to="/join" id="homepage_join_session" class="button-primary">
        <img src="../assets/icons/media/headphones-outline.svg" alt="Join">
        {{ $t('home_join')}}
      </router-link>
      <span class="text-white text-2xl uppercase">{{ $t('common_or') }}</span>
      <router-link id="homepage_host_session" to="/host" class="flex items-center justify-center gap-2 text-primary text-xl">
        <img src="../assets/icons/media/microphone-outline.svg" alt="Host">
        {{ $t('home_host')}}
      </router-link>
    </div>

    <div class="flex flex-col items-center justify-center gap-6 mt-16 px-[4%] max-w-[70%]">
      <p class="text-white text-sm lg:text-lg text-center" v-html="$t('home_footer_cite')">
      </p>
      <router-link to="/start" class="text-xl text-primary">{{ $t('home_footer_cite_sub') }}</router-link>
    </div>
  </div>

  <main-footer></main-footer>


</template>

<script>
// @ is an alias to /src
 import MainFooter from '@/components/MainFooter.vue';
import BackgroundVideo from "@/components/ui/BackgroundVideo.vue";
import {mapMutations, mapState} from "vuex";

export default {
  name: 'HomeView',
  components: {
    MainFooter,
    BackgroundVideo
  },
  methods: {
    ...mapMutations('nonpersist', ['setShowGuide']),
  },
  computed: {
    ...mapState('nonpersist', ['showGuide']),
  },
  mounted() {
    if (this.showGuide) {
      console.log("guide");
      setTimeout(() => {
        window.pendo.showGuideById('A8qrMfEEgZ2Fy2GOGaNgpZPoBN8');
        this.setShowGuide(false);
      }, 1000
    );
  }}
}
</script>

<style lang="scss" scoped>

  .center-logo {
    width: 670px;

    @media all and (max-height: 800px) {
      max-width: 550px;
    }
  }

  .home__actions {
    display: flex;
    flex-direction: column;
    align-items: center;

    a:hover {
      opacity: 0.9;
    }

    @media all and (max-height: 800px) {
      .orange-btn {
        height: 70px;

        img {
          width: 45px;
        }
      }
    }

    
  }

  .home__actions-or {
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.875rem;
    text-transform: uppercase;

    color: #C2C2C2;
    margin: 21px auto 10px;
  }

  .home__slogan {
    color: #fff;
    font-size: 1.8rem;
    text-align: center;
  }
</style>
