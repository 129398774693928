<template>
  <router-link to="/" class="icon-btn conference-close">
    <img src="@/assets/icons/xmark.svg" alt="Close">
  </router-link>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "CloseButton",
});
</script>

<style lang="scss" scoped></style>