export default new class PermissionService {
    features = {
        asr: {
            allowed: true,
            continuous_recording: false,
            real_time_translation: false
        },
        tts: {
            allowed: true
        },
        custom_terms: {
            allowed: false
        },
        closed_captioning: {
            allowed: false
        },
        green_screen: {
            allowed: false
        },
        host_event: {
            allowed: false
        },
        transcripts: {
            allowed: true
        },
        keyboard: {
            allowed: false
        }
    };

    can(feature, user) {
        if (!user.features) {
            user.features = this.features;
        }
        let allowed = false;
        if (user.features[feature]) {
            allowed = user.features[feature].allowed;
        } else {
            allowed = this.getNestedPermission(user.features, feature);
        }

        return allowed;
    }

    getNestedPermission(obj, key) {
        return key.split(".").reduce(function(result, key) {
           return result[key] 
        }, obj);
    }
}
