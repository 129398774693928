<template>
  <background-video />
  <div class="signup centered-container">
    <div class="signup__title">
      <h1 class="heading_1">{{ heading }}</h1>
      <div class="signup__email" v-if="storageUser && storageUser.email && registrationSucess">{{ storageUser.email }}</div>
    </div>
    
    <div class="signup__container orange-bordered" :class="{ emailConfirm: registrationSucess }">
      <div class="email-confirmation" v-if="registrationSucess">
        <div class="email-confirmation__text"><svg width="16" height="22" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <rect width="16" height="22" fill="url(#pattern0)"/>
          <defs>
          <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use xlink:href="#image0_371_438" transform="translate(0 0.136364) scale(0.0104167 0.00757576)"/>
          </pattern>
          <image id="image0_371_438" width="96" height="96" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAABmJLR0QA/wD/AP+gvaeTAAAFNElEQVR4nO2cTYgcVRSFz2uFUTdBRndCMCDEZGeIK1ciSOJOGEUQjIiBIOoqutSlxlUEk6jEjCs1Iy7EKCK4cmNw6SAoMwgu86MuNBNw5nPxprGnrequqr7vp3reBwNDd/V7p+6tPlV16/WVCoVCoVAwBDgIHEitow8MAo27JOmJQGMXpgGsAj+l1rEr2bafIcWGphDCgpZG/i82FJtt+xlSbCgmY/ZTbKgB1ha0VPFasaFYjNlPsaGY1NhPsaEpWFpQlf0MKTYUmhr7KTYUgyn2U2xoAlYWNMl+hhQbCsUU+yk2FJKG9lNsKATbwb/YIgEXgYMZ6D4MHE6toxPbQX+9oe3UsQacBh5KtA/LwIUUc3fCKOhZJANYBG4AG8DdMebsROCgJ0sG8MrIfCdDzdOJREGvwzwZwABYH5njV+AWq/G7isop6HWYJAN4rGLso1ax7MJA0g1Jv2//5cqGvL7rM47zQsPX0gDsA14Gvot4dNexiv9mmtw74Pdts2KeLeA+izlMIU0yTIM+tj9vTZj3lPV8phA2GcGCPqL/duDqBA3XgTtCzW+KUTKCB31M87MNNB2LocUUelKKAC430HY5ti4TgP0tEhC9GAc82EJfb+tDTe4hfkykbblFAqLXh6weyKwYbWMKsCjpyRYfeYrI9SGrBHzcYJvoCZD0nKTbWmy/IKl/J2Npqg1Ftx/+X/dpStT6kOWylElHeIqj/4ikezt8bq+kR421hIfJV0Mprn6+7HD0D7kUW68JVNtQCvupq/s0JVp9yHpxbpXVpLCfE5pt35yk5420xINqG4pqP0yv+zSlP/WhUdhpQynsp0ndpynBL0lD/ERppeb/WJwwHOtFw7HiwE4bilXtXACWCFMu/wE4Tp/sCG9Dwe0Hf7XzBnAlQODH+QP/XHpf6P2aGXyt/7VAYw+AR/Cl7X8iBH6cTeAb/Dcu7YqKOvDPCUzr/vgFVSfxKyRyYQ2vadFyX7MCOAS8C/yVNNST2cB/I5MstTSHsCfV0LQ6abvQwWwD/gR3XL6MfFdiObPyp6QPJZ12zq3XbZQ8AcBA0sPygX9cUp4ntu5sSfpW0nuSPnPObY6+mSwBwB75hx8vScr/0s6GdflEnHfOXZUSJAA4JH+0Py2pPzc3ttyU9Lmkt0M1bCo0pFhQXNJb0DjlJJwRu/EyNEvYRTdi2UMpReQBsAe/Kju3YtyrQN/tsjmUcnQ+UB7I5AHlkeROSNg7mmY/xmjK96H1zmPv6DOZjlVJkBsxYFXSwDl3f4jxp8y9IOk3SbOu878m6R7n3Mbsquox/wbgnwUfkLQ/hQ05525KWjYY6nzo4Evz2zv6HUmbU7eqZ0vSOSMtcSGT3tHApRlOvl+k0j0TZNQ7Gjg6QwKOxNI5z72jv5L0S4fPrUv62lhLLTES0OZXimY455D0foePnnXObVnrCU6N/aS2oTtpV0X9mz6ucmN6y4Jk3RKBCy0SMPeN/KJ3SwQeaKEv71YFHYOePBk0qw8Fr/t0wjjoSZIBHGug4ZkQc0+jyVVQjJ5yVj3h6vhI0pUJ71+T9Emgue3AtnNW7KZNpyZoeTOGBlM6JiNq0Mf07qX6EeYm0KWlQT6Q8WXomM6q+lA/2xKMQ2a9Iqqguj4Ure4THDLpFVEH4ICfR7St4ZdGJmNee0VUUlEf6mfdp44aG8rCfobwX32on3WfaZC4V0QT8PWhD1LrkML3ivg0wPgWnJF0NrWIIJCgV0RhDCL1ipgHbg007ookAo09V4RMQKFQKBQKhQn8C5/YpNe3Fg3fAAAAAElFTkSuQmCC"/>
          </defs>
          </svg>
          {{ $t('email_confirmation_message') }}
        </div>
        <a href="javascript:;" class="email-confirmation__resend orange-link text-transform-none" @click="resend">{{ $t('email_confirmation_resend') }}</a>
      </div>
      <div class="signup-form form form-default" v-else>
        <form action="#" method="POST" @submit.prevent="formRequest">
          <div class="form-row">
            <label for="#signup_name">{{ $t('profile_name') }}</label>
            <input type="text" required name="name" v-model="user.name" class="form-input form-input--text" placeholder="" id="signup_name">
          </div>
          <div class="form-row">
            <label for="#signup_email">{{ $t('profile_email') }}</label>
            <input type="email" required name="email" v-model="user.email" class="form-input form-input--text" placeholder="" id="signup_email">
          </div>
          <div class="form-row">
            <label for="#signup_password">{{ $t('profile_password') }}</label>
            <input type="password" required class="form-input form-input--text" name="password" v-model="user.password" placeholder="" id="signup_password">
          </div>
          <div class="form-row">
            <label for="#signup_retype_password" ref="retype_password_label">{{ $t('profile_retype_password') }}</label>
            <input type="password" @keyup="passwordsMatchCheck" required name="retype_password" ref="retype_password_input" v-model="user.retype_password" class="form-input form-input--text" placeholder="" id="signup_retype_password">
          </div>
          <button type="submit" name="signup_submit" id="signup_submit" class="orange-btn form-btn" value="Sign up">{{ $t('signup_title')}}</button>
          <div class="or-divider">or</div>
          <router-link to="/start" class="orange-link orange-link--inline">{{ $t('signin_title') }}</router-link>
        </form>
      </div>
    </div>
  </div>
  <main-footer></main-footer>
  
</template>

<script>
// @ is an alias to /src
 import MainFooter from '@/components/MainFooter.vue';
 import AuthService from '../services/auth.service';
 import { mapState } from 'vuex';
import BackgroundVideo from "@/components/ui/BackgroundVideo.vue";



export default {
  name: 'SignupView',
  components: {
    BackgroundVideo,
    MainFooter
  },
  data() {
    return {
      user: {},
      errors: {},
      heading: "Sign Up",
      registrationSucess: false,
      resent: false,
    }
  },
  methods: {
    formRequest() {
      if (this.validate()) {
        this.$store.dispatch("auth/register", this.user).then(
        () => {
          this.registrationSucess = true;
          this.heading = "Email Confirmation";
        },
        (error) => {
          this.$notify(error.response.data.error, {
            icon: "error",
            toast: true
          });
        }
      );
      }
    },
    resend() {
      AuthService.resend({
        email: this.client_user.email,
        token: this.token
      }).then(
        () => {
          this.resent = true;
          this.$notify("profile_link_resent", {
              icon: "success",
              toast: true
          });
        },
        (error) => {
          this.$notify(error.response.data.error, {
              icon: "error",
              toast: true
          });
        }
      )
    },
    validate() {
      // for now we are just validation passwords match
      // TODO other validation we need
      return true;
    },
    passwordsMatchCheck() {
      let match = this.user.password === this.user.retype_password;
      if (!match) {
        this.$refs.retype_password_input.setCustomValidity("Passwords don't match");
        this.$refs.retype_password_input.focus()
      } else {
        this.$refs.retype_password_input.setCustomValidity("");
      } 

      return match;
    }
  },
  computed: {
    ...mapState('auth', ['client_user', 'token'])
  }
}
</script>

<style lang="scss">

  .form-row {
    margin-bottom: 33px;

    @media all and (max-height: 800px) {
      margin-bottom: 18px;
    }

    label {
      font-style: normal;
      font-weight: 700;
      font-size: 1rem;
      line-height: 1.18rem;
      text-transform: uppercase;
      color: #FFFFFF;
      display: block;
      margin-bottom: 13px;
      padding-left: 10px;
    }

    .form-input--text {
      border: 2px solid #505050;
      border-radius: 10px;
      color: #FFFFFF;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.18rem;
      background: transparent;
      padding: 0 10px;
      height: 44px;
      width: 270px;
      outline: none;

      &:focus {
        border: 2px solid #46E0D4;
      }
    }

    &.flex-row {
      align-items: center;

      label {
        margin: 0 16px 0 0;
      }
    }
  }

  .wrapper .form-btn {
      height: 50px;
      font-weight: 700;
      font-size: 1rem;
      line-height: 1.18rem;
      text-align: center;
      text-transform: uppercase;
      color: #FFFFFF;
      min-width: 270px;
      border: none;
      outline: none;
    }

    .signup__title {
      text-align: center;

      .signup__email {
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.18rem;
        color: #FFFFFF;
      }
    }

    .emailConfirm {
      font-family: 'Inter';
      padding: 200px 70px;
    }

    .email-confirmation__text {
      color: #FFFFFF;
      font-size: 1.5rem;
      line-height: 1.875rem;
      text-align: center;
      
      svg {
        vertical-align: top;
      }
    }

    .email-confirmation__resend {
      margin-top: 70px;
      justify-content: center;
    }
</style>
