<template>
  <background-video />
  <div class="host centered-container">
    <agreement-modal v-if="!authorized && !guestAgreed"></agreement-modal>
    <event-start :is_host="false" v-else></event-start>
  </div>
  <main-footer></main-footer>
  
</template>

<script>
// @ is an alias to /src
import MainFooter from '@/components/MainFooter.vue';
import EventStart from '@/components/EventStart.vue'
import AgreementModal from '@/components/AgreementModal.vue';

import { mapState } from 'vuex';
import BackgroundVideo from "@/components/ui/BackgroundVideo.vue";

export default {
  name: 'JoinView',
  components: {
    BackgroundVideo,
    MainFooter,
    EventStart,
    AgreementModal
  },
  data() {
    return {
      errors: {},
      title: "", 
      heading: '',
      profileState: false,
      createdState: false,
    }
  },
  methods: {
  },
  computed: {
    ...mapState('auth', ['agreed', 'authorized']),
    ...mapState('nonpersist', ['user', 'guestAgreed'])
  },
  mounted() {
    
  }
}
</script>

<style lang="scss" scoped></style>
