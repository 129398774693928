import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vue3GoogleLogin from 'vue3-google-login'
import VueClickAway from "vue3-click-away";
import CustomScrollbar from 'custom-vue-scrollbar';
import 'custom-vue-scrollbar/dist/style.css';
import YouTube from 'vue3-youtube'
import { i18n } from '@/plugins/i18n';
import Notification from '@/plugins/notification';
import isMobile from '@/plugins/isMobile';

import "@/directives/tooltip/tooltip.scss";
import "@/assets/scss/design.scss";


import TooltipWrap from "@/components/ui/TooltipWrap";

// import global components

import RetinaImage from "@/components/ui/RetinaImage";

import "@/assets/css/fonts.css";
import 'shepherd.js/dist/css/shepherd.css';
import '@/assets/scss/tailwind.scss';
import '@/assets/scss/styles.scss';

createApp(App)
    .use(store)
    .use(router)
    .use(i18n)
    .use(vue3GoogleLogin, {
        clientId: '586530322371-ksrjhqmenvgoilene4e02o2uflslf8r6.apps.googleusercontent.com'
    })
    .use(VueClickAway)
    .use(Notification, {
        router
    })
    .use(isMobile)
    .component("RetinaImage", RetinaImage)
    .component(CustomScrollbar.name, CustomScrollbar)
    .component("TooltipWrap", TooltipWrap)
    .component('YouTube', YouTube)
    .mount('#app')
