import api from '@/services/api.service';

class MultiseatService {
  getAssignedSeats(token, status = 'active') {
    return api.post('/multi-seats-get', {
      token: token,
      status: status,
    })
  }

  assignSeat(token, email) {
    return api.post('/multi-seats-add', {
      token: token,
      email: email
    })
  }

  revokeSeat(token, email) {
    return api.post('/multi-seats-revoke', {
      token: token,
      email: email
    })
  }

  cancelInvitation(token, email) {
    return api.post('/multi-seats-cancel-invitation', {
      token: token,
      email: email
    })
  }

  sendInvite(token, email) {
    return api.post('/multi-seats-send-invitation', {
      token: token,
      email: email
    })
  }

  updateTeamPlan(token, price_id, seats = false) {
    let params = {
      token: token,
      price_id: price_id,
      success_url: 'payment_success?session_id={CHECKOUT_SESSION_ID}',
      cancel_url: 'payment_cancel?session_id={CHECKOUT_SESSION_ID}'
    };

    if (seats) {
      params.seats = seats;
    }
    return api.post('/payment-stripe-subscription-update', params)
  }
}
export default new MultiseatService();