import { sha256 } from 'js-sha256';
import api from '@/services/api.service';
import jwt_decode from "jwt-decode";

class AuthService {

  userMe(token) {
    let no_token_update = true;
    if (this.isTokenExpiring(token)) {
      console.log("token expiring");
      no_token_update = false;
    }
    return api
      .post('/user-me', {
        token: token,
        no_token_update: no_token_update
      });
  }

  vanillaLogin(user) {
    return api
      .post('/user-login', user);
  }

  updateProfile(user) {
    return api
      .post('/user-update', user);
  }

  vanillaRegister(user) {
    let userData = {
        ...user,
        ...this.appendSaltedFields()
    }
    return api.post('/user-registration', userData);
  }

  googleLogin(googleResponse) {
    return api.post('/user-login-google', {
      id_token: googleResponse.access_token,
      token_type: "access_token"
    });
  }

  appleLogin(appleResponse) {
    const appleAuth = appleResponse.authorization;
    const appleUser = appleResponse.user;

    let loginObject = {
      id_token: appleAuth.id_token,
      token_type: 'id_token'
    };

    if (appleUser) {
      loginObject.name = appleUser.name.firstName + appleUser.name.lastName ? ' ' + appleUser.name.lastName : '';
    }

    console.log(loginObject);

    return api.post('/user-login-apple', loginObject);
  }

  resend(params) {
    return api.post('/user-resend-confirmation-code', params)
  }

  resetPassword(params) {
    return api.post('/user-reset-password', params).then(
      response => {
        return Promise.resolve(response.data);
      },
      error => {
        return Promise.reject(error);
      }
    );
  }

  resetPasswordVerifyCode(email, code) {
    console.log("Payload being sent to API for token: ", {email, code});
    return api.post('/user-reset-password-verify-code', {email, code}).then(
      response => {
        console.log("Token created");  // can add more if needed
        return Promise.resolve(response.data);
      },
      error => {
        console.log("Error in the API call: ", error);
        if(error.response) {
          console.error("Error Response Data: ", error.response.data);
        }
        return Promise.reject(error);
      }
    );
  }

  setNewPassword(params) {
    //console.log("Payload being sent to the API for setNewPassword: ", params);
    return api.post('/user-reset-password-set-new-password', params).then(
      response => {
        console.log("Password reset successfully");
        return Promise.resolve(response.data);
      },
      error => {
        console.error("Error in the API call: ", error);
        if(error.response) {
          console.error("Error Response Data: ", error.response.data);
        }
        return Promise.reject(error);
      }
    );
  }

  confirmAccount(code, token, email) {
    return api.post('/user-email-verify',{
      token: token,
      code: code,
      email: email
    })
  }

  deleteAccount(user) {
    return api
        .post('/user-deactivate-account', user);
  }

  appendSaltedFields() {
    let udid = this.generateUDID();
    // TODO think about another implementation of this
    const SALT = "AA:EA:A7:AD:3D:F0:15:03:36:80:ED:00:6C:B1:AF:77:84:50:C6:3C";
    let api_key = sha256(udid + 'web' + SALT);

    return {
        udid: udid,
        os: 'web',
        api_key: api_key
    }
  }

  generateUDID() {
    return sha256('web' + new Date().getTime());
  }

  isTokenExpiring(token) {
    const decoded = jwt_decode(token);
    return ((decoded.exp * 1000 - Date.now()) /  (1000 * 3600 * 24)) < 2;
  }
}
export default new AuthService();