import { createRouter, createWebHistory } from 'vue-router'
import VueBodyClass from 'vue-body-class';
import Swal from 'sweetalert2'

import HomeView from '../views/HomeView.vue'
import MobileView from '../views/MobileView.vue'

import SignupView from '../views/SignupView.vue'
import StartView from '../views/StartView.vue'
import SigninView from '../views/SigninView.vue'
import ForgotPwdView from '../views/ForgotPwdView.vue'
import ResetPasswordView from '../views/ResetPasswordView.vue'
import HostView from '../views/HostView.vue'
import JoinView from '../views/JoinView.vue'
import ProfileView from '../views/ProfileView.vue'
import RoomView from '../views/RoomView.vue'
import PaymentSuccess from "@/views/Website/PaymentSuccess";
import PaymentCancel from "@/views/Website/PaymentCancel";

// Website Views
import AboutView from '../views/Website/AboutView.vue'
import FaqView from '../views/Website/FaqView.vue'
import ContactView from '../views/Website/ContactView.vue'
import PlansView from '../views/Website/PlansView.vue'
import TermsView from '../views/Website/TermsView'
import TeamDashboard from '../views/Website/TeamDashboard'
import GlossaryView from '../views/Website/GlossaryView'

//import { createLocal } from 'the-storages';
import store from '../store/'
import VersionView from "@/views/Website/VersionView.vue";
import AccountConfirm from "@/views/AccountConfirm.vue";

//const mirror = createLocal();
//const storage = mirror._prx;

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/mobile',
    name: 'mobile',
    component: MobileView
  },
  {
    path: '/start',
    name: 'start',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () => import(/* webpackChunkName: "about" */ '../views/SignupView.vue')
    component: StartView
  },
  {
    path: '/signin',
    name: 'signin',
    component: SigninView
  },
  {
    path: '/forgotpwd',
    name: 'forgotpwd',
    component: ForgotPwdView
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: ResetPasswordView
  },
  {
    path: '/signup',
    name: 'signup',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () => import(/* webpackChunkName: "about" */ '../views/SignupView.vue')
    component: SignupView
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView,
    meta: {
      tpl: 'website'
    }
  },
  {
    path: '/faq',
    name: 'faq',
    component: FaqView,
    meta: {
      tpl: 'website'
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView,
    meta: {
      tpl: 'website'
    }
  },
  {
    path: '/plans',
    name: 'plans',
    component: PlansView,
    meta: {
      tpl: 'website'
    }
  },
  {
    path: '/host',
    name: 'host',
    component: HostView
  },
  {
    path: '/join',
    name: 'join',
    component: JoinView
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView
  },
  {
    path: '/account-confirm',
    name: 'account-confirm',
    component: AccountConfirm,
  },
  {
    path: '/room/:room_code',
    name: 'room',
    component: RoomView,
    meta: {
      tpl: 'conference',
      bodyClass: 'conferenceBody'
    },
  },
  {
    path: '/payment_success',
    name: 'payment_success',
    component: PaymentSuccess,
    meta: {
      tpl: 'website'
    }
  },
  {
    path: '/payment_cancel',
    name: 'payment_cancel',
    component: PaymentCancel,
    meta: {
      tpl: 'website'
    }
  },
  {
    path: '/terms',
    name: 'terms',
    component: TermsView,
    meta: {
      tpl: 'website'
    }
  },
  {
    path: '/plan-dashboard',
    name: 'team_dashboard',
    component: TeamDashboard,
    meta: {
      tpl: 'website'
    }
  },
  {
    path: '/glossary',
    name: 'glossary',
    component: GlossaryView,
    meta: {
      tpl: 'website'
    }
  },
  {
    path: '/version',
    name: 'version',
    component: VersionView,
    meta: {
      tpl: 'website'
    }
  },
]

const protectedRoutes = ['host', 'profile', 'team_dashboard'];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

const vueBodyClass = new VueBodyClass(routes);
router.beforeEach((to, from, next) => { vueBodyClass.guard(to, next) });

router.beforeEach((to, from, next) => {
  Swal.close();
  if (protectedRoutes.includes(to.name) && !store.state.auth.authorized) {
    next({ name: 'start', query: { from: to.name } });
  } else {
    next();
  }
});

export default router
