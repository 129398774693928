import { createStore } from 'vuex'
import VuexPersistence from "vuex-persist";
import { auth } from "./auth.module";
import { lang } from "./lang.module";
import { event } from "./event.module";
import { nonpersist } from "./nonpersist.module";
import { counter } from "./counter.module";
import { locale } from "./locale.module"
import {hidden} from "@/store/hidden.module";

const persist = new VuexPersistence({
  modules: [
    'auth',
    'lang',
    'event',
    'locale',
    'hidden',
  ]
})

export default createStore({
  state: {
    inConference: false,

  },
  getters: {
  },
  mutations: {
    setInConference(state, inConference) {
      state.inConference = inConference;
    }
  },
  actions: {
  },
  modules: {
    auth,
    lang,
    event,
    nonpersist,
    counter,
    locale,
    hidden,
  },
  plugins: [persist.plugin]
})
