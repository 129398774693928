<template>
  <div class="page-wrapper page-wrapper--team-dashboard">
    <div class="pricing">
      <div class="space-y-2 mb-[32px]">
        <div class="!text-4xl font-bold tracking-tight flex items-center !text-black !mb-[8px]">
          <Users color="teal" class="mr-2 h-8 w-8 text-teal-600" />
          {{ $t('manage_licenses') }}
        </div>
        <p class="max-w-[800px] text-black/75 text-base font-normal leading-tight">
           {{ $t('manage_premium_service_licenses') }}
        </p>
      </div>
        <div class="pricing-tabs">
          <!-- <div class="tabs-controls">
            <a href="#manage" :class="{ active: tab == 'manage'}" @click="setTab('manage')">{{ $t('team_dashboard_manage_tab_title') }}</a>
            <a href="#billing" v-if="user.subscription.payment_type != 'manual'" :class="{ active: tab == 'billing'}" @click="setTab('billing')">{{ $t('team_dashboard_billing_tab_title') }}</a>
          </div> -->
          <!-- <div class="tabs-controls__subheader" v-if="tab == 'team'">{{ $t('plans_teams_subheader') }}</div> -->
          <div class="rounded-lg border b g-white shadow-sm border-[#e5e7eb] p-6">
            <div class="tab" data-tab="manage"  v-show="tab == 'manage'">
              <div class="manage-seats">
                <div class="flex items-start justify-between">
                  <div>
                    <div class="flex justify-start items-center">
                      <div
                        class="mr-2"
                      >
                        <Book color="teal" class="h-5 w-5" />
                      </div>
                      <div class="text-black text-base font-semibold leading-normal">{{ user.subscription.title }}</div>
                    </div>
                    <p class="text-sm text-black/75 text-xs font-medium leading-[18px] mt-[4px]">
                      {{ $t('team_dashboard_each_user_will_get') }}:
                    </p>
                    <div class="mt-[4px] text-black/75 text-xs font-medium leading-[18px]">
                      <Clock class="inline mr-[1px] h-4 w-4 mt-[-3px]" /> {{ totalAllowedItems }} {{ totalAllowedItems !== 1 ? $t('hours_of_recording') : $t('hour_of_recording') }}
                      <Users class="inline ml-2 mr-[1px] h-4 w-4 mt-[-3px]" /> {{ seatsTotal }} {{ seatsTotal !== 1 ? $t('participants') : $t('participant') }}
                      <Book class="inline ml-2 mr-[1px] h-4 w-4 mt-[-3px]" /> {{ customTerms }} {{  customTerms !== 1 ? $t('custom_glossary_terms') : $t('custom_glossary_term') }}
                    </div>
                    <p class="text-black/75 text-xs font-medium leading-[18px] mt-[4px] flex items-center">
                      <Calendar class="mr-1 h-4 w-4 mt-[-3px]" />
                     {{ $t('plan_started_on') }} {{ subscriptionDate }}
                    </p>
                  </div>

                  <div class="text-right">
                    <button
                      :class="[
                        'ml-auto transition-all duration-300 flex items-center rounded-md cursor-pointer h-9 pl-4 pr-6 py-2 bg-[#008379] rounded-md justify-center items-center gap-2 inline-flex text-white text-sm font-medium leading-tight',
                        seatsAvailable === 0 ? 'bg-gray-400 text-gray-700 cursor-not-allowed' : 'bg-teal-600 hover:bg-teal-700 text-white hover:scale-105'
                      ]"
                      :disabled="seatsAvailable === 0"
                      @click="openAssignSeatDialog()"
                    >
                      <Plus class="h-4 w-4" />
                      {{ $t('assign_license') }}
                    </button>
                    <p class="text-black/75 text-xs font-medium leading-[18px] mt-[6px]">
                      {{ loading ? '--' : seatsAvailable }} {{ $t('licenses_remaining_out_of') }} {{ seatsTotal }}
                    </p>
                  </div>
                </div>
                 <table class="w-full caption-bottom text-sm">
                  <thead class="[&_tr]:border-b" v-if="seats && seats.length > 0">
                    <tr class="border-b border-[#e5e7eb]">
                      <th class="h-12 px-4 text-left align-middle text-black/75 text-xs font-bold leading-[18px]">{{ $t('profile_email') }}</th>
                      <th class="h-12 px-4 text-left align-middle text-black/75 text-xs font-bold leading-[18px]">{{ $t('profile_name') }}</th>
                      <th class="h-12 px-4 text-left align-middle text-black/75 text-xs font-bold leading-[18px]">{{ $t('team_dashboard_status_column') }}</th>
                      <th class="h-12 px-4 text-left align-middle text-black/75 text-xs font-bold leading-[18px]">{{ $t('Usage') }}</th>
                      <th class="h-12 px-4 text-black/75 text-xs font-bold leading-[18px]" align="right">{{ $t('team_dashboard_actions_column') }}</th>
                    </tr>
                  </thead>
                  <tbody class="[&_tr:last-child]:border-0" v-if="seats && seats.length > 0">
                    <tr v-for="seat in sortedSeatsByEmail" :key="seat.email" class="border-b transition-colors hover:bg-gray-100 border-[#e5e7eb]">
                      <td class="h-[72px] px-3 py-4 align-middle font-medium">
                        <div class="flex items-center text-black/75 text-sm font-normal font-['Inter'] leading-tight">
                          <Mail class="mr-2 h-4 w-4" color="teal" />
                          {{ seat.email }}
                        </div>
                      </td>
                      <td class="h-[72px] px-3 py-4 align-middle">
                        <div class="flex items-center text-black/75 text-sm font-normal font-['Inter'] leading-tight">
                          <User class="mr-2 h-4 w-4" color="teal" />
                          {{ !seat.name ? $t('team_dashboard_invited_user') : capitalize(seat.name) }}
                        </div>
                      </td>
                      <td class="h-[72px] px-3 py-4 align-middle">
                        <span class="inline-flex items-center gap-1">
                          <span
                            :class="`h-2 w-2 rounded-full ${
                              seat.status === UserStatus.ACTIVE
                                ? 'bg-green-500'
                                : seat.status === UserStatus.PENDING
                                  ? 'bg-yellow-500'
                                  : 'bg-red-500'
                            }`"
                          />
                          <span class="transition-transform duration-500 group-hover:-translate-y-0.5 text-black/75 text-sm font-normal leading-tight capitalize">
                            {{ $t('team_dashboard_status_' + seat.status) }}
                          </span>
                        </span>
                      </td>
                      <td class="h-[72px] px-3 py-4 align-middle w-48">
                        <div class="flex items-center gap-2">
                          <div class="h-2 flex-1 rounded-full bg-gray-200 overflow-hidden">
                            <div
                              class="h-full rounded-full bg-teal-600 transition-all duration-500 ease-in-out"
                              :class="{ 'bg-orange-500': seat?.features?.asr?.left_allowed_items === 0 }"
                              :style="{ width: `${ seat.status !== UserStatus.ACTIVE ? 0 : 
                                (seat.features.asr.total_allowed_items - seat.features.asr.left_allowed_items) / seat.features.asr.total_allowed_items * 100  
                              }%` }"
                            />
                          </div>
                          <span
                            :class="[
                              'whitespace-nowrap group-hover:font-bold group-hover:-translate-y-0.5 transition-all duration-200 w-10 text-right text-black text-sm font-medium leading-tight',
                              seat.status === UserStatus.ACTIVE && seat.features.asr.left_allowed_items === 0 ? 'text-orange-500 font-semibold' : 'text-gray-600'
                            ]"
                          >
                            {{ seat.status !== UserStatus.ACTIVE ? '0h' : formatHours((seat.features.asr.total_allowed_items - seat.features.asr.left_allowed_items) / (60 * 60)) }} / {{ totalAllowedItems }}h
                          </span>
                        </div>
                      </td>
                      <td class="h-[72px] px-3 py-4" align="right">
                        <TooltipWrap hover :content="this.$t('resend_invitation')" arrow="true">
                          <div
                            class="transition-all duration-500 hover:scale-105 hover:bg-gray-200 w-10 h-9 cursor-pointer rounded-sm flex items-center justify-center group"
                            v-if="seat.status !== UserStatus.ACTIVE"
                            @click="openReinviteDialog(seat.email)"
                          >
                            <RefreshCw class="h-4 w-4 group-hover:rotate-180 transition-transform duration-500" />
                          </div>
                        </TooltipWrap>
                        <TooltipWrap hover :content="seat.status === UserStatus.ACTIVE ? $t('revoke_access') : $t('cancel_invitation')" arrow="true">
                          <div
                            class="ml-2 transition-all duration-500 hover:scale-105 hover:bg-gray-200 w-10 h-9 cursor-pointer rounded-sm flex items-center justify-center group"
                            @click="seat.status === UserStatus.ACTIVE ? openRevokeSeatDialog(seat) : openCancelInvitationDialog(seat)"
                          >
                            <X class="h-4 w-4 group-hover:text-red-500 transition-colors duration-300" />
                          </div>
                        </TooltipWrap>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="5" class="p-4 py-16 text-center text-gray-500 w-full" v-html="$t(loading ? 'team_dashboard_loading' : 'team_dashboard_no_seats_assigned')"></td>
                    </tr>
                  </tbody>
                </table>
                <ConfirmationDialog 
                  :description="`${this.$t('team_dashboard_resend_invitation_prompt')} ${this.reinviteDialogEmail}? ${this.$t('team_dashboard_invite_expiry_notice')}`"
                  :title="$t('resend_invitation')"
                  @confirm="assignSeat(reinviteDialogEmail)"
                  @cancel="clearReinviteDialog()"
                  :open="isReinviteDialogOpen"
                />
                <ConfirmationDialog 
                  :description="$t('team_dashboard_revoke_access_warning')"
                  :title="$t('revoke_access')"
                  @confirm="revokeSeat(revokeDialogEmail)"
                  @cancel="clearRevokeDialog()"
                  :open="isRevokeDialogOpen"
                />
                <ConfirmationDialog 
                  :description="$t('team_dashboard_cancel_invitation_warning')"
                  :title="$t('cancel_invitation')"
                  @confirm="cancelInvitation(cancelInvitationDialogEmail)"
                  @cancel="clearCancelInvitationDialog()"
                  :open="isCancelInvitationDialogOpen"
                />
                <ConfirmationDialog
                  :description="$t('send_invitation_email')"
                  :title="$t('assign_new_license')"
                  @confirm="assignSeat()"
                  @cancel="clearAssignSeatDialog()"
                  :open="isAddingDialogOpen"
                  is-with-form="true"
                >
                  <template v-slot:content>
                    <div class="mt-8 pb-6">
                      <form @submit.prevent="assignSeat()">
                        <input ref="emailInput" type="email" v-model="assignSeatDialogEmail" placeholder="Email" class="flex h-10 w-full rounded-md border border-input px-3 py-2 text-base">
                        <button type="submit" class="mt-4 inline-flex w-fit justify-center rounded-md px-4 py-2 text-sm font-semibold text-white shadow-xs bg-teal-600 hover:bg-[#056770] duration-160 cursor-pointer outline-none"
                        >{{ $t('send_invite') }}</button>
                      </form>
                    </div>
                  </template>
                </ConfirmationDialog>
              </div>
            </div>

            <!-- billing tab -->
            <div class="tab" data-tab="billing" v-show="tab == 'billing'" v-if="plan &&  user.subscription.payment_type != 'manual'">
                <div class="team-upcoming-changes" v-if="user.subscription.upcoming_changes">
                    <img src="@/assets/icons/info-icon-gray.svg" alt="" width="20">
                    <span v-html="upcomingText"></span>
                </div>
                <div class="seats-table seats-table--billing">
                    <div class="seats-table__row seats-table__row--header">
                        <div class="seats-table__col">{{ $t('team_dashboard_number_of_seats') }}</div>
                        <div class="seats-table__col">{{ $t('team_dashboard_price_per_seat') }}</div>
                        <div class="seats-table__col">{{ $t('team_dashboard_total') }}</div>
                    </div>
                    <div class="seats-table__row">
                        <div class="seats-table__col">
                            <div class="seats-selector" v-if="billing.editing">
                                <div class="seats-selector__wrap">
                                    <div class="seats-selector__select">
                                        <button type="button" class="seats-selector__minus" :disabled="billing.seats <= minSeats" @click="decSeats()">&mdash;</button>
                                        <input type="number"  v-model="billing.seats" :min="minSeats" :max="plan.billing.max_allowed_multi_seats">
                                        <button type="button" class="seats-selector__plus" @click="incSeats()" >+</button>  
                                    </div>
                                    <div class="seats-selector__total"></div>
                                </div>
                            </div>
                            <div class="seats-static" v-else>{{ user.features.seats.total_allowed_items }}</div>
                            
                        </div>
                        <div class="seats-table__col"><span class="price-bold">${{ seatPrice }}</span></div>
                        <div class="seats-table__col"><span class="price-bold">${{ (seatPrice * billing.seats).toFixed(2) }}</span></div>
                    </div>
                    
                    <div class="seats-table__row">
                        <div class="seats-table__col" v-if="billing.editing">
                            <div class="adding-actions" >
                                    <button type="button" class="adding-actions__confirm" :class="{ disabled: sameSeatsNumber}" :disabled="sameSeatsNumber" @click="confirmSeatsNumberChange"><span> &check;</span>Confirm</button>
                                    <a href="javascript:;" class="adding-actions__cancel" @click="cancelBillingEditing()">Cancel</a>    
                                </div>
                            </div>
                        <div class="seats-table__col" v-else><a href="javascript:;" class="edit-link" @click="setBillingEditing(true)">&#128393; {{ $t('common_edit') }}</a></div>
                    </div>
                </div>
                
                        
                <div class="cancel-wrap">
                    <a href="javascript:;" class="danger-btn" @click="cancelSubscription" v-if="canCancel">
                        <img src="@/assets/icons/sub-cancel-icon.svg" alt="Cancel Subscription">
                        {{ $t('profile_cancel_subscription')}}
                    </a>
                </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex';
import ProductService from '@/services/products.service';
import MultiseatService from '@/services/multiseat.service';
import { Book, Users, Clock, Calendar, X, RefreshCw, Mail, User, Plus } from 'lucide-vue-next';
import TooltipWrap from '@/components/ui/TooltipWrap.vue';
import { UserStatus } from '@/constants/multi-license-user-status';
import ConfirmationDialog from '@/components/ui/ConfirmationDialog.vue';

export default {
  name: 'TeamDashboard',
  components: {
    Book,
    Users,
    Clock,
    Calendar,
    X,
    RefreshCw,
    Mail,
    User,
    Plus,
    TooltipWrap,
    ConfirmationDialog
  },
  data() {
    return {
        loading: true,
        tab: "manage",
        plan: false,
        seats: [],
        billing: {
            seats: 0,
            editing: false
        },
        UserStatus,
        isAddingDialogOpen: false,
        assignSeatDialogEmail: "",
        isInviteDialogOpen: false,
        isReinviteDialogOpen: false,
        reinviteDialogEmail: "",
        isRevokeDialogOpen: false,
        revokeDialogEmail: "",
        isCancelInvitationDialogOpen: false,
        cancelInvitationDialogEmail: ""
    }
  },
  methods: {
    openBeacon() {
      window.Beacon('open');
    },
    setTab(tab) {
      this.tab = tab;
    },
    incSeats() {
      this.billing.seats = this.billing.seats + 1;
    },
    decSeats() {
      this.billing.seats = this.billing.seats - 1;
    },
    setAdding(bool) {
        this.adding = bool;
    },
    capitalize(str) {
      return str.replace(/\b\w/g, char => char.toUpperCase());
    },
    formatHours(hours) {
      return `${hours % 1 === 0 ? hours : parseFloat(hours.toFixed(2)).toString()}h`;
    },
    getSeats() {
        this.loading = true;
        MultiseatService.getAssignedSeats(this.token, 'all').then((data) => {
          this.seats = data.data.result;
          this.loading = false;
        });
    },
    getTeamPlans() {
      ProductService.getTeamPlans().then((data) => {
        this.plan = data.data.result.subscriptions[this.user.subscription.type];
        if (!this.plan.billing.is_multi_seats) {
             this.$router.push('profile');
        }
      }).catch((error) => {
        this.$router.push('profile');
        console.log(error);
      });
    },
    openAssignSeatDialog() {
      this.isAddingDialogOpen = true;
      this.$nextTick(() => {
        this.$refs.emailInput.focus();
      });
    },
    clearAssignSeatDialog() {
        this.isAddingDialogOpen = false;
        this.assignSeatDialogEmail = "";
    },
    openReinviteDialog(email) {
      this.reinviteDialogEmail = email;
      this.isReinviteDialogOpen = true;
    },
    clearReinviteDialog() {
      this.isReinviteDialogOpen = false;
      this.reinviteDialogEmail = "";
    },
    assignSeat(email) {
        MultiseatService.assignSeat(this.token, email || this.assignSeatDialogEmail).then(() => {
           this.getSeats();
           this.clearAssignSeatDialog();
           this.clearReinviteDialog();
        }).catch((error) => {
            if (error.response.data.error == "target_user_is_not_found") {
              MultiseatService.sendInvite(this.token, email || this.assignSeatDialogEmail).then(() => {
                  this.$notify('team_dashboard_invite_sent_success', {
                      icon: "success",
                      showCancelButton: false,
                      toast: true
                  })
              }).catch((error) => {
                  this.$notify(error.response.data.error, {
                      icon: "error",
                      showCancelButton: false,
                      toast: true
                  })
              });
              this.getSeats();
              this.clearAssignSeatDialog();
              this.clearReinviteDialog();
            } else if (error.response.data.error == "invalid_email") {
                this.newSeat.errors.email = true;
            } else if (error.response.data.error == 'target_user_already_has_subscription') {
                this.$notify('team_dashboard_target_user_already_has_subscription', {
                    icon: "error",
                    showCancelButton: false,
                    confirmButtonText: this.$t('common_ok'),
                });
                this.clearAssignSeatDialog();
                this.clearReinviteDialog();
            } else if (error.response.data.error == 'target_user_is_not_activated') {
                this.$notify('team_dashboard_target_user_is_not_activated', {
                    icon: "error",
                    showCancelButton: false,
                    confirmButtonText: this.$t('common_ok'),
                });
                this.clearAssignSeatDialog();
                this.clearReinviteDialog();
            }
        });
    },
    openRevokeSeatDialog(seat) {
      this.revokeDialogEmail = seat.email;
      this.isRevokeDialogOpen = true;
    },
    clearRevokeDialog() {
      this.isRevokeDialogOpen = false;
      this.revokeDialogEmail = "";
    },
    revokeSeat(email) {
      MultiseatService.revokeSeat(this.token, email).then(() => {
          this.getSeats();
          this.$notify('team_dashboard_revoke_access_success', {
              icon: "success",
              toast: true,
          });
          this.clearRevokeDialog();
      })
    },
    openCancelInvitationDialog(seat) {
      this.cancelInvitationDialogEmail = seat.email;
      this.isCancelInvitationDialogOpen = true;
    },
    clearCancelInvitationDialog() {
      this.isCancelInvitationDialogOpen = false;
      this.cancelInvitationDialogEmail = "";
    },
    cancelInvitation(email) {
      MultiseatService.cancelInvitation(this.token, email).then(() => {
          this.getSeats();
          this.$notify('team_dashboard_cancel_invitation_success', {
              icon: "success",
              toast: true,
          });
          this.clearCancelInvitationDialog();
      })
    },
    setBillingEditing(bool) {
        this.billing.editing = bool;
    },
    cancelBillingEditing() {
        this.billing.editing = false;
        this.billing.seats = this.user.features.seats.total_allowed_items;
    },
    cancelSubscription() {
      if (this.user.subscription.payment_type != 'stripe' && this.user.subscription.payment_type.length > 0) {
        this.$notify('apple_subscription_notice', {
          icon: "warning",
        })
        return false;
      }

      this.$notify('profile_subscription_cancel_warning', {
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: this.$t('common_cancel'),
        confirmButtonText: this.$t('common_yes'),
        focusCancel: true,
      }).then((result) => {
        if (result.isConfirmed) {
            ProductService.cancel(this.token).then(() => {
              this.$notify('profile_subscription_cancel_success', {
                icon: "success"
              }).then(() => {
                location.reload();
              })
            })
        }
      })
    },
    confirmSeatsNumberChange() {
        MultiseatService.updateTeamPlan(this.token, this.plan.payment_types.stripe.prices.monthly.id, this.billing.seats).then((data) => {
          let result = data.data.result;
          if (result.downgrade) {
            this.$notify('plans_downgrade_success', {
              icon: "success",
              confirmReload: true
            })
          } else {
            location.href = result.url;
          }
        });
    }
  },
  computed: {
    ...mapState('auth', ['token', 'authorized']),
    ...mapState('nonpersist', ['user']),
    sortedSeatsByEmail() {
        return Object.assign([], this.seats).sort((a, b) => a.email.localeCompare(b.email));
    },
    seatsAvailable() {
        return this.user.features.seats.left_allowed_items - this.seats.filter((seat) => [this.UserStatus.PENDING].includes(seat.status)).length;
    },
    seatsTotal() {
       return this.user.features.seats.total_allowed_items; 
    },
    totalAllowedItems() {
        return this.user.features.asr.total_allowed_items / (60 * 60);
    },
    customTerms() {
        return this.user.features.custom_terms.total_allowed_items;
    },
    subscriptionDate() {
        return this.user.subscription.started_at ? new Date(this.user.subscription.started_at).toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        }) : '';
    },
    assignedSeats() {
        return this.seats.length;
    },
    seatPrice() {
        return (this.plan.payment_types.stripe.prices.monthly.price / 100).toFixed(2);
    },
    minSeats() {
        return this.plan.billing?.min_allowed_multi_seats && this.plan.billing.min_allowed_multi_seats >= this.assignedSeats ? this.plan.billing.min_allowed_multi_seats : this.assignedSeats;
    },
    canCancel() {
      return !this.isCanceled;
    },
    isCanceled() {
      return this.user.subscription.upcoming_changes && this.user.subscription.upcoming_changes.type == 'basic';
    },
    upcomingText() {
      let planString =  "<b style='text-transform: capitalize;'>" + this.user.subscription.upcoming_changes.type + "</b>";
      if (this.user.subscription.upcoming_changes.seats) {
        planString += " " + "(" + this.user.subscription.upcoming_changes.seats + " " + this.$t('team_dashboard_seat_plural') +")";
      }
      return this.$t('profile_upcoming_changes', {
        plan:  planString,
        date: this.user.subscription.upcoming_changes.date
      });
    },
    sameSeatsNumber() {
        return this.billing.seats == this.user.features.seats.total_allowed_items;
    }
  },
  watch:{
     'billing.seats': function (newVal){
       
         if (this.plan.billing?.max_allowed_multi_seats && newVal > this.plan.billing.max_allowed_multi_seats) {
          this.billing.seats = this.plan.billing.max_allowed_multi_seats;
         } else if (newVal < this.minSeats) {
          this.billing.seats = this.minSeats;
         }
     },
  },
  async mounted() {
    let hash = location.hash;
    if (hash) {
      hash = hash.substring(1);
      if (hash == 'billing') {
        this.tab = 'billing';
      }
    }

    this.loading = true;

    this.getSeats();
    this.getTeamPlans();
    
    this.billing.seats = this.user.features.seats.total_allowed_items;
  },
  created() {
  }
}
</script>

<style lang="scss">
    .page-wrapper {
        display: flex;
        flex-direction: column;

        &--team-dashboard {
            padding-top: 3rem !important;

            width: 90%;
            margin: 0 auto;

            img {
                max-width: 57rem;
            }

            h1 {
                font-size: 3rem !important;
                margin: 0.3em 0;
            }
        }

        .about-text {

            p {
                margin: 30px 0 0;
                text-transform: uppercase;
                font-size: 1.25rem;
                line-height: 1.875rem;
                text-align: center;
                color: #000000;
            }
        }
    }

    .pricing {
      width: 100%;

        &-header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 2rem;

            &__left {
                font-size: 1.25rem;
                line-height: 100%;
                font-weight: 400;
                color: #979797;
                display: flex;
                align-items: center;
            }

            &__right {
                font-weight: 400;
                font-size: 1.375rem;
                line-height: 150%;
                color: #3D485A;
                display: flex;

                .toggle-container {
                    margin: 0 1rem;
                }
            }
        }

        .buttons-row {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            column-gap: 11px;

            .button-column {
                padding: 0 1.5rem 0;
            }
        }
        .subscribe-btn {
            background: #46E0D4;
            border-radius: 25px;
            color: #fff;
            text-transform: uppercase;
            font-size: 1rem;
            line-height: 1.2rem;
            display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: 0.15em;
            height: 2.8rem;
            justify-content: center;
            font-weight: 700;
            margin: 11px auto 0;
            width: 80%;

            &:hover {
                filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
            }

            &--margined {
                margin-top: 25px;
            }
        }

        .current-selected {
          text-transform: uppercase;
          font-size: 1rem;
          line-height: 1.2rem;
          display: flex;
          align-items: center;
          text-align: center;
          letter-spacing: 0.15em;
          height: 2.8rem;
          justify-content: center;
          font-weight: 700;
          margin: 11px auto 0;
          width: 80%;

          &.current-selected--downgrade {
                text-transform: none;
                /* line-height: 1rem; */
                letter-spacing: 0.1em;
                width: 90%;
          }
        }

        &-footer {
            display: flex;
            justify-content: center;
            font-weight: 400;
            font-size: 1.6rem;
            line-height: 120%;
            /* identical to box height, or 30px */

            display: flex;
            align-items: center;
            text-align: center;
            font-feature-settings: 'pnum' on, 'lnum' on;

            color: #979797;
            margin-top: 6rem;
            padding-bottom: 2.25rem;

            a {
                font-weight: 700;
                font-size: 1.6rem;
                /* identical to box height, or 38px */

                display: flex;
                align-items: center;
                text-align: center;
                text-decoration-line: underline;

                /* Gold */

                color: #46e0d4;
                margin-left: 1rem;
            }
        }
    }
    :deep(.popper) {
        max-width: 200px !important;
        text-align: left !important;
        font-size: 0.8rem !important;
        line-height: 150% !important;
        font-weight: 400;
        padding: 1rem;
    }

    .tabs-controls {
      margin-bottom: 30px;
      display: flex;
      column-gap: 2.25rem;

      a {
        color: #000;
        font-weight: 700;
        font-size: 2.25rem;
        line-height: 100%;
        padding-bottom: 10px;
        border-bottom: 2px solid transparent;

        &.active {
          color: #46E0D4;
          border-color: #46E0D4;
        }
      }
    }

    .tabs-controls__subheader {
      
        margin-bottom: 30px;
        font-size: 1.25rem;
        line-height: 125%;
        width: 90%;
      
    }

    .contact-link {
      font-weight: 700;
      font-size: 1.6rem;
      display: flex;
      align-items: center;
      text-align: center;
      -webkit-text-decoration-line: underline;
      text-decoration-line: underline;
      color: #46e0d4;
      justify-content: center;
      margin-top: 1rem;
    }

    input[type=number] { 
        -moz-appearance: textfield;
        appearance: textfield;
        margin: 0; 
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button { 
          -webkit-appearance: none; 
          margin: 0; 
    }

    .seats-selector {
      
      &__wrap {
        
        display: inline-flex;
       
      }

      &__select {
        position: relative;
        padding-left: 10px;
      }

      &__total {
        margin-left: 30px;
        display: flex;
        align-items: center;
        font-size: 1rem;
        font-weight: 700;
      }

      &__text {
        color: #979797;
        margin-bottom: 10px;
        font-size: 0.8rem;
      }

      button {
        background: #46e0d4;
        border: none;
        outline: none;
        border-radius: 50%;
        height: 30px;
        width: 30px;
        color: #fff;
        position: absolute;
        cursor: pointer;

        &.seats-selector__minus {
          left: -3px;
        }

        &.seats-selector__plus {
          right: -14px;
        }

        &:disabled, &[disabled] {
          opacity: 1;
          background: #979797;
        }
      }

      input {
          height: 30px;
          border: none;
          text-align: center;
          width: 80px;
          padding: 0 20px;
          border: 1px solid #46e0d4;

          &:focus {
            border: 2px solid #46e0d4;
            outline: none;
          }
      }
    }

    .seats-table {
        display: table;
        width: 65%;

        @media all and (max-width: 1400px) {
            width: 100%;
        }

        &__body {
            display: table;
            width: 100%;
        }

        &--billing {
            width: 600px;

            .seats-table__col {
                font-size: 1.2rem;
                height: 55px;
            }

            .seats-table__col:first-child {
                width: 225px;
            }

            .seats-table__col:last-child {
                width: 120px;
            }

            .adding-actions {
                margin-top: 0;
            }

        }

        &__header, &__row {
            display: table-row;
        }

        &__add {
            display: flex;
            column-gap: 12px;

            .seats-available {
                display: flex;
                align-items: center;
                color: #979797;
            }
        }

        &__col {
            display: table-cell;
            vertical-align: middle;
            padding: 12px 12px 12px 0;
            box-sizing: border-box;

            &--actions {
                text-align: center;
            }

            input[type="email"] {
                border: 2px solid #505050;
                border-radius: 10px;
                font-weight: 400;
                font-size: 1rem;
                line-height: 1.18rem;
                background: transparent;
                padding: 0 10px;
                height: 44px;
                width: 350px;
                outline: none;

                &.error {
                    border-color: red;
                }
            }
        }

        &__row--header {
            .seats-table__col {
                font-weight: 700;
                color: #979797;
                border-bottom: 1px solid #979797;
            }

            & + .seats-table__row .seats-table__col {
                padding-top: 30px;
            }
        }

        &__footer {
            margin-top: 12px;
            a {
                color: #46E0D4;
                font-weight: 700;
                display: flex;
                align-items: center;

                span {
                    font-size: 1.5rem;
                    margin-right: 8px;
                }
            }
        }
    }

    .adding-actions {
        display: flex;
        column-gap: 24px;
        margin-top: 12px;

        &__confirm {
            border: none;
            outline: none;
            color: #46E0D4;
            display: flex;
            align-items: center;
            background: none;
            cursor: pointer;
            font-weight: 700;
            
            span {
                font-size: 1.5rem;
                margin-right: 8px;
            }

            &.disabled {
                color: #979797;
            }
        }

        &__cancel {
            color: #000 !important;
            font-weight: 400 !important;
            display: flex;
            align-items: center;
        }
    }

    .revoke-button {
        color: #EA4335;
        font-weight: 700;
    }

    .status--revoked {
        color: #979797;
    }

    .price-bold {
        font-weight: 700;
    }

    .seats-static {
        font-weight: 700;
    }

    .edit-link {
        color: #46E0D4;
        font-weight: 700;
    }

    .cancel-wrap {
        margin-top: 60px;
        border-top: 2px solid #EA4335;
        padding-top: 20px;
        width: 600px;

    }

    .team-upcoming-changes {
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        img {
            vertical-align: top;
            margin-right: 8px;
        }
    }

    .seat-status {
        display: flex;
        align-items: center;
        font-weight: 700;
        color: #979797;

        &::before {
            content: "";
            width: 8px;
            height: 8px;
            background: #979797;
            border-radius: 50%;
            margin-right: 6px;
        }

        &.active {
            color: green;
            
            
            &:before {
                background: green;
            }
        }
    }
</style>
