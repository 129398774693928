<template>
  <div class="home-page-video">
    <div class="overlay"></div>
    <video muted loop autoplay webkit-playsinline playsinline>
      <source src="/media/output.mp4" type="video/mp4" />
    </video>
  </div>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "BackgroundVideo",
  components: {},
});
</script>

<style scoped>
  .overlay {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;

    &__bg {
      background: linear-gradient(180deg, #000000 0%, rgba(27, 8, 8, 0.02) 49.5%, #060101 96.62%);
      z-index: 1;
      height: 100%;
    }
  }
</style>