<template>
  <TransitionRoot as="template" :show="open">
    <Dialog class="relative z-10" @close="onCancel">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500/75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all my-8 w-full max-w-[425px]">
              <div class="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                <button type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 outline-0 cursor-pointer" @click="onCancel">
                  <span class="sr-only">Close</span>
                  <XMarkIcon class="size-6" aria-hidden="true" />
                </button>
              </div>
              <div class="bg-white px-3 pt-[24px] pb-0 px-6">
                <div class="sm:flex sm:items-start">
                  <div class="text-center text-left">
                    <DialogTitle as="h3" class="text-lg font-semibold leading-none tracking-tight">{{ title }}</DialogTitle>
                    <div class="mt-[6px]">
                      <p class="text-sm text-[#737373]">{{ description }}</p>
                    </div>
                    <slot name="content"></slot>
                  </div>
                </div>
              </div>
              <div v-if="!isWithForm" class="px-3 py-3 pb-[24px] mt-4 flex flex-row-reverse px-6 gap-2">
                <button type="button" class="inline-flex w-full justify-center rounded-md px-4 py-2 text-sm font-semibold text-white shadow-xs bg-teal-600 hover:bg-[#056770] duration-160 cursor-pointer outline-none sm:w-auto" @click="onConfirm">{{ $t('common_confirm') }}</button>
                <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-4 py-2 text-sm font-semibold text-gray-900 ring-1 shadow-xs ring-gray-300 cursor-pointer outline-none duration-160 ring-inset hover:bg-gray-50 sm:mt-0 sm:w-auto" @click="onCancel" ref="cancelButtonRef">{{ $t('common_cancel') }}</button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XMarkIcon } from '@heroicons/vue/24/outline'

export default {
  name: 'ConfirmationDialog',
  components: {
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XMarkIcon
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    onConfirm: {
      type: Function,
      default: () => {},
    },
    onCancel: {
      type: Function,
      default: () => {},
    },
    isWithForm: {
      type: Boolean,
      default: false,
    },
  }
}
</script>