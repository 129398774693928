import { sha256 } from 'js-sha256';
import api from '@/services/api.service';

class EventService {
  
  create(event) {
    return api
      .post('/conference-create', event);
  }
  
  join(params) {
    let axiosParams = {};
    if (!params.token) {
      axiosParams = {
        ...params,
        ...this.appendSaltedFields()
      }
    } else {
      axiosParams = {
        ...params,
        token: params.token
      }
    }
    return api
      .post('/conference-info', axiosParams)
  }

  appendSaltedFields() {
    let udid = sha256('web' + new Date().getTime());
    // TODO think about another implementation of this
    const SALT = "AA:EA:A7:AD:3D:F0:15:03:36:80:ED:00:6C:B1:AF:77:84:50:C6:3C";
    let api_key = sha256(udid + 'web' + SALT);

    return {
        udid: udid,
        os: 'web',
        api_key: api_key
    }
  }
}
export default new EventService();