<template>
    <div class="page-wrapper page-wrapper--plans overflow-y-scroll md:overflow-y-visible">

      <div class="h-[389px] w-full bg-white flex-col justify-start items-start overflow-hidden relative hidden md:inline-flex">
        <div class="h-[389px] flex-col justify-start items-end gap-2 flex absolute left-0 bg-hero-image">
        </div>
        <div class="flex justify-center w-full">
          <div class="h-[172px] flex-col justify-start items-start gap-6 inline-flex z-2 mt-[88px] max-w-[1440px] px-[40px] w-full">
            <div class="w-[410px] text-white text-4xl font-['Inter'] leading-[44px] font-semibold tracking-tight">Find the right plan for your multilingual events</div>
            <div class="w-[375px] text-white/90 text-[19px] font-['Inter'] leading-[30px]">With AI-powered interpretation, translated captions, and transcripts.</div>
          </div>
        </div>
      </div>
      <div className="h-[358px] relative inline-flex flex-col justify-start items-start md:hidden bg-[#056770] w-full px-[24px] py-[40px]">
        <div className="w-[327px] h-[278px] flex flex-col justify-start items-start gap-6">
          <div className="relative justify-start text-white text-4xl font-['Inter'] leading-[44px] tracking-tight font-semibold">Find the right plan for your multilingual events</div>
          <div className="h-[122px] flex flex-col justify-start items-start gap-2">
            <div className="relative justify-start text-white/90 text-[19px] font-['Inter'] leading-[30px]">With AI-powered interpretation, translated captions, and transcripts.</div>
            <div className="relative justify-start text-white/90 text-base font-medium font-['Inter'] leading-normal" @click="scrollToPricingCards">View plans ></div>
          </div>
        </div>
      </div>
      <div class="flex-col justify-start items-start gap-16 inline-flex max-w-[1440px] pt-[40px] md:pt-[46px] px-[24px] md:px-[40px] pb-[73px] w-full h-full md:h-auto">
        <div class="self-stretch flex-col justify-start items-start flex">
          <div class="text-white text-[36px] font-semibold font-['Inter'] leading-[44px]">Pricing plans</div>
          <div class="self-stretch max-w-[1000px] justify-between items-center inline-flex mt-[24px]">
            <div class="grow shrink basis-0 text-white/75 text-[19px] font-medium font-['Inter'] leading-[30px]">Whether you're hosting a one-time meeting or scaling global communication across your organization, we’ve got you covered. Choose from flexible options that suit your meeting style and audience size.</div>
          </div>
          <div class="w-full mt-[32px]">
            <div class="text-white/90">
              All plans include:
            </div>
            <div class="grid grid-cols-1 md:grid-cols-3 gap-4 mt-[16px]">
              <div class="flex flex-col gap-4">
                <div class="flex items-start gap-2">
                  <img src="@/assets/icons/sorenson/check.svg" alt="check" />
                  <div class="text-white/90 text-sm font-medium font-['Inter'] leading-tight">Unlimited session time</div>
                </div>
                <div class="flex items-start gap-2">
                  <img src="@/assets/icons/sorenson/check.svg" alt="check" />
                  <div class="text-white/90 text-sm font-medium font-['Inter'] leading-tight">All languages + dialects</div>
                </div>
                <div class="flex items-start gap-2">
                  <img src="@/assets/icons/sorenson/check.svg" alt="check" />
                  <div class="text-white/90 text-sm font-medium font-['Inter'] leading-tight">Green screen</div>
                </div>
                <div class="flex items-start gap-2">
                  <img src="@/assets/icons/sorenson/check.svg" alt="check" />
                  <div class="text-white/90 text-sm font-medium font-['Inter'] leading-tight">Express join via QR code</div>
                </div>
                <div class="flex items-start gap-2">
                  <img src="@/assets/icons/sorenson/check.svg" alt="check" />
                  <div class="text-white/90 text-sm font-medium font-['Inter'] leading-tight">Your hours are valid for a full year</div>
                </div>
              </div>
              <div class="flex flex-col gap-4">
                <div class="flex items-start gap-2">
                  <img src="@/assets/icons/sorenson/check.svg" alt="check" />
                  <div class="text-white/90 text-sm font-medium font-['Inter'] leading-tight">Live caption</div>
                </div>
                <div class="flex items-start gap-2">
                  <img src="@/assets/icons/sorenson/check.svg" alt="check" />
                  <div class="text-white/90 text-sm font-medium font-['Inter'] leading-tight">PDF transcript in all languages</div>
                </div>
                <div class="flex items-start gap-2">
                  <img src="@/assets/icons/sorenson/check.svg" alt="check" />
                  <div class="text-white/90 text-sm font-medium font-['Inter'] leading-tight">Password protected rooms/links</div>
                </div>
                <div class="flex items-start gap-2">
                  <img src="@/assets/icons/sorenson/check.svg" alt="check" />
                  <div class="text-white/90 text-sm font-medium font-['Inter'] leading-tight">3 recording modes</div>
                </div>
                <div class="flex items-start gap-2">
                  <img src="@/assets/icons/sorenson/check.svg" alt="check" />
                  <div class="text-white/90 text-sm font-medium font-['Inter'] leading-tight">50 custom glossary terms</div>
                </div>
              </div>
              <div class="flex flex-col gap-4">
                <div class="flex items-start gap-2">
                  <img src="@/assets/icons/sorenson/check.svg" alt="check" />
                  <div class="text-white/90 text-sm font-medium font-['Inter'] leading-tight">iOS, Android, Chromebook, and web</div>
                </div>
                <div class="flex items-start gap-2">
                  <img src="@/assets/icons/sorenson/check.svg" alt="check" />
                  <div class="text-white/90 text-sm font-medium font-['Inter'] leading-tight">Presentation mode</div>
                </div>
                <div class="flex items-start gap-2">
                  <img src="@/assets/icons/sorenson/check.svg" alt="check" />
                  <div class="text-white/90 text-sm font-medium font-['Inter'] leading-tight">Advanced host controls</div>
                </div>
                <div class="flex items-start gap-2">
                  <img src="@/assets/icons/sorenson/check.svg" alt="check" />
                  <div class="text-white/90 text-sm font-medium font-['Inter'] leading-tight">Natural voice output with speed control</div>
                </div>
                <div class="flex items-start gap-2">
                  <img src="@/assets/icons/sorenson/check.svg" alt="check" />
                  <div class="text-white/90 text-sm font-medium font-['Inter'] leading-tight">Keyboard/stenograph/corder input</div>
                </div>
              </div>
            </div>
          </div>
          <div id="pricing-cards" class="h-fit gap-6 w-full mt-[40px] grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 md:justify-items-center items-end">
            <PricingCard
              title="Free Trial"
              description="30-days with no credit card required"
              price=""
              :buttonText="authorized && !isUserFreeTrialHoursAvailable ? 'Trial expired' : 'Try now'"
              :onClick="authorized ? redirectToLanding : redirectToSignUp"
              :disabled="authorized && !isUserFreeTrialHoursAvailable"
            >
              <template #features>
                <div class="h-24 flex-col justify-start items-start gap-4 inline-flex">
                  <div class="self-stretch justify-start items-start gap-2 inline-flex">
                    <img src="@/assets/icons/sorenson/users.svg" alt="users" />
                    <div class="grow shrink basis-0 text-white/75 text-sm font-medium font-['Inter'] leading-tight">One event at a time with up to 50 participants</div>
                  </div>
                  <div class="self-stretch justify-start items-start gap-2 inline-flex">
                    <img src="@/assets/icons/sorenson/check.svg" alt="check" />
                    <div class="grow shrink basis-0 text-white/75 text-sm font-medium font-['Inter'] leading-tight">Full access to real-time translation and captions in all languages</div>
                  </div>
                </div>
              </template>
            </PricingCard>
            <PricingCard
              title="Pay-as-you-go"
              description="Pay only for what you need"
              price="$75 per hour"
              :buttonText="authorized && userCurrentPlan !== 'sorenson_basic' ? 'Purchase hours' : 'View my plan'"
              :current="authorized && userCurrentPlan === 'sorenson_basic'"
              :onClick="() => authorized && userCurrentPlan === 'sorenson_basic' ? redirectToCurrentPlan : selectPlan('sorenson_basic')"
              :disabled="authorized && (displayProducts.sorenson_basic && !isPlanChangeAllowed(displayProducts.sorenson_basic))"
            >
               <template #features>
                <div class="h-24 flex-col justify-start items-start gap-4 inline-flex">
                  <div class="self-stretch justify-start items-start gap-2 inline-flex">
                    <img src="@/assets/icons/sorenson/users.svg" alt="users" />
                    <div class="grow shrink basis-0 text-white/75 text-sm font-medium font-['Inter'] leading-tight">One event at a time with up to 100 participants</div>
                  </div>
                  <div class="justify-start items-center gap-2.5 inline-flex">
                    <div class="w-[69px] h-11 flex-col justify-start items-start gap-2 inline-flex">
                      <div class="self-stretch px-3 py-2.5 rounded-xl box-border border border-[#66ebe0]/20 justify-start items-center gap-2 inline-flex overflow-hidden bg-white/3 hover:bg-white/5 duration-160">
                        <div class="grow shrink basis-0 h-6 justify-center items-center gap-2 flex">
                          <!-- <div class="grow shrink basis-0 text-right text-white/90 text-base font-semibold font-['Inter'] leading-normal">1</div> -->
                           <input 
                            @keydown="preventInvalidNumberInput" 
                            @input="preventDecimalInput" 
                            @paste="preventDecimalPaste" 
                             min="1" 
                             step="1" 
                             type="number" 
                             class="grow shrink basis-0 text-right text-white/90 text-base font-semibold font-['Inter'] leading-normal w-[40px] outline-none" 
                             v-model="payAsYouGoUsers" 
                            />
                        </div>
                      </div>
                    </div>
                    <div class="self-stretch pt-0.5 justify-center items-center gap-2 flex">
                      <div class="text-white/90 text-sm font-normal font-['Inter'] leading-tight">hours</div>
                    </div>
                    <div class="w-[146px] self-stretch py-2.5 flex-col justify-start items-start gap-2 inline-flex">
                      <div class="self-stretch grow shrink basis-0 flex-col justify-start items-start gap-4 flex">
                        <div class="self-stretch text-right text-white text-base font-semibold font-['Inter'] leading-normal">Total: ${{ payAsYouGoUsers * 75 }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </PricingCard>
            <PricingCard
              title="Professional"
              description="For teams and growing organizations"
              :price="`Annual subscription: $${
                displayProducts.sorenson_pro ? getYearlyPrice(displayProducts.sorenson_pro) : '---'
              }`"
              :buttonText="authorized && userCurrentPlan !== 'sorenson_pro' ? 'Subscribe now' : 'View my plan'"
              :recommended="!authorized || userCurrentPlan !== 'sorenson_pro'"
              :current="authorized && userCurrentPlan === 'sorenson_pro'"
              :onClick="() => authorized && userCurrentPlan === 'sorenson_pro' ? redirectToCurrentPlan : selectPlan('sorenson_pro')"
              :disabled="authorized && (displayProducts.sorenson_pro && !isPlanChangeAllowed(displayProducts.sorenson_pro))"
            >
              <template #features>
                <div class="h-24 flex-col justify-start items-start gap-4 inline-flex">
                  <div class="self-stretch justify-start items-start gap-2 inline-flex">
                    <img src="@/assets/icons/sorenson/users.svg" alt="users" />
                    <div class="grow shrink basis-0 text-white/75 text-sm font-medium font-['Inter'] leading-tight">One event at a time with up to 100 participants</div>
                  </div>
                  <div class="self-stretch justify-start items-start gap-2 inline-flex">
                    <img src="@/assets/icons/sorenson/clock.svg" alt="clock" />
                    <div class="grow shrink basis-0 text-white/75 text-sm font-medium font-['Inter'] leading-tight">60 hours of total event time annually ($60 per hour)</div>
                  </div>
                </div>
              </template>
            </PricingCard>
            <PricingCard
              title="Enterprise"
              description="Scalable solutions for global teams"
              price=""
              buttonText="Contact us"
              :onClick="openBeacon"
            >
              <template #features>
                <div class="h-24 flex-col justify-start items-start gap-4 inline-flex">
                  <div class="self-stretch justify-start items-start gap-2 inline-flex">
                    <img src="@/assets/icons/sorenson/users.svg" alt="users" />
                    <div class="grow shrink basis-0 text-white/75 text-sm font-medium font-['Inter'] leading-tight">Multiple events simultaneously with 100+ participants</div>
                  </div>
                  <div class="self-stretch justify-start items-start gap-2 inline-flex">
                    <img src="@/assets/icons/sorenson/check.svg" alt="check" />
                    <div class="grow shrink basis-0 text-white/75 text-sm font-medium font-['Inter'] leading-tight">Tailored plan to meet your needs</div>
                  </div>
                  <div class="self-stretch justify-start items-start gap-2 inline-flex">
                    <img src="@/assets/icons/sorenson/check.svg" alt="check" />
                    <div class="grow shrink basis-0 text-white/75 text-sm font-medium font-['Inter'] leading-tight">Permanent room codes</div>
                  </div>
                </div>
              </template>
            </PricingCard>
          </div>
          <div class="text-center text-white/75 text-sm font-medium font-['Inter'] leading-tight flex justify-center w-full mt-[24px] pb-[40px] md:pb-0">
            <div class="max-w-[1040px]">
              Most plans support one event at a time. This means that concurrent meetings, presentations or events cannot be hosted simultaneously on the same plan. If your organization needs to host multiple simultaneous events, consider upgrading to the Enterprise plan which includes support for concurrent events.
            </div>
          </div>
        </div>
      </div>
    </div>
    <main-footer></main-footer>
</template>

<script>
/* eslint-disable */
// @ is an alias to /src
import Toggle from '@vueform/toggle'
import ProductService from "@/services/products.service";
import {mapState} from 'vuex';
import moment from 'moment';
import axios from "axios";
import { Constants } from "@/cfg/constants"
import PricingCard from '@/components/ui/PricingCard.vue';
import MainFooter from '@/components/MainFooter.vue';
export default {
    name: 'AboutView',
    components: {
        Toggle,
        PricingCard,
        MainFooter
    },
    data() {
        return {
            yearly: true, // as per discussion, all plans will be yearly
            yearlyEnabled: true, // as per discussion, all plans will be yearly for now
            tab: "individual",
            seats: {
                teams: 0,
                organizations: 0
            },
            products: {},
            displayProducts: {},
            teamProducts: {},
            teamPlansEnabled: false,
            tiers: {
                'basic': 0,
                'starter': 1,
                'professional': 2,
                'premium': 3
            },
            teamTiers: [
                'teams',
                'organizations',
                'teams_participant',
                'organizations_participant'
            ],
            plansDirections: {},
            payAsYouGoUsers: 1
        }
    },
    methods: {
        openBeacon() {
            window.Beacon('open');
        },
        preventInvalidNumberInput(event) {
          const keyCode = event.keyCode || event.which;
          if (['e', '+', '-', '.', 'E'].includes(event.key) || keyCode === 46) {
              event.preventDefault();
          }
        },
        preventDecimalInput(event) {
          const value = event.target.value;
          if (value.includes('.')) {
            event.target.value = value.replace(/\./g, '');
            this.payAsYouGoUsers = parseInt(event.target.value, 10);
          }
        },
        preventDecimalPaste(event) {
          const paste = (event.clipboardData || window.clipboardData).getData('text');
          if (paste.includes('.')) {
            event.preventDefault();
          }
        },
        getPlans() {
            let prePlans = [];
            ProductService.getProductsArray().then((data) => {
                prePlans = data.data.result.subscriptions.filter((pr) => {
                    return pr.ui_visibility && !pr.is_custom
                });
                prePlans.forEach((plan) => {
                    this.displayProducts[plan.id] = plan;
                });
                data.data.result.subscriptions.forEach((subscription) => {
                  this.products[subscription.id] = subscription;
                });
            });
        },
        getPlansDirections() {
            axios.get('/data/plans-directions.json').then((response) => {
                this.plansDirections = response.data;
            });
        },
        getTeamPlans() {
            let preTeamPlans = [];
            ProductService.getTeamProductsArray().then((data) => {
                preTeamPlans = data.data.result.subscriptions.filter((pr) => {
                    return pr.ui_visibility
                });
                preTeamPlans.forEach((plan) => {
                    this.teamProducts[plan.id] = plan;
                });
                this.seats.teams = this.teamProducts.teams.billing.min_allowed_multi_seats;
                this.seats.organizations = this.teamProducts.organizations.billing.min_allowed_multi_seats;
            }).catch((error) => {
                console.log(error);
            });
        },
        setTab(tab) {
            this.tab = tab;
        },
        incSeats(code) {
            this.seats[code] = this.seats[code] + 1;
        },
        decSeats(code) {
            this.seats[code] = this.seats[code] - 1;
        },
        isDowngrade(plan) {
            if (this.userCurrentPlan === 'basic') {
                return false;
            }
            const planPrice = this.getMonthlyPrice(plan);
            const currentPlan = this.products[this.user.subscription.type];
            const currentPrice = currentPlan ? this.getMonthlyPrice(currentPlan) : 0;

            return parseFloat(planPrice) < parseFloat(currentPrice);
        },

        isUpgrade(plan) {
            if (this.userCurrentPlan === 'basic') {
                return true;
            }
            const planPrice = this.getMonthlyPrice(plan);
            const currentPlan = this.products[this.user.subscription.type];

            const currentPrice = currentPlan ? this.getMonthlyPrice(currentPlan) : 0;

            return parseFloat(planPrice) > parseFloat(currentPrice);
        },
        isUpcomingPlan(code) {
            return this.user.subscription.upcoming_changes && this.user.subscription.upcoming_changes.type == code;
        },
        selectPlan(code) {
            // if user is not signed in, take to "/start" page
            if (!this.authorized) {
                this.$router.push('/start');
                return;
            }

            if (this.user.subscription.payment_type != 'stripe' && this.user.subscription.payment_type.length > 0) {
                this.$notify('apple_subscription_notice', {
                    icon: "warning",
                })
                return false;
            }

            const stripeProduct = this.products[code].payment_types.stripe;
            const subProduct = this.products[code];
            let priceId;

            if (this.yearly) {
                priceId = stripeProduct.prices.yearly.id;
            } else {
                priceId = stripeProduct.prices.monthly.id;
            }

            this.$store.commit("auth/setPriceId", priceId);
            if (this.isDowngrade(subProduct)) {
                this.$notify('plans_downgrade_warning', {
                    icon: "warning",
                    showCancelButton: true,
                    cancelButtonText: this.$t('common_cancel'),
                    confirmButtonText: this.$t('common_yes'),
                    focusCancel: true,
                }).then((result) => {
                    if (result.isConfirmed) {
                        ProductService.createOrder(this.token, priceId, priceId === 'sorenson_basic' ? this.payAsYouGoUsers : false).then((data) => {
                            let result = data.data.result;
                            if (result.downgrade) {
                                this.$notify('plans_downgrade_success', {
                                    icon: "success",
                                    confirmRedirect: "profile"
                                })
                            } else {
                                location.href = result.url;
                            }

                        })
                    }
                })
            } else {
                ProductService.createOrder(this.token, priceId, priceId === 'sorenson_basic' ? this.payAsYouGoUsers : false).then((data) => {
                    let result = data.data.result;
                    if (result.downgrade) {
                        this.$notify('plans_downgrade_success', {
                            icon: "success",
                            confirmRedirect: "profile"
                        })
                    } else {
                        location.href = result.url;
                    }

                })
            }
        },
        getMonthlyPrice(item, yearly = false) {
            if (!item || !item.payment_types || !item.payment_types.stripe) {
                return 0;
            }
            let price;
            if (yearly) {
                price = (item.payment_types.stripe.prices.yearly.price / 12 / 100).toFixed(2);
            } else {
                price = (item.payment_types.stripe.prices.monthly.price / 100).toFixed(2);
            }

            return price;
        },
        getYearlyPrice(item) {
          if (!item.payment_types.stripe) {
            return 0;
          }
        
          return (item.payment_types.stripe.prices.yearly.price / 100).toFixed(2).replace(/\.?0+$/, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },
        getASRlimit(item) {
            let asrGrantedText;
            let asrTimeGranted = item.features.asr.total_allowed_items;
            let asrMinutes = asrTimeGranted / 60;
            if (asrMinutes < 60) {
                asrGrantedText = asrMinutes + " " + this.$t('common_minutes');
            } else {
                asrGrantedText = (asrMinutes / 60) + " " + this.$t('common_hours');
            }

            return asrGrantedText;
        },
        getSessionsLimit(item) {
            return item.features.host_event.total_allowed_items < 999 ? item.features.host_event.total_allowed_items : this.$t('common_unlimited');
        },
        isPlanChangeAllowed(newPlan) {
            if (this.user.subscription.type === 'basic') {
                return true;
            }
            let newPlanCode = newPlan.id;
            if (this.yearly) {
                newPlanCode += '_annual';
            } else {
                newPlanCode += '_monthly';
            }

            const keyToCheck = this.isCurrentAnnual ? 'annual' : 'monthly';
            if (!this.plansDirections[this.user.subscription.type]) {
              return true;
            }
            const isUpgradeAllowed = this.plansDirections[this.user.subscription.type][keyToCheck]['upgrade'].includes(newPlanCode);
            const isDowngradeAllowed = this.plansDirections[this.user.subscription.type][keyToCheck]['downgrade'].includes(newPlanCode);

            return isUpgradeAllowed || isDowngradeAllowed;
        },
        scrollToPricingCards() {
            document.getElementById('pricing-cards').scrollIntoView({behavior: 'smooth'});
        },
        redirectToSignUp() {
          this.$router.push('/signup');
        },
        redirectToLanding() {
          this.$router.push('/');
        },
        redirectToCurrentPlan() {
            this.$router.push('/profile?subscription');
        }
    },
    computed: {
        ...mapState('auth', ['token', 'authorized']),
        ...mapState('nonpersist', ['user']),
        userCurrentPlan() {
            if (!this.authorized) {
                return false;
            }

            if (this.user && this.user.subscription) {
                return this.user.subscription.type;
            }
        },
        upcomingText() {
            return this.$t('profile_upcoming_changes', {
                plan: this.$t('plans_this_plan'),
                date: this.user.subscription.upcoming_changes.date

            });
        },
        isCurrentAnnual() {
            return this.user.subscription.billing_frequency && this.user.subscription.billing_frequency === 'yearly';
        },
        isUserFreeTrialHoursAvailable() {
            return this.userCurrentPlan === 'basic' && this.user.features?.asr?.allowed && this.user.features?.asr?.left_allowed_items > 0;
        },
    },
    watch: {
        'seats.teams': function (newVal, oldVal) {
            if (newVal > this.teamProducts.teams.billing.max_allowed_multi_seats) {
                this.seats.teams = this.teamProducts.teams.billing.max_allowed_multi_seats;
            } else if (newVal < this.teamProducts.teams.billing.min_allowed_multi_seats) {
                this.seats.teams = this.teamProducts.teams.billing.min_allowed_multi_seats;
            }
        },
        'seats.organizations': function (newVal, oldVal) {
            if (newVal > this.teamProducts.organizations.billing.max_allowed_multi_seats) {
                this.seats.organizations = this.teamProducts.organizations.billing.max_allowed_multi_seats;
            } else if (newVal < this.teamProducts.organizations.billing.min_allowed_multi_seats) {
                this.seats.organizations = this.teamProducts.organizations.billing.min_allowed_multi_seats;
            }
        }
    },
    async mounted() {
      this.yearlyEnabled = true;
      await this.getPlans();
      await this.getPlansDirections();
      if (this.teamPlansEnabled) {
          await this.getTeamPlans();
      }

      let hash = location.hash;
      if (hash) {
          hash = hash.substring(1);
          if (hash === 'team') {
              this.tab = 'team';
          }
      }
    }
}
</script>

<style lang="scss" scoped>
html, body, #app, .wrapper {
    min-height: 100% !important;
    height: auto !important;
}

.bg-hero-image {
  background-image: url('@/assets/media/pricing-hero-image.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%; 
}

.page-wrapper {

    &--plans {
        /* justify-content: center; */
        align-items: center;
        width: 100%;
        margin: 0 auto;
        padding-top: 0rem;
    }
}

.pricing {
    width: 100%;

    &-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 2rem;

        &__left {
            font-size: 1.25rem;
            line-height: 100%;
            font-weight: 400;
            color: #979797;
            display: flex;
            align-items: center;
        }

        &__right {
            font-weight: 400;
            font-size: 1.375rem;
            line-height: 150%;
            color: #3D485A;
            display: flex;

            .toggle-container {
                margin: 0 1rem;
            }
        }
    }

    &-table {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        column-gap: 11px;

        &.pricing-table--3col {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }

    .plan-column {

        .plan {
            background: linear-gradient(0.78deg, #F0F0F0 3.95%, rgba(252, 251, 252, 0) 97.57%);
            border: 3px solid #DADADA;
            border-radius: 10px;
            padding: 3rem 2.5rem 0;
            height: 100%;
            font-size: 1rem;
            line-height: 120%;

                &--highlighted {
                    background: linear-gradient(180deg, #46e0d4 0%, #A5771A 100%);
                    /* Forum shadow */

                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                color: #fff;
                border: none;
                height: 104%;
                position: relative;
                top: -2%;

                .highlight-text {
                    color: #FAFAFA;
                    opacity: 0.5;
                    font-size: 1.25rem;
                    line-height: 150%;
                    margin-bottom: 1px;
                }

                & + .subscribe-btn {
                    position: relative;
                    top: -2%;
                }
            }

            .asterisked {
                font-size: 0.6rem;
                line-height: 120%;
            }

            &__title {
                height: 5rem;

                h2 {
                    font-weight: 700;
                    font-size: 1.6rem;
                    line-height: 150%;
                    margin: 0 0 2px 0;
                }
            }

            &__price {
                font-size: 1.25rem;
                line-height: 150%;
                font-weight: 700;
                margin-bottom: 2rem;

                b {
                    font-size: 2.25rem;
                    line-height: 100%;
                }

                &--year-monthly {
                    margin-top: -20px;
                    color: #979797;

                    b {
                        font-size: 1.5rem;
                    }
                }
            }

            &__features {
                margin-top: 2rem;

                ul {
                    margin: 0;
                    padding: 0;
                    list-style: none;

                    li {
                        margin-top: 18px;

                        &:first-child {
                            margin-top: 0;
                        }

                        .inline-block {
                            position: static;
                            padding-left: 5px;
                            padding-top: 2px;

                            img {
                                vertical-align: bottom;
                                position: relative;
                                top: -2px;
                            }
                        }
                    }
                }
            }

            &.plan--custom {
                display: flex;
                flex-direction: column;

                .plan__price {
                    margin-bottom: 0;
                }

                .plan__custom-content {
                    margin: auto;
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    height: 100%;
                    align-items: center;
                    justify-content: center;

                    p {
                        font-size: 1.7rem;
                        line-height: 110%;
                        text-align: center;
                        margin-top: 0;
                    }

                    a {
                      font-weight: 700;
                      font-size: 1.6rem;
                      display: flex;
                      align-items: center;
                      text-align: center;
                      -webkit-text-decoration-line: underline;
                      text-decoration-line: underline;
                      color: #46e0d4;
                    }
                }

                  .custom-question {
                        font-size: 10rem;
                        line-height: 75%;
                        color: #46e0d4;
                  }
                }
            }

            &:hover {
                .plan {
                    border-color: #46e0d4;
                }   
            }
        }

    .buttons-row {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        column-gap: 11px;

            .button-column {
                padding: 0 1.5rem 0;
            }
        }
        .subscribe-btn {
            background: #46E0D4;
            border-radius: 25px;
            color: #fff;
            text-transform: uppercase;
            font-size: 1rem;
            line-height: 1.2rem;
            display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: 0.15em;
            height: 2.8rem;
            justify-content: center;
            font-weight: 700;
            margin: 11px auto 0;
            width: 80%;

        &:hover {
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        }

        &--margined {
            margin-top: 25px;
        }
    }

    .current-selected {
        text-transform: uppercase;
        font-size: 1rem;
        line-height: 1.2rem;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.15em;
        height: 2.8rem;
        justify-content: center;
        font-weight: 700;
        margin: 11px auto 0;
        width: 80%;

        &.current-selected--downgrade {
            text-transform: none;
            /* line-height: 1rem; */
            letter-spacing: 0.1em;
            width: 90%;
        }
    }

    &-footer {
        display: flex;
        justify-content: center;
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 120%;
        /* identical to box height, or 30px */

        display: flex;
        align-items: center;
        text-align: center;
        font-feature-settings: 'pnum' on, 'lnum' on;

        color: #979797;
        margin-top: 6rem;
        padding-bottom: 2.25rem;
        flex-direction: column;

        a {
            font-weight: 700;
            font-size: 1.6rem;
            /* identical to box height, or 38px */

            display: flex;
            align-items: center;
            text-align: center;
            text-decoration-line: underline;

            /* Gold */

                color: #46e0d4;
                margin-left: 1rem;
            }
        }
    }
    :deep(.popper) {
        max-width: 200px !important;
        text-align: left !important;
        font-size: 0.8rem !important;
        line-height: 150% !important;
        font-weight: 400;
        padding: 1rem;
    }

.tabs-controls {
    margin-bottom: 30px;
    display: flex;
    column-gap: 2.25rem;

    a {
        color: #000;
        font-weight: 700;
        font-size: 2.25rem;
        line-height: 100%;
        padding-bottom: 10px;
        border-bottom: 2px solid transparent;

        &.active {
          color: #46E0D4;
          border-color: #46E0D4;
        }
    }
}

.tabs-controls__subheader {

    margin-bottom: 30px;
    font-size: 1.25rem;
    line-height: 125%;
    width: 90%;

}

    .contact-link {
      font-weight: 700;
      font-size: 1.6rem;
      display: flex;
      align-items: center;
      text-align: center;
      -webkit-text-decoration-line: underline;
      text-decoration-line: underline;
      color: #46e0d4;
      justify-content: center;
      margin-top: 1rem;
    }

input[type=number] {
    -moz-appearance: textfield;
    appearance: textfield;
    margin: 0;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.seats-selector {
    margin-top: 16px;

    &__wrap {

        display: inline-flex;

    }

    &__select {
        position: relative;
        padding-left: 10px;
    }

    &__total {
        margin-left: 30px;
        display: flex;
        align-items: center;
        font-size: 1rem;
        font-weight: 700;
    }

    &__text {
        color: #979797;
        margin-bottom: 10px;
        font-size: 0.8rem;
    }

      button {
        background: #46e0d4;
        border: none;
        outline: none;
        border-radius: 50%;
        height: 30px;
        width: 30px;
        color: #fff;
        position: absolute;
        cursor: pointer;

        &.seats-selector__minus {
            left: -3px;
        }

        &.seats-selector__plus {
            right: -14px;
        }

        &:disabled, &[disabled] {
            opacity: 1;
            background: #979797;
        }
    }

      input {
          height: 30px;
          border: none;
          text-align: center;
          width: 80px;
          padding: 0 20px;
          border: 1px solid #46e0d4;

          &:focus {
            border: 2px solid #46e0d4;
            outline: none;
        }
    }
}
</style>
