<template>
  <div class="secure-password-popup">
    <div class="secure-password-wrap">
      <div class="profile-form form form-default">
        <div class="form-title" style="text-align: center">
          <h2>{{ $t('enter_password')}}</h2>
          <h4>{{ $t('secure_session_pwd_required_title') }}</h4>
        </div>
        <form action="#" method="POST" @submit.prevent="secureHandle">
          <div class="form-row">
            <input type="password" required name="session_password" v-model="sessionPassword" class="form-input form-input--text" placeholder="Password" id="session_password" />
          </div>

          <button type="submit" name="profile_submit" id="profile_submit" class="orange-btn form-btn" value="Join">{{ $t('common_join') }}</button>

        </form>
      </div>

    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import { mapState } from 'vuex';

export default {
  name: 'SessionSecured',
  data() {
    return {
      status: "",
      statusText: "",
      sessionPassword: "",
    }
  },
  props: {
    is_direct: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
  },
  methods: {
    secureHandle() {
      if (this.sessionPassword) {
        this.$store.commit("nonpersist/setSessionPassword", this.sessionPassword);
        this.$emit("provided", this.sessionPassword);
      } else {
        this.$notify('secure_session_pwd_required_empty_error', {
          icon: "error",
          toast: true
        });
      }
    },
  },
    computed: {
      ...mapState('auth', ['token', 'authorized', 'user']),
      ...mapState('event', ['event', 'guest']),
      ...mapState('nonpersist', ['sessionPassword']),
    },
    mounted() {}
}
</script>

<style lang="scss" scoped>
.secure-password-popup {
  background: #505050;
  border-radius: 35px;
  width: 550px;
  padding: 60px;
  position: absolute;
  z-index: 5;

  @media all and (max-width: 1100px) {
    width: 100%;
    height: 100%;
    padding: 0;
    align-items: center;
    justify-content: center;
    border-radius: 0;
    display: flex;
  }

  .secure-password-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


    @media all and (max-width: 1100px) {

    }
  }

  h2, h4 {
    color: #ffF;
  }
  .form-row {
    display: flex;
    justify-content: center;
  }

  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .form-input {
    border: 1px solid #fff;
  }
}

@media all and (max-height: 800px) {
  .secure-password-popup {
    padding: 30px;
  }
}

</style>