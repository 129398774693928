<template>
  <background-video />
  <div class="signin centered-container">
    <div class="signup__title">
      <h1 class="mb-6">{{ heading }}</h1>
      <div class="signup__email" v-if="client_user && client_user.email">{{ client_user.email }}</div>
    </div>
    
    <div class="orange-bordered">
      <div class="signup-form form form-default">
        <form action="#" method="POST" @submit.prevent="formRequest">
          <div class="form-row">
            <label for="#signin_email">{{ $t('profile_email')}}</label>
            <input type="email" required name="email" v-model="user.email" class="form-input form-input--text" placeholder="" id="signin_email">
          </div>
          <div class="form-row">
            <label for="#signin_password">{{ $t('profile_password')}}</label>
            <input type="password" required class="form-input form-input--text" name="password" v-model="user.password" placeholder="" id="signin_password">
          </div>

          <div class="mb-10 text-center">
            <router-link to="/forgotpwd" class="text-gray-400">{{ $t('profile_forgot_password') }}</router-link>
          </div>
          
          <button type="submit" name="signin_submit" id="signin_submit" class="orange-btn form-btn" value="Sign In">{{ $t('signin_title') }}</button>
          <div class="or-divider">{{ $t('common_or') }}</div>
          <router-link :to="signupUrl" class="orange-link orange-link--inline">{{ $t('signup_title') }}</router-link>
        </form>
        
      </div>
    </div>
  </div>
  <main-footer></main-footer>
</template>

<script>
// @ is an alias to /src
 import MainFooter from '@/components/MainFooter.vue';
import { mapState } from 'vuex';
import BackgroundVideo from "@/components/ui/BackgroundVideo.vue";

export default {
  name: 'SigninView',
  components: {
    BackgroundVideo,
    MainFooter
  },
  data() {
    return {
      user: {},
      errors: {},
      heading: this.$t('signin_title'),
    }
  },
  methods: {
    formRequest() {
      if (this.validate()) {
        this.$store.dispatch("auth/login", this.user).then(
        (data) => {
          console.log(data);
        },
        (error) => {
          this.$notify(error.response.data.error, {
            icon: "error",
            toast: true
          });
        }
      );
      }
    },
    validate() {
      // for now we are just validation passwords match
      // TODO other validation we need
      return true;
    },
  },
  computed: {
    ...mapState('auth', ['client_user']),
    signupUrl() {
      let url = '/signup';
      if (this.$route.query.from) {
        url += `?from=${this.$route.query.from}`;
      }

      return url;
    }
  }
}
</script>

<style lang="scss" scoped>


</style>
