<template>
  <div class="conference-meta" :class="{ transparenthidden: roomSettingsPopup, mobileMeta: $isMobile() }" v-if="event">
      <div class="mobile-meta-header"  v-if="$isMobile()">
        <a href="/" class="conference-meta__logo">
          <img src="../assets/logo-sm.svg" width="167" alt="">
        </a>
        <a href="javascript:;" class="mobile-info-btn" @click="showForumInfoPopup">
          <svg width="78" height="78" viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M39 33.8573V57.0001M39 18.4287V23.5716" stroke="#46E0D4" stroke-width="5.14286" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M39 75C58.8823 75 75 58.8823 75 39C75 19.1177 58.8823 3 39 3C19.1177 3 3 19.1177 3 39C3 58.8823 19.1177 75 39 75Z" stroke="#46E0D4" stroke-width="5.14286" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
        </a>
      </div>
      <div class="meta-footer">
        <div class="conference-meta__title">{{ event.title }}</div>
      <div class="conference-meta__speaker">{{ event.speaker_name }}</div>
      </div>
      
  </div>
  <div class="room flex-column" ref="roomwrap" :class="[{ anyPopupShown: anyPopupShown }, mode.mode, mode.theme]">

    <div class="room-overlay" v-show="roomOverlayed" :class="{ transparenthidden: roomSettingsPopup }" @click="closePopups" ></div>

    <!-- if cc -->
    <div class="session-meta" v-if="mode.mode === 'cc'">
      <div class="session-meta__title">{{ event.title }}</div>
      <div class="toolbar-divider">|</div>
      <div class="session-meta__speaker">{{ event.speaker_name }}</div>
    </div>

    <div class="cc-toolbar" :class="{ shown: ccToolbarShown}" v-if="mode.mode !== 'default'">
      <div class="cc-toolbar-inner">
        <!-- Theme -->
        <div class="cc-toolbar-theme cc-toolbar-item" v-if="mode.mode === 'cc'">
          <a href="#" class="cc-toolbar-theme-btn cc-toolbar-btn" @click.prevent="switchModeTheme">
            <svg width="40" height="40" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="mode.theme === 'black'">
              <g clip-path="url(#clip0_503_15805)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12 1C12.5523 1 13 1.44772 13 2V4C13 4.55228 12.5523 5 12 5C11.4477 5 11 4.55228 11 4V2C11 1.44772 11.4477 1 12 1ZM4.1928 4.1928C4.58332 3.80227 5.21648 3.80227 5.60701 4.1928L7.02122 5.60701C7.41175 5.99753 7.41175 6.6307 7.02122 7.02122C6.6307 7.41175 5.99753 7.41175 5.60701 7.02122L4.1928 5.60701C3.80227 5.21648 3.80227 4.58332 4.1928 4.1928ZM19.8072 4.1928C20.1977 4.58332 20.1977 5.21648 19.8072 5.60701L18.393 7.02122C18.0025 7.41175 17.3693 7.41175 16.9788 7.02122C16.5883 6.6307 16.5883 5.99753 16.9788 5.60701L18.393 4.1928C18.7835 3.80227 19.4167 3.80227 19.8072 4.1928ZM12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8ZM6 12C6 8.68629 8.68629 6 12 6C15.3137 6 18 8.68629 18 12C18 15.3137 15.3137 18 12 18C8.68629 18 6 15.3137 6 12ZM1 12C1 11.4477 1.44772 11 2 11H4C4.55228 11 5 11.4477 5 12C5 12.5523 4.55228 13 4 13H2C1.44772 13 1 12.5523 1 12ZM19 12C19 11.4477 19.4477 11 20 11H22C22.5523 11 23 11.4477 23 12C23 12.5523 22.5523 13 22 13H20C19.4477 13 19 12.5523 19 12ZM7.02122 16.9829C7.41175 17.3734 7.41175 18.0066 7.02122 18.3971L5.60701 19.8113C5.21648 20.2018 4.58332 20.2018 4.1928 19.8113C3.80227 19.4208 3.80227 18.7876 4.1928 18.3971L5.60701 16.9829C5.99753 16.5924 6.6307 16.5924 7.02122 16.9829ZM16.9788 16.9829C17.3693 16.5924 18.0025 16.5924 18.393 16.9829L19.8072 18.3971C20.1977 18.7876 20.1977 19.4208 19.8072 19.8113C19.4167 20.2018 18.7835 20.2018 18.393 19.8113L16.9788 18.3971C16.5883 18.0066 16.5883 17.3734 16.9788 16.9829ZM12 19C12.5523 19 13 19.4477 13 20V22C13 22.5523 12.5523 23 12 23C11.4477 23 11 22.5523 11 22V20C11 19.4477 11.4477 19 12 19Z" fill="#D7D6D6"/>
              </g>
              <defs>
                <clipPath id="clip0_503_15805">
                  <rect width="24" height="24" fill="white"/>
                </clipPath>
              </defs>
            </svg>

            <svg width="40" height="40" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="mode.theme === 'white'">
              <g clip-path="url(#clip0_503_15770)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.86288 1.29289C9.15714 1.58715 9.23841 2.03289 9.06693 2.41206C8.53036 3.59854 8.23116 4.91625 8.23116 6.30654C8.23116 11.5324 12.4676 15.7688 17.6935 15.7688C19.0838 15.7688 20.4015 15.4696 21.5879 14.9331C21.9671 14.7616 22.4128 14.8429 22.7071 15.1371C23.0014 15.4314 23.0826 15.8771 22.9112 16.2563C21.1136 20.2311 17.1124 23 12.4623 23C6.13185 23 1 17.8682 1 11.5377C1 6.88761 3.76893 2.88639 7.74371 1.08884C8.12289 0.917367 8.56862 0.998635 8.86288 1.29289ZM6.41336 4.26077C4.32688 5.99712 3 8.61307 3 11.5377C3 16.7636 7.23642 21 12.4623 21C15.3869 21 18.0029 19.6731 19.7392 17.5866C19.0751 17.7064 18.3913 17.7688 17.6935 17.7688C11.363 17.7688 6.23116 12.637 6.23116 6.30654C6.23116 5.60867 6.29363 4.92493 6.41336 4.26077Z" fill="#D7D6D6"/>
              </g>
              <defs>
                <clipPath id="clip0_503_15770">
                  <rect width="24" height="24" fill="white"/>
                </clipPath>
              </defs>
            </svg>
          </a>
        </div>

        <v-swatches v-model="settings.chroma_overlay_color" :swatches="swatches" v-else-if="mode.mode === 'chromakey'"
                    show-fallback
                    fallback-input-class="colorpicker-input"
                    fallback-ok-class="colorpicker-ok"
                    fallback-ok-text="OK"
                    popover-x="left"
                    popover-y="top"
                    row-length="6"
                    shapes="circles"
                    show-border>
          <template #trigger>
            <div class="cc-toolbar-theme cc-toolbar-item" >
              <a href="#" class="cc-toolbar-theme-btn cc-toolbar-btn" @click.prevent="switchModeTheme">
                <svg width="40" height="40" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_503_13706)">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C14.2091 23 16 21.2091 16 19V18.5C16 18.0038 16.0022 17.8525 16.0171 17.7389C16.1353 16.8415 16.8415 16.1353 17.7389 16.0171C17.8525 16.0022 18.0038 16 18.5 16H19C21.2091 16 23 14.2091 23 12C23 5.92487 18.0751 1 12 1ZM14 8C14 6.89543 14.8954 6 16 6C17.1046 6 18 6.89543 18 8C18 9.10457 17.1046 10 16 10C14.8954 10 14 9.10457 14 8ZM10 5C8.89543 5 8 5.89543 8 7C8 8.10457 8.89543 9 10 9C11.1046 9 12 8.10457 12 7C12 5.89543 11.1046 5 10 5ZM5 12C5 10.8954 5.89543 10 7 10C8.10457 10 9 10.8954 9 12C9 13.1046 8.10457 14 7 14C5.89543 14 5 13.1046 5 12Z" fill="white"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_503_13706">
                      <rect width="24" height="24" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
              </a>
            </div>
          </template>
        </v-swatches>


        <!-- Font Sizing -->
        <div class="cc-toolbar-font cc-toolbar-item">
          <a href="#" class="cc-toolbar-font-btn cc-toolbar-font-btn--decrease cc-toolbar-btn" @click.prevent="changeFontSize(-1)">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_870_6063)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.92307 5.85282C8.82056 5.67232 8.61925 5.346 8.24313 5.16074C7.80977 4.94729 7.30183 4.94729 6.86847 5.16074C6.49234 5.346 6.29104 5.67232 6.18853 5.85282C6.07931 6.04511 5.9667 6.29295 5.85181 6.54581L5.83622 6.58012L1.08988 17.0221C0.861341 17.5248 1.08366 18.1177 1.58644 18.3462C2.08922 18.5748 2.68207 18.3525 2.91061 17.8497L4.66436 13.9914H10.4472L12.201 17.8497C12.4295 18.3525 13.0224 18.5748 13.5252 18.3462C14.0279 18.1177 14.2503 17.5248 14.0217 17.0221L9.27538 6.58012L9.25979 6.5458C9.1449 6.29295 9.03229 6.0451 8.92307 5.85282ZM5.57345 11.9914H9.53815L7.5558 7.63026L5.57345 11.9914Z" fill="#D7D6D6"/>
                <path d="M17.9074 18.3175C18.0974 18.5391 18.3747 18.6667 18.6666 18.6667C18.9586 18.6667 19.2359 18.5391 19.4259 18.3175L22.7592 14.4286C23.1187 14.0092 23.0701 13.3779 22.6508 13.0185C22.2314 12.6591 21.6001 12.7077 21.2407 13.127L19.6666 14.9634V6C19.6666 5.44771 19.2189 5 18.6666 5C18.1144 5 17.6666 5.44771 17.6666 6V14.9634L16.0926 13.127C15.7332 12.7077 15.1019 12.6591 14.6825 13.0185C14.2632 13.3779 14.2146 14.0092 14.5741 14.4286L17.9074 18.3175Z" fill="#D7D6D6"/>
              </g>
              <defs>
                <clipPath id="clip0_870_6063">
                  <rect width="24" height="24" fill="white"/>
                </clipPath>
              </defs>
            </svg>
          </a>
          <div class="toolbar-divider">|</div>
          <a href="#" class="cc-toolbar-font-btn cc-toolbar-font-btn--increase cc-toolbar-btn" @click.prevent="changeFontSize(1)">
            <svg width="40" height="40" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_870_6070)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.92307 5.85314C8.82056 5.67264 8.61925 5.34632 8.24313 5.16107C7.80977 4.94761 7.30183 4.94761 6.86847 5.16107C6.49234 5.34632 6.29104 5.67264 6.18853 5.85314C6.07931 6.04543 5.9667 6.29328 5.85181 6.54614L5.83622 6.58044L1.08988 17.0224C0.861341 17.5252 1.08366 18.118 1.58644 18.3466C2.08922 18.5751 2.68207 18.3528 2.91061 17.85L4.66436 13.9917H10.4472L12.201 17.85C12.4295 18.3528 13.0224 18.5751 13.5252 18.3466C14.0279 18.118 14.2503 17.5252 14.0217 17.0224L9.27538 6.58044L9.25979 6.54613C9.1449 6.29327 9.03229 6.04543 8.92307 5.85314ZM5.57345 11.9917H9.53815L7.5558 7.63058L5.57345 11.9917Z" fill="#D7D6D6"/>
                <path d="M19.4256 5.34921C19.2356 5.12756 18.9583 5 18.6664 5C18.3744 5 18.0971 5.12756 17.9071 5.34921L14.5738 9.2381C14.2143 9.65742 14.2629 10.2887 14.6822 10.6481C15.1016 11.0076 15.7329 10.959 16.0923 10.5397L17.6664 8.70326L17.6664 17.6667C17.6664 18.219 18.1141 18.6667 18.6664 18.6667C19.2186 18.6667 19.6664 18.219 19.6664 17.6667L19.6664 8.70326L21.2404 10.5397C21.5999 10.959 22.2312 11.0076 22.6505 10.6481C23.0698 10.2887 23.1184 9.65742 22.7589 9.2381L19.4256 5.34921Z" fill="#D7D6D6"/>
              </g>
              <defs>
                <clipPath id="clip0_870_6070">
                  <rect width="24" height="24" fill="white"/>
                </clipPath>
              </defs>
            </svg>
          </a>
        </div>

        <!-- margin -->
        <div class="cc-toolbar-margin cc-toolbar-item" style="width: 66px; justify-content: center">
          <a href="#" class="cc-toolbar-margin-btn cc-toolbar-btn" @click.prevent="switchModeMargin">
            <svg width="40" height="22" viewBox="0 0 34 19" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="mode.margin === 'wider'">
              <rect x="7.99219" y="6.28955" width="18.012" height="4.6988" rx="2.3494" fill="#D7D6D6"/>
              <path d="M30.832 7.29839C32.1654 8.06819 32.1654 9.99269 30.832 10.7625L23.323 15.0978C21.9897 15.8676 20.323 14.9054 20.323 13.3658L20.323 4.6951C20.323 3.1555 21.9897 2.19324 23.323 2.96304L30.832 7.29839Z" fill="#D7D6D6"/>
              <path d="M3.16016 10.7622C1.82683 9.99236 1.82682 8.06786 3.16016 7.29806L10.6692 2.96272C12.0025 2.19292 13.6692 3.15516 13.6692 4.69477L13.6692 13.3654C13.6692 14.9051 12.0025 15.8673 10.6692 15.0975L3.16016 10.7622Z" fill="#D7D6D6"/>
            </svg>

            <svg width="66" height="19" viewBox="0 0 66 19" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="mode.margin === 'narrower'">
              <rect x="6.76562" y="7.229" width="52.4699" height="4.6988" rx="2.3494" fill="#D7D6D6"/>
              <path d="M62.5 8.23784C63.8333 9.00764 63.8333 10.9321 62.5 11.7019L54.991 16.0373C53.6576 16.8071 51.991 15.8448 51.991 14.3052L51.991 5.63455C51.991 4.09495 53.6576 3.1327 54.991 3.9025L62.5 8.23784Z" fill="#D7D6D6"/>
              <path d="M3.5 11.7021C2.16667 10.9323 2.16667 9.0078 3.5 8.238L11.009 3.90266C12.3424 3.13286 14.009 4.09511 14.009 5.63471L14.009 14.3054C14.009 15.845 12.3424 16.8072 11.009 16.0374L3.5 11.7021Z" fill="#D7D6D6"/>
            </svg>

          </a>
        </div>

        <!-- align -->
        <div class="cc-toolbar-align cc-toolbar-item">
          <a href="#" class="cc-toolbar-align-btn cc-toolbar-btn" @click.prevent="switchModeAlign">
            <svg width="40" height="40" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="mode.align === 'center'">
              <g clip-path="url(#clip0_503_13342)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2 6C2 5.44772 2.44772 5 3 5H21C21.5523 5 22 5.44772 22 6C22 6.55228 21.5523 7 21 7H3C2.44772 7 2 6.55228 2 6ZM5 10C5 9.44772 5.44772 9 6 9H18C18.5523 9 19 9.44772 19 10C19 10.5523 18.5523 11 18 11H6C5.44772 11 5 10.5523 5 10ZM2 14C2 13.4477 2.44772 13 3 13H21C21.5523 13 22 13.4477 22 14C22 14.5523 21.5523 15 21 15H3C2.44772 15 2 14.5523 2 14ZM5 18C5 17.4477 5.44772 17 6 17H18C18.5523 17 19 17.4477 19 18C19 18.5523 18.5523 19 18 19H6C5.44772 19 5 18.5523 5 18Z" fill="#D7D6D6"/>
              </g>
              <defs>
                <clipPath id="clip0_503_13342">
                  <rect width="24" height="24" fill="white"/>
                </clipPath>
              </defs>
            </svg>

            <svg width="40" height="40" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="mode.align === 'left'">
              <g clip-path="url(#clip0_503_13356)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2 6C2 5.44772 2.44772 5 3 5H20C20.5523 5 21 5.44772 21 6C21 6.55228 20.5523 7 20 7H3C2.44772 7 2 6.55228 2 6ZM2 10C2 9.44772 2.44772 9 3 9H16C16.5523 9 17 9.44772 17 10C17 10.5523 16.5523 11 16 11H3C2.44772 11 2 10.5523 2 10ZM2 14C2 13.4477 2.44772 13 3 13H20C20.5523 13 21 13.4477 21 14C21 14.5523 20.5523 15 20 15H3C2.44772 15 2 14.5523 2 14ZM2 18C2 17.4477 2.44772 17 3 17H16C16.5523 17 17 17.4477 17 18C17 18.5523 16.5523 19 16 19H3C2.44772 19 2 18.5523 2 18Z" fill="#D7D6D6"/>
              </g>
              <defs>
                <clipPath id="clip0_503_13356">
                  <rect width="24" height="24" fill="white"/>
                </clipPath>
              </defs>
            </svg>
            <svg width="40" height="40" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="mode.align === 'right'">
              <g clip-path="url(#clip0_503_13363)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3 6C3 5.44772 3.44772 5 4 5H21C21.5523 5 22 5.44772 22 6C22 6.55228 21.5523 7 21 7H4C3.44772 7 3 6.55228 3 6ZM7 10C7 9.44772 7.44772 9 8 9H21C21.5523 9 22 9.44772 22 10C22 10.5523 21.5523 11 21 11H8C7.44772 11 7 10.5523 7 10ZM3 14C3 13.4477 3.44772 13 4 13H21C21.5523 13 22 13.4477 22 14C22 14.5523 21.5523 15 21 15H4C3.44772 15 3 14.5523 3 14ZM7 18C7 17.4477 7.44772 17 8 17H21C21.5523 17 22 17.4477 22 18C22 18.5523 21.5523 19 21 19H8C7.44772 19 7 18.5523 7 18Z" fill="#D7D6D6"/>
              </g>
              <defs>
                <clipPath id="clip0_503_13363">
                  <rect width="24" height="24" fill="white"/>
                </clipPath>
              </defs>
            </svg>
          </a>
        </div>

        <!-- exit -->
        <div class="cc-toolbar-exit cc-toolbar-item">
          <a href="#" class="cc-toolbar-exit-btn cc-toolbar-btn" @click.prevent="switchMode('default')">
            <img src="@/assets/icons/sorenson/logout.svg" alt="Exit">
          </a>
        </div>
      </div>
    </div>
    <!-- room code & qr code popup -->
    <room-code v-if="roomCodePopup" @closed="closePopups" :room_code="event.room_code"></room-code>

    <!-- password if secure session -->
    <session-secured v-if="secureSessionPopup" @provided="setPasswordProvided"></session-secured>
    
    <!-- room settings popup -->
    <room-settings 
      v-if="roomSettingsPopup" 
      @closed="closePopups" 
      @saved="setSettings"
      :participant="participant"
      :openLanguageSelector="openLanguageSelector" 
      :defaultSettings="settings">
    </room-settings>

    <div class="forum-info-popup" v-show="forumInfoPopup">
      <a href="javascript:;" class="icon-btn conference-close" @click="hideForumInfoPopup"><img src="@/assets/icons/xmark.svg" alt="Close"></a>
      <div class="forum-info">
        <div class="forum-info__text">
          Download the <b>Forum Interpreter App</b> for additional functionality and the ability to record and translate your speech. Try it for free today!
        </div>
        <div class="forum-info__links">
          <a href="https://apps.apple.com/app/id1612322502">
            <img src="@/assets/appstore.svg" alt="Get it on the App Store" width="160">
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.waverlylabs.audience.translate&pli=1">
            <img src="@/assets/googleplay.svg" alt="Get it on Google Play" width="160">
          </a>
        </div>
        <div class="forum-info__footer">
          <b>Thanks for helping us create
 a world without language barriers. </b>
        </div>
      </div>
    </div>
    
    <!-- signup popup -->
    <signup-popup v-show="!authorized && roomSignupPopup" @closed="closePopups"></signup-popup>  

    <div class="screen" :class="{ transparenthidden: roomSettingsPopup }" :style="{ width: transcriptsWidth, maxWidth: '100%'}">
      <!-- default text when waiting for messages -->
      <div class="screen__default" v-if="!started && isAllowedToSpeak">{{ defaultText }} <img src="@/assets/icons/arrow-down.svg" alt=""></div>
      <div class="screen__default" v-if="!started && !isAllowedToSpeak"> {{ $t('session_listener_waiting') }} </div>
      
      <!-- transcripts & partials scroller -->
      <custom-scrollbar class="screen__scroll" :class="{visible: true || transcripts.length > 0 || partials.length > 0 || recording || typing}" throttleType="throttle" :autoHide="false">
        <div class="transcripts" v-if="mode.mode === 'default'">
          <div class="transcripts__item transcripts__item--final" v-for="(transcript, key) in transcripts" :key="'tr_' + key">
            <div class="transcript__time">{{ transcript.time }}</div>
            <div class="transcript__original" :class="{ noTranslations: hasTranslationForUser(transcript) }">
              <div class="transcript__sendername"><div class="transcript__flag"><img :src="'/data/flags/' + transcript.sender.language + '.png'" /></div> {{ transcript.sender.name }}</div>

              <div class="transcript__text">{{ transcript.original.text }}</div>
              <div class="transcript__read" v-if="transcript.sender.sender_id == user_id"><img src="@/assets/icons/sent.svg" /></div>
            </div>
            <div class="transcript__translation" v-if="transcript.translations && hasTranslationForUser(transcript)">
              <div class="transcript__text">{{ getTranslationForUser(transcript).text }}</div>
            </div>
          </div>
          <div class="transcripts__item transcripts__item--partial" v-for="(partial, key) in partials" :key="'pt_' + key">
            <div class="transcript__sendername" v-if="partial.sender"><div class="transcript__flag">
              <img v-if="partial.sender" :src="'/data/flags/' + partial.sender.language + '.png'" />
              <img v-else :src="'/data/flags/' + participant.language + '.png'" />

            </div>{{ partial.sender.name }}</div>
            <div class="transcript__sendername" v-else><div class="transcript__flag">
              <img v-if="partial.sender" :src="'/data/flags/' + partial.sender.language + '.png'" />
              <img v-else :src="'/data/flags/' + participant.language + '.png'" />

            </div>{{ participant.name }}</div>
            <div class="transcript__text">{{ partial.original.text }} {{ partial.concat }}</div>
          </div>
          <div class="transcripts__item transcripts__item--partial" v-if="recording && !hasOwnMessage()">
            <div class="transcript__flag">
              <img :src="'/data/flags/' + participant.language + '.png'" />
            </div>
            <div class="transcript__text">{{ $t('common_listening')}}</div>
          </div>
          <div class="transcripts__item transcripts__item--partial" v-if="partial && hasOwnMessage() && recording">
            <div class="transcript__flag">
              <img :src="'/data/flags/' + participant.language + '.png'" />
            </div>
            <div class="transcript__text">{{ partial.original.text }} {{ partial.concat }}</div>
          </div>

          <div class="transcripts__item transcripts__item--partial keyboard-input clear" v-show="typing">
            <div class="transcript__flag">
              <img :src="'/data/flags/' + participant.language + '.png'" />
            </div>
            <contenteditable tag="div" ref="keyboard_input_field" id="keyboard_input_field" class="transcript__text" v-model="keyboardInputValue" :no-nl="true" :no-html="true" @returned="onEnterPressed" />
            <a href="javascript:;" class="keyboard-input__send" @click="sendKeyboardMessage(true)">
              <img src="@/assets/icons/paperplane.svg" />
            </a>
          </div>
        </div>

        <!-- chromakey mode -->
        <div class="transcripts" v-else-if="mode.mode === 'chromakey'" :class="[ mode.margin ]">
          <div class="transcripts__item transcripts__item--final" v-for="(transcript, key) in transcripts" :key="'tr_' + key">
            <div class="goo-wrapper" v-if="transcript.translations && hasTranslationForUser(transcript)">
              <text-clamp :text="getTranslationForUser(transcript).text" :max-lines='3' location="start" />
            </div>
            <div class="goo-wrapper" v-else>
              <text-clamp :text="transcript.original.text" :max-lines='3' location="start" />
            </div>
          </div>
          <div class="transcripts__item transcripts__item--partial" v-for="(partial, key) in partials.slice(-1)" :key="'pt_' + key">
            <div class="goo-wrapper">
              <text-clamp :text="mergeStringsIf(partial.original.text, partial.concat)" :max-lines='3' location="start" />
            </div>
          </div>
          <div class="transcripts__item transcripts__item--partial" v-if="partial && hasOwnMessage() && recording">
            <div class="goo-wrapper">
              <text-clamp :text="mergeStringsIf(partial.original.text, partial.concat)" :max-lines='3' location="start" />
            </div>
          </div>
          <div class="transcripts__item transcripts__item--partial transcripts__item--partial--incoming" v-if="partialIncoming && partials.length <= 0">
            <div class="goo-wrapper">
                <text-clamp :text="`...`" :max-lines='3' location="start" />
            </div>
          </div>
        </div>

        <!-- cc mode -->
        <div class="transcripts" v-else-if="mode.mode === 'cc'" :class="[ mode.margin ]">
          <div class="transcripts__item transcripts__item--final" v-for="(transcript, key) in transcripts" :key="'tr_' + key">
            <div class="transcript__original" :class="{ noTranslations: hasTranslationForUser(transcript) }" v-if="!hasTranslationForUser(transcript)">
              <div class="transcript__text">{{ transcript.original.text }}</div>
            </div>
            <div class="transcript__translation" v-if="transcript.translations && hasTranslationForUser(transcript)">
              <div class="transcript__text">{{ getTranslationForUser(transcript).text }}</div>
            </div>
          </div>
          <div class="transcripts__item transcripts__item--partial" v-for="(partial, key) in partials.slice(-1)" :key="'pt_' + key">
            <div class="transcript__translation">
              <div class="transcript__text">{{ mergeStringsIf(partial.original.text, partial.concat) }}</div>
            </div>
          </div>
          <div class="transcripts__item transcripts__item--partial" v-if="partial && hasOwnMessage() && recording">
            <div class="transcript__translation">
              <div class="transcript__text">{{ mergeStringsIf(partial.original.text, partial.concat) }}</div>
            </div>
          </div>
          <span class="transcripts__item transcripts__item--partial transcripts__item--partial--incoming">
            <span class="incoming-line" :class="{ incoming: partialIncoming || recording}"></span>
          </span>
        </div>
      </custom-scrollbar>

      <!-- scroller arrow -->
      <a href="javascript:;" class="chevron-to-bottom-scroller" v-show="scroller.scrolled" @click="resetScroller">
        <svg width="22" height="13" viewBox="0 0 22 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.8348 13C10.5498 13.0016 10.2674 12.947 10.0036 12.8392C9.73975 12.7315 9.49981 12.5727 9.2975 12.3721L0.636763 3.71132C0.434884 3.50944 0.274746 3.26978 0.16549 3.00601C0.0562339 2.74225 4.25427e-09 2.45954 0 2.17404C-4.25427e-09 1.88854 0.056234 1.60584 0.16549 1.34207C0.274746 1.0783 0.434884 0.83864 0.636763 0.636762C0.838641 0.434883 1.07831 0.274744 1.34207 0.165488C1.60584 0.0562319 1.88854 -4.25427e-09 2.17404 0C2.45954 4.25428e-09 2.74225 0.0562319 3.00602 0.165488C3.26978 0.274744 3.50945 0.434883 3.71132 0.636762L10.8348 7.80352L17.9799 0.918236C18.1791 0.69676 18.4218 0.518763 18.6929 0.395377C18.964 0.271992 19.2577 0.205873 19.5555 0.201154C19.8533 0.196436 20.1489 0.253218 20.4238 0.367953C20.6987 0.482689 20.9469 0.652909 21.153 0.867964C21.3591 1.08302 21.5186 1.33829 21.6216 1.6178C21.7245 1.89731 21.7687 2.19506 21.7513 2.49242C21.7339 2.78977 21.6554 3.08035 21.5206 3.34596C21.3857 3.61158 21.1976 3.84652 20.9678 4.0361L12.3071 12.3937C11.9111 12.7755 11.3847 12.9922 10.8348 13Z" fill="#46E0D4"/>
        </svg>
        <!-- scroller new dot -->
        <span class="new-indicator" v-show="scroller.hasNew"></span>
      </a>

    </div>

    <div class="toolbar" :class="{ transparenthidden: roomSettingsPopup }">
      <div class="toolbar-left">
        <div class="participants-info flex-column">
          <img class="participants-info__icon" src="@/assets/icons/people.svg" alt="Participants">
          <span class="participants-info__count">{{ room.count }}/{{ event.guests_limit }}</span>
        </div>
      </div>
      
      <div class="speech-control flex-column"  :class="{ notAllowed: !isAllowedToSpeak }">
        <asr-timer v-if="enableAsrTimer && asrTimer" :days="asrTimer.days" :hours="asrTimer.hours" :minutes="asrTimer.minutes" :seconds="asrTimer.seconds"></asr-timer>
          <div class="speech__wrap" v-if="!$isMobile()">
            <div class="keyboard-input-btn" @click="keyboardInput">
              <img src="@/assets/icons/keyinput.svg"  alt="Keyboard input">
            </div>
          
            <!-- start rec button -->
            <div class="mic flex-row" :class="{ pulsing: !speaked}" v-show="!recording && !asrConnecting" 
                v-on="{ 
                  click: !isHoldToTalk ? startRecording : null, 
                  mousedown: isHoldToTalk ? startRecording : null,
                  }"
                >
              <img :src="this.speechModeIcon" alt="Mic">
            </div>

            <!-- stop rec button -->
            <div class="mic mic-stop flex-row" v-show="recording && !isHoldToTalk() && !asrConnecting" @click="stopRecording">
              <img src="@/assets/icons/stop.svg"  alt="Stop">
            </div>

            <!-- audio wave for hold-to-talk-->
            <div class="mic flex-row mic-wave" v-show="recording && isHoldToTalk() && !asrConnecting">
              <svg id="wave" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 38.05">
                <title>Audio Wave</title>
                <path id="Line_1" data-name="Line 1" d="M0.91,15L0.78,15A1,1,0,0,0,0,16v6a1,1,0,1,0,2,0s0,0,0,0V16a1,1,0,0,0-1-1H0.91Z"/>
                <path id="Line_2" data-name="Line 2" d="M6.91,9L6.78,9A1,1,0,0,0,6,10V28a1,1,0,1,0,2,0s0,0,0,0V10A1,1,0,0,0,7,9H6.91Z"/>
                <path id="Line_3" data-name="Line 3" d="M12.91,0L12.78,0A1,1,0,0,0,12,1V37a1,1,0,1,0,2,0s0,0,0,0V1a1,1,0,0,0-1-1H12.91Z"/>
                <path id="Line_4" data-name="Line 4" d="M18.91,10l-0.12,0A1,1,0,0,0,18,11V27a1,1,0,1,0,2,0s0,0,0,0V11a1,1,0,0,0-1-1H18.91Z"/>
                <path id="Line_5" data-name="Line 5" d="M24.91,15l-0.12,0A1,1,0,0,0,24,16v6a1,1,0,0,0,2,0s0,0,0,0V16a1,1,0,0,0-1-1H24.91Z"/>
                <path id="Line_6" data-name="Line 6" d="M30.91,10l-0.12,0A1,1,0,0,0,30,11V27a1,1,0,1,0,2,0s0,0,0,0V11a1,1,0,0,0-1-1H30.91Z"/>
                <path id="Line_7" data-name="Line 7" d="M36.91,0L36.78,0A1,1,0,0,0,36,1V37a1,1,0,1,0,2,0s0,0,0,0V1a1,1,0,0,0-1-1H36.91Z"/>
                <path id="Line_8" data-name="Line 8" d="M42.91,9L42.78,9A1,1,0,0,0,42,10V28a1,1,0,1,0,2,0s0,0,0,0V10a1,1,0,0,0-1-1H42.91Z"/>
                <path id="Line_9" data-name="Line 9" d="M48.91,15l-0.12,0A1,1,0,0,0,48,16v6a1,1,0,1,0,2,0s0,0,0,0V16a1,1,0,0,0-1-1H48.91Z"/>
              </svg>
            </div>

            <!-- connecting loader-->
            <div class="mic loader default-03" v-show="asrConnecting">
              <div class='loader-container'>
                <div class='ball'></div>
                <div class='ball'></div>
                <div class='ball'></div>
                <div class='ball'></div>
                <div class='ball'></div>
                <div class='ball'></div>
                <div class='ball'></div>
                <div class='ball'></div>
                <div class='ball'></div>
                <div class='ball'></div>
              </div>
            </div>
            <!-- language selector -->
            <div class="user-language-selector language-tooltip" @click="showLanguageSelector">
              <img :src="'/data/flags/' + participant.language + '.png'" alt="">
            </div>
        </div>
        <div class="user-language-selector language-tooltip user-language-selector--mobile" @click="showLanguageSelector">
          <img :src="'/data/flags/' + participant.language + '.png'" alt="">
        </div>
      </div>


      <div class="toolbar-right">
        <!--<div class="toolbar-filler"></div>-->
        <div class="settings-control" v-if="!$isMobile()">
          <div class="settings-menu" >
            <transition name="settings_menu">
                <div class="settings-menu__options" v-show="settingsMenuOpen">
                  <div class="settings-menu-item" @click="showRoomSettingsPopup">
                    <div class="settings-menu-item__text">{{ $t('settings_title') }}</div>
                    <a href="javascript:;"><img src="@/assets/icons/settings-tweaks.svg" /></a>
                  </div>
                  <div class="settings-menu-item" v-if="isAllowedToUseGreenScreen" @click="switchMode('chromakey')">
                    <div class="settings-menu-item__text">{{ $t('session_menu_chroma_overlay') }}</div>
                    <a href="javascript:;"><img src="@/assets/icons/sorenson/chromakey.svg" /></a>
                  </div>
                  <div class="settings-menu-item" v-if="isAllowedToUseCC" @click="switchMode('cc')">
                    <div class="settings-menu-item__text">{{ $t('session_menu_cc') }}</div>
                    <a href="javascript:;"><img src="@/assets/icons/sorenson/cc.svg" /></a>
                  </div>
                  <div class="settings-menu-item">
                    <div class="settings-menu-item__text">{{ $t('session_menu_code') }}</div>
                    <a href="javascript:;" @click="showRoomCodePopup"><img src="@/assets/icons/settings-qr.svg" /></a>
                  </div>
                  <div class="settings-menu-item">
                    <div class="settings-menu-item__text">{{ $t('session_menu_resize') }}</div>
                    <div class="font-changer">
                      <a href="javascript:;" class="font-changer__bigger" @click="changeFontSize(1)"><img src="@/assets/icons/settings-fontbigger.svg" alt="Font Bigger"></a>
                      <a href="javascript:;" class="font-changer__smaller" @click="changeFontSize(-1)"><img src="@/assets/icons/settings-fontsmaller.svg" alt="Font Smaller"></a>
                    </div>
                  </div>
                </div>
            </transition>
            <button type="button" class="settings-control__threedots flex-column" @click="toggleSettingsMenuOpen" title="Settings">
            <img src="@/assets/icons/dots.svg" alt="Open Settings">
          </button>
          </div>
        </div>
        <div class="mute-control" v-else @click="toggleTTS">
            <img class="participants-info__icon" v-if="settings.tts_muted" src="@/assets/icons/muted.svg" alt="Mute/Unmute">
            <img class="participants-info__icon" v-else src="@/assets/icons/unmuted.svg" alt="Mute/Unmute">
          </div>
      </div>
      
    </div>
    <div class="hidden-audio-players">
      <audio ref="start_rec_audio" src="/media/rec_start.opus.mp3"></audio>
      <audio ref="stop_rec_audio" src="/media/rec_stop.opus.mp3"></audio>
      <audio ref="tts_rec_audio" src="/media/rec_start.opus.mp3"></audio>
    </div>

  </div>

  <!-- Filter: https://css-tricks.com/gooey-effect/ -->
  <svg style="visibility: hidden; position: absolute;" width="0" height="0" xmlns="http://www.w3.org/2000/svg" version="1.1">
    <defs>
      <filter id="goo"><feGaussianBlur in="SourceGraphic" stdDeviation="5" result="blur" />
        <feColorMatrix in="blur" mode="matrix" values="0 0 0 0 0  0 0 0 0 0  0 0 0 0 0  0 0 0 16 -9" result="goo" />
        <feComposite in="SourceGraphic" in2="goo" operator="atop"/>
      </filter>
    </defs>
  </svg>
</template>

<script>
// @ is an alias to /src
import { mapState, mapGetters, mapMutations } from 'vuex';
import MediaRecorder from 'opus-media-recorder';
// Use worker-loader
import EncoderWorker from 'worker-loader!opus-media-recorder/encoderWorker.js';
// You should use file-loader in webpack.config.js.
// See webpack example link in the above section for more detail.
//import OggOpusWasm from 'opus-media-recorder/OggOpusEncoder.wasm';
//import WebMOpusWasm from 'opus-media-recorder/WebMOpusEncoder.wasm';
import { io } from "socket.io-client";
import ss from "socket.io-stream";
import { v4 as uuidv4 } from 'uuid';
import {Howler} from 'howler';
import emitter from "@/services/emitter.service";
import Swal from 'sweetalert'
import permissions from "@/services/permissions.service";

import RoomCode from '@/components/RoomCode.vue';
import RoomSettings from '@/components/RoomSettings.vue';
import SignupPopup from '@/components/SignupPopup.vue';
import { useTimer } from 'vue-timer-hook';
import AsrTimer from '@/components/AsrTimer.vue';
import contenteditable from 'vue-contenteditable';
import GlossaryService from "@/services/glossary.service";

import Shepherd from 'shepherd.js'
import SessionSecured from "@/components/SessionSecured.vue";
import TextClamp from 'vue3-text-clamp';
import {VSwatches} from "vue3-swatches";

Howler.volume(1);

// Non-standard options
const workerOptions = {
  encoderWorkerFactory: _ => new EncoderWorker(), // eslint-disable-line no-unused-vars
  OggOpusEncoderWasmPath: 'https://cdn.jsdelivr.net/npm/opus-media-recorder@latest/OggOpusEncoder.wasm',
  WebMOpusEncoderWasmPath: 'https://cdn.jsdelivr.net/npm/opus-media-recorder@latest/WebMOpusEncoder.wasm'
};

export default {
  name: 'ConferenceRoom',
  components: {
    VSwatches,
    SessionSecured,
    RoomCode,
    RoomSettings,
    SignupPopup,
    AsrTimer,
    contenteditable,
    TextClamp
  },
  data() {
    return {
      swatches:  ['#00FF00', '#4CBB17', '#228B22', '#0000FF', '#00FFFF'],
      tour: {},
      asrTimer: {},
      idleTimer: {},
      user_id: false,
      recording: false,
      typing: false,
      recorder: false,
      asrSocket: false,
      apiSocket: false,
      scroller: {
        scrolled: false,
        hasNew: false,
        scrollTop: 0
      },
      mode: {
        mode: "default", // | "chromakey" || "cc" || "default"
        theme: "black", // for cc
        margin: "wider",
        align: "center",
      },
      ccToolbarShown: true,
      holding: false,
      //asrSocketStream: false,
      room_token: false,
      transcripts: [],
      partials: [],
      partial: {
        message_id: false,
        original: {
          text: ""
        },
        concat: ""
      },
      partialIncoming: false,
      keyboardInputValue: "",
      started: false,
      speaked: false,
      room: {
        count: 0
      },
      participant: {},
      custom_terms: [],
      isGuest: false,
      settingsMenuOpen: false,
      roomOverlayed: false,
      roomCodePopup: false,
      secureSessionPopup: false,
      roomSettingsPopup: false,
      forumInfoPopup: false,
      roomSignupPopup: false,
      fontSize: 20,
      fontDim: "px",
      maxFontSize: 150,
      minFontSize: 12,
      micActivated: false,
      asrConnecting: true,
      openLanguageSelector: false,
      ttsQueue: [],
      ttsPlaying: false,
      finalMessages: [],
      deletedMessages: [],
      socketRoomOwner: false,
      sessionPassword: "",
      messagesCount: 0,
      sessionStartTime: 0,
      sessionEndTime: 0,
      eventData: {},
      conference: {},
      sessionFeatures: {
        asr: {
          allowed: false,
          reason: "session_participation_is_disabled"
        }
      }, // new session object, TODO: will be refactored to SessionFeatures type with Typescript
    }
  },
  methods: {
    ...mapMutations('auth', ['setTutorialShown']),
    ...mapMutations('nonpersist', ['setShowGuide']),
    setRecording(status) {
      this.recording = status;
    },
    setMicActivated(isActivated) {
      this.micActivated = isActivated;
    },
    mergeStringsIf(string1, string2) {
      let returnString = string1;
      if (string2) {
        returnString += ` ${string2}`;
      }
      if (this.partialIncoming) {
        returnString += ` ...`;
      }
      return returnString;
    },
    keyboardInput() {
      if (!this.isAllowedToSpeak) {
        this.$notify('session_participation_is_disabled', {
          icon: "error",
        });
        return false;
      }
      if (this.typing) {
        this.typing = false;
        this.clearCurrenPartial(true);
      } else {
        this.typing = true;
        if (this.recording) {
          this.stopRecording();
        }

        this.startRoom();

        setTimeout(function() {
          document.querySelector('#keyboard_input_field').focus();
        }, 0);
        this.resetScroller();
      }

    },
    onEnterPressed() {
      this.sendKeyboardMessage(true);
    },
    startRecording(sound = true) {
      console.log("starting");

      if (this.asrConnecting) {
        return false;
      }
      if (!this.isAllowedToSpeak) {
        this.$notify(this.sessionFeatures.asr.reason, {
          icon: "error",
        });
        this.asrConnecting = false;
        this.speaked = true;
        return false;
      } else if (this.enableAsrTimer && (this.asrTimer.isExpired || this.asrTimeLeft <= 0) && !this.participant.features.asr.overdraft){
        this.$notify('error_no_asr_left', {
          icon: "error",
        });
        this.asrConnecting = false;
        this.speaked = true;
        return false;
      }

      this.startRoom();
      if (this.typing) {
        this.typing = false;
        this.clearCurrenPartial(true);
      }
      this.stopTTS();

      navigator.permissions.query({ name: "microphone" }).then((result) => {
        if (result.state === "granted") {
          this.setupRecorder(sound);
          this.setRecording(true);
          this.setMicActivated(true);
        } else {
          if (this.isHoldToTalk()) {
            this.preSetupRecorder();
          } else {
            this.setupRecorder(sound);
          }
        }
        // Don't do anything if the permission was denied.
      });

      this.ttsPlaying = false;
      this.ttsQueue = [];

      if(this.settings.is_autostop_enabled && !this.isHoldToTalk() && sound) {
        this.restartIdleTimer();
      }
    },
    stopRecording(forced = true, cancel = false) {
      // Remove “recording” icon from browser tab
      this.recorder.stream.getTracks().forEach(i => i.stop());
      if (forced) {
        this.soundPlayerRecStop.play();
        this.holding = false;
      }

      if (this.recorder._state != 'inactive') {

        this.recorder.stop();
      }

      if (this.isHoldToTalk() && forced) {
        const appThis = this;
        this.asrConnecting = true;

        if (!this.merging) {
          this.merging = setTimeout(function () {
            if (this.enableAsrTimer) {
              appThis.asrTimer.pause();
            }
            appThis.asrSocket.emit('asr-stop');
            appThis.partial.is_final = true;
            appThis.partial.push = true;
            appThis.mergePartial();
            appThis.setRecording(false);
            appThis.setMicActivated(false);
            appThis.asrConnecting = false;
            appThis.merging = false

          }, 500)
        }
      } else {
        console.log("forced lt")
        if (forced || cancel) {
          if (this.enableAsrTimer) {
            this.asrTimer.pause();
          }
          this.asrSocket.emit('asr-stop');
          this.clearCurrenPartial(true);
        }

        this.setMicActivated(false);
        this.setRecording(false);
        this.asrConnecting = false;
      }

      if (this.settings.is_autostop_enabled && !this.isHoldToTalk() && this.idleTimer) {
        this.restartIdleTimer(false);
      }
    },
    restartRecording(forced = false) {
      if (this.recording) {
        this.asrConnecting = true;
        this.stopRecording(forced);
        this.startRecording(false);
      }
    },
    setRoomToken() {
      if (this.user && this.token) {
        this.room_token = this.token;
      } else if (this.event && this.event.guest_token) {
        this.room_token = this.event.guest_token;
      }
    },
    setSettings(roomSettings) {
      this.$store.dispatch("event/saveSettings", roomSettings.settings)
      this.updateParticipant(roomSettings.profile);
      this.closePopups();
    },
    setSpeechMode(speechMode) {
      this.settings.speech_mode = speechMode;
    },
    toggleTTS() {
      this.settings.tts_muted = !this.settings.tts_muted;
    },
    toggleSettingsMenuOpen() {
      if (!this.secureSessionPopup) {
        this.settingsMenuOpen = !this.settingsMenuOpen;
        this.toggleOverlayed();
      }
    },
    toggleOverlayed() {
      if (!this.secureSessionPopup) {
        this.roomOverlayed = !this.roomOverlayed;
      }
    },
    showRoomCodePopup() {
      this.roomCodePopup = true;
      this.roomOverlayed = true;
      this.settingsMenuOpen = true;
    },
    showForumInfoPopup() {
      this.forumInfoPopup = true;
    },
    hideForumInfoPopup() {
      this.forumInfoPopup = false;
    },
    showRoomSettingsPopup() {
      this.roomCodePopup = false;
      this.roomSettingsPopup = true;
      this.roomOverlayed = true;
      this.settingsMenuOpen = true;
    },
    showRoomSignupPopup() {
      this.roomSignupPopup = true;
    },
    showLanguageSelector() {
      this.roomSettingsPopup = true;
      this.openLanguageSelector = true;
    },
    closePopups() {
      this.roomCodePopup = false;
      this.roomSettingsPopup = false;
      this.roomSignupPopup = false;

      this.settingsMenuOpen = false;
      if (!this.secureSessionPopup) {
        this.roomOverlayed = false;
      }
    },
    switchMode(mode) {
      this.mode.mode = mode;
      if (mode !== 'default') {
        this.ccToolbarShown = true;
        setTimeout(() => {
          this.ccToolbarShown = false;
        }, 1500)
      }
      this.closePopups();
    },
    switchModeTheme() {
      this.mode.theme = this.mode.theme === 'black' ? 'white' : 'black';
      this.closePopups();
    },
    switchModeMargin() {
      this.mode.margin = this.mode.margin === 'wider' ? 'narrower' : 'wider';
      this.closePopups();
    },
    switchModeAlign() {
      this.mode.align = this.mode.align === 'center' ? 'left' : this.mode.align === 'left' ? 'right' : 'center';
      this.closePopups();
    },
    changeFontSize(direction) {
      let newFontSize = parseInt(this.fontSize) + (direction * 4);
      if (newFontSize > this.maxFontSize || newFontSize < this.minFontSize) {
        return false;
      }

      this.fontSize = newFontSize;
    },
    isHoldToTalk() {
      return this.settings.speech_mode == "hold_to_talk";
    },
    isCustomPause() {
      return this.settings.speech_mode == "custom_pause";
    },
    isLiveTranslate() {
      return this.settings.speech_mode == "live_translate";
    },
    startRoom() {
      if (this.started) {
        return;
      }
      this.started = true;
      this.sessionStartTime = Date.now();
    },
    syncRoomSettings() {
      if (this.$isMobile()) {
        this.settings.speech_mode = 'custom_pause';
      }
    },
    generateMessageId() {
      return uuidv4();
    },
    setMessageId() {
      if (!this.partial.message_id) {
        this.partial.message_id = this.generateMessageId();
      }

      return this.partial.message_id;
    },
    pushTranscript(message) {
      this.finalMessages.push(message.message_id);

      let partialIndex = this.partials.findIndex((part => part.message_id == message.message_id));

      if (message.original.text === '_INVALID_' || message.original.text == '_crosstalk_is_detected_') {
        if (partialIndex >= 0) {
          this.partials.splice(partialIndex, 1)
        }

        return false;
      }

      const now = new Date();

      let minutesRaw = now.getMinutes();
      if (minutesRaw < 10) {
        minutesRaw = '0' + minutesRaw;
      }

      let hoursRaw = now.getHours();
      if (hoursRaw < 10) {
        hoursRaw = '0' + hoursRaw;
      }
      const hoursAndMinutes = hoursRaw + ':' + minutesRaw;

      this.transcripts.push({
        ...message,
        time: hoursAndMinutes,
        status: "sent"
      });
      ///this.partial = {};

      if (partialIndex > -1) { // only splice array when item is found
        this.partials.splice(partialIndex, 1); // 2nd parameter means remove one item only
      }

      const appThat = this;
      this.scroller.hasNew = true;

      setTimeout(() => {
          appThat.scrollTranscripts();
      }, 50)

      this.messagesCount++;

      //this.clearCurrenPartial();
      //this.$refs.transcripts_scroll.update();
    },
    isSameLanguageAudio(message_id) {
      let messageFound = this.transcripts.find((part => part.message_id == message_id));
      if (messageFound && messageFound.sender) {
        return this.participant.language.slice(0, 2) == messageFound.sender.language.slice(0,2);
      }

      return false;
    },
    mergePartial(final = true) {
      console.log("merge partial")
      if (this.partial.message_id) {
        this.partial.is_final = final;
        let concatPrev = this.partial.concat;
        this.partial.concat = "";
        if (concatPrev) {
          this.partial.original.text += " " + concatPrev;
        }

        this.sendMessage(this.partial);
      } else {
        this.sendMessage(this.partial);
      }
      clearTimeout(this.merging);
    },
    sendHoldToTalkPartial(newPartial) {
      console.log("partial send")
      let tmpMessage = {
        message_id: newPartial.message_id,
        original: {}
      };
      if (this.partial.original.text) {
        let concatPrev = "";
        if (this.partial.original.text && this.partial.concat) {
          concatPrev = this.partial.original.text;
        }
        tmpMessage.original.text = concatPrev + " " + newPartial.original.text ;

        tmpMessage.is_final = false;
        this.sendMessage(tmpMessage);
      } else {
        newPartial.is_final = false;
        this.sendMessage(newPartial);
      }
    },
    sendKeyboardMessage(is_final) {
      let text = "...";
      if (this.keyboardInputValue.length > 0) {
        text = this.keyboardInputValue;
      } else if (is_final) {
        return false;
      }
      let message = {
        message_id: this.setMessageId(),
        original: {
          text: text
        },
        is_final: is_final,
      };
      this.sendMessage(message);

      if (is_final) {
        this.keyboardInputValue = '';
      }


      setTimeout(function() {
        document.querySelector('#keyboard_input_field').focus();
      }, 0);
    },
    clearCurrenPartial(deletemsg = false) {
      if (this.partial.message_id) {
        if (deletemsg) {
          this.deletedMessages.push(this.partial.message_id);
          this.sendMessage({
            message_id: this.partial.message_id,
            original: {
              text: "_INVALID_"
            },
            is_final: true
          });
        }

        this.partial = {
          message_id: false,
          original: {
            text: ''
          },
          concat: ''
        };

        this.keyboardInputValue = "";
        //this.typing = false;
      }
    },
    isOwnMessage(message) {
      return this.user_id && this.user_id == message.sender.sender_id;
    },
    hasOwnMessage() {
      return this.partial.original && (this.partial.original.text.length > 0 || this.partial.concat.length > 0);
      /*if (this.partials.length <= 0) {
        return false;
      } else {
        let partialIndex = this.partials.findIndex((message => message.sender_id == this.user_id));
        return partialIndex < 0;
      }*/
    },
    pushPartial(partial) {

      if (this.finalMessages.includes(partial.message_id)) {
        console.log("already")
        return false;
      }

      let senderId = partial.sender_id ?? partial.sender.sender_id;
      if (this.partials.length > 0) {
        let sameSenderMessageIndex = this.partials.findIndex((message => message.sender && message.sender.sender_id == senderId));
        if (sameSenderMessageIndex >= 0) {
          if (partial.message_id != this.partials[sameSenderMessageIndex].message_id) {
            this.partials.splice(sameSenderMessageIndex, 1)
          }
        }
      }

      let partialIndex = this.partials.findIndex((message => message.message_id == partial.message_id));
      if (partial.original.text === '_INVALID_' || partial.original.text == '_crosstalk_is_detected_') {
        if (partialIndex >= 0) {
          this.partials.splice(partialIndex, 1)
        }

        return false;
      }

      if (partialIndex >= 0) {
        this.partials[partialIndex] = partial;
      } else {
        this.partials.push(partial);
      }

      this.scrollTranscripts();
    },
    pushOwnPartial(partial) {
      if (this.finalMessages.includes(partial.message_id)) {
        return false;
      }

      //let partialIndex = this.partials.findIndex((message => message.message_id == partial.message_id));

      if (partial.original.text === '_INVALID_' || partial.original.text == '_crosstalk_is_detected_') {
        return false;
      }

      if (this.isHoldToTalk()) {

        this.partial.concat = partial.concat;
        if (partial.is_final) {
          this.partial.original.text += " " + partial.original.text;
          this.partial.concat = "";
        }
      } else {
        this.partial.original.text = partial.original.text;
      }

      this.scrollTranscripts();
    },
    hasTranslationForUser(transcript) {
      const mainLanguage = this.participant.language.slice(0, 2);
      const originalLanguage = transcript.sender.language.slice(0, 2);

      return mainLanguage !== originalLanguage &&  (typeof transcript.translations[this.participant.language] === 'object' &&  transcript.translations[this.participant.language] !== null);
    },
    isSameLanguage(lang1, lang2) {
      const mainLanguage = lang1.slice(0, 2);
      const originalLanguage = lang2.slice(0, 2);

      return mainLanguage === originalLanguage;
    },
    getTranslationForUser(transcript) {
      return transcript.translations[this.participant.language];
    },
    transformTimerSlots(props) {
      const formattedProps = {};

      Object.entries(props).forEach(([key, value]) => {
        formattedProps[key] = value < 10 ? `0${value}` : String(value);
      });

      return formattedProps;
    },
    sendMessage(message) {
        this.apiSocket.emit("message", message);
    },
    closeSocket() {
      this.apiSocket.emit("close_connection");
      this.asrSocket.disconnect();
      this.sessionEndTime = Date.now();

      const trackEventData = {
        duration: this.sessionDuration,
        messages_number: this.finalMessages.length,
        room_code: this.eventData.code,
        title: this.eventData.title,
        role: this.socketRoomOwner ? "Host" : "Participant",
        room_type: this.eventData.room_type,
        participants_number: this.room.count,
        room_protected: this.eventData.is_room_protected ? "Yes" : "No",
        particicipation: this.eventData.is_participating_disabled ? "disabled" : "enabled"
      };
      window.pendo.track("session_exit", trackEventData);

      if (trackEventData.messages_number >= 1) {
        this.setShowGuide(true);
      }
    },
    closedRoomMessage() {
      let swalDescription = this.$t('service_event_ended');
      Swal({
        title: this.$t('session_ended'),
        text: swalDescription,
        icon: 'warning',
        dangerMode: false,
        buttons: {
          confirm: {
              text: "OK",
              value: true,
              visible: true,
              className: "",
              closeModal: true
            }
        },
      }).then(() => {
        this.$store.dispatch("event/close");
        this.closeSocket();
        this.$router.replace("/");
      });
    },
    preSetupRecorder() {
        // setup recorder
        navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {

          if (this.recorder && this.recorder.state !== 'inactive') {
            throw new Error('Stop the recorder first');
          }
          return stream;
        }).then((stream) => {
          let options = { mimeType: 'audio/ogg;codecs=opus' };
          // Start recording
          this.recorder = new MediaRecorder(stream, options, workerOptions);
          this.stopRecording(false);
        });
    },
    setupRecorder(sound = true) {
      // setup recorder
      navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {

          if (this.recorder && this.recorder.state !== 'inactive') {
              throw new Error('Stop the recorder first');
          }
          return stream;

      }).then((stream) => {
        if (sound) {
          this.soundPlayerRecStart.play();
        }

        this.setRecording(true);
        this.setMicActivated(true);

        if (this.enableAsrTimer) {
          if (this.speaked) {
            this.asrTimer.resume();
          } else {
            this.asrTimer.start();
          }
        }
        this.speaked = true;
        this.asrConnecting = false;
        let options = { mimeType: 'audio/ogg;codecs=opus' };
          // Start recording
          this.recorder = new MediaRecorder(stream, options, workerOptions);
          // Set record to <audio> when recording will be finished
          this.recorder.ondataavailable = (e) => {
            let blob = e.data;
            let stream = ss.createStream();
            // stream directly to server
            ss(this.asrSocket).emit('audio', stream, {
                size: blob.size
            });

            // pipe the audio blob to the read stream
            ss.createBlobReadStream(blob).pipe(stream);
            if (!this.partial.message_id) {
              this.partial.message_id = this.generateMessageId();
            }
          };
          let combinedTerms = [];
          let langTerms = this.custom_terms.filter((term) => {
            return term.language === "all" ||
                term.language === this.participant.language ||
                this.participant.language.includes(term.language);
          });
          if (this.event.custom_vocabulary && this.event.custom_vocabulary.length > 0) {
            let tmpTerms = this.event.custom_vocabulary.filter((term) => {
              return term.language === "all" ||
                  term.language === this.participant.language ||
                  this.participant.language.includes(term.language);
            });
            let mergedTerms = tmpTerms.concat(langTerms.filter(
                item2 => !tmpTerms.some(item1 => item1.term.toLowerCase() === item2.term.toLowerCase())));
            combinedTerms = mergedTerms.map(a => a.term);
          } else {
            combinedTerms = langTerms.map(a => a.term);
          }

          const wait_k = this.hidden_settings.wait_k ?? 1;

          this.asrSocket.emit('asr-streaming_config', {
            language: this.participant.language,
            token: this.room_token,
            speech_mode: this.$isMobile() ? 'custom_pause' : this.settings.speech_mode,
            ASR_URL: this.event.asr_url,
            ASR_PORT: this.event.asr_port,
            profanity_filter: this.settings.profanity_filter,
            custom_terms: combinedTerms,
            asr_version: this.hidden_settings.asr_version ? this.hidden_settings.asr_version : 'v1',
            wait_k: wait_k,
            conversation_id: this.event.id
          });
          this.recorder.start(100);

      }).catch(e => {
          console.log(`MediaRecorder is failed: ${e.message}`);
          //Promise.reject(new Error());
      });
    },
    setupAsrSocket() {
      let asrSocketObject = io(process.env.VUE_APP_SOCKET_URL, {
        path: "/socket.io/",
        auth: {
            token: this.room_token,
            room_code: this.event.room_code
        },
        //transports: ["polling"]
      });

      this.asrSocket = asrSocketObject.on('connect', () => {
        this.asrConnecting = false;
      });

      asrSocketObject.on('connection', () => {
      });

      // On receive ASR result
      asrSocketObject.on('asr', (data) => {
        let recognitionResult = data.result;
        let recognitionText = "";
        if (this.isCustomPause() || this.isHoldToTalk()) {
          recognitionResult = data.result.results[0];
          recognitionText = recognitionResult.alternatives[0].transcript.trim();
        } else {
          recognitionText = recognitionResult.recognition.trim();
        }

        if (recognitionText == '_crosstalk_is_detected_'
            || recognitionText == '_INVALID_') {
          return false;
        }

        if (!recognitionText || recognitionText === '') {
          if (!(this.isLiveTranslate() && recognitionResult.is_final)) {
            return false;
          }
        }

        if (this.settings.is_autostop_enabled && !this.isHoldToTalk() && this.idleTimer) {
          this.restartIdleTimer(true);
        }

        let message = {
          message_id: this.setMessageId(),
          original: {
            text: recognitionText
          },
          concat: ""
        };

        if (this.isCustomPause()) {
          message.is_final = recognitionResult.is_final;
        } else if (this.isHoldToTalk()) {
          message.is_final = recognitionResult.is_final;
        } else {
          message.is_final = recognitionResult.is_final || recognitionResult.is_stabilized
        }
        
        if (!this.isHoldToTalk()) {
          this.pushOwnPartial(message);
          this.sendMessage(message);
        } else {
          message.concat = message.original.text;
          
          this.pushOwnPartial(message);
          this.sendHoldToTalkPartial(message);
        }
      

        if (recognitionResult.is_force_stop) {
          this.restartRecording();
        }

        if (recognitionResult.is_final) {
          this.restartRecording();
        }
      });

      // On receive ASR end 
      asrSocketObject.on('asr-end', () => {
        if (this.recording) {
          this.restartRecording();
        }
      });

      // On receive ASR end
      asrSocketObject.on('asr-error', (e) => {
        if (this.recording) {
          this.stopRecording();
          this.$notify(e.error.details, {
            icon: "error",
            toast: true
          });
          console.log(e);
        }
      });
    },
    askSecurePassword() {
      this.secureSessionPopup = true;
      this.roomOverlayed = true;
    },
    setPasswordProvided(pwd) {
      this.sessionPassword = pwd;
      this.setupApiSocket();
    },
    setupApiSocket() {
      if (this.event.is_room_protected && !this.sessionPassword) {
        this.askSecurePassword();
        return false;
      }

      let socketAuth = {
        token: this.room_token,
        room_code: this.event.room_code,
        udid: this.udid
      };

      // TODO: this can be refactored for beautier code
      if(this.user && this.authorized) {
        socketAuth.language = this.user.language;
        socketAuth.display_name = this.user.name;
        socketAuth.gender = this.user.voice;
      } else if (this.guest) {
        socketAuth.language = this.guest.language;
        socketAuth.display_name = this.guest.name;
        socketAuth.gender = this.guest.voice;
      }

      if (this.event.is_room_protected) {
        socketAuth.password = this.sessionPassword;
      }

      let apiSocketObject = io(this.event.socket_server, {
        path: "/socket.io/",
        auth: socketAuth,
        transports: ["websocket"]
      });

      this.apiSocket = apiSocketObject.on('connect', () => {
        console.log("connected api");
      });
      
      apiSocketObject.on("connection", (data) => {
        this.conference = data.conference;
        this.secureSessionPopup = false;
        this.roomOverlayed = false;

        this.is_room_owner = data.is_room_owner;
        this.socketRoomOwner = data.is_room_owner;
        this.setParticipant(data.user);

        if (this.is_room_owner || this.socketRoomOwner) {
          this.showRoomCodePopup();
        } else {
          if (this.isAllowedToSpeak) {
            this.showRoomSettingsPopup();
          } else if(!this.isGuest) {
            this.$notify('session_participation_is_disabled', {
              icon: "error",
            });
          } else if (this.isGuest && !this.preRoomPassed ) {
            this.showLanguageSelector();
          }
        }
      });

      apiSocketObject.on("features", (data) => {
        this.sessionFeatures = data;
      });

      apiSocketObject.on("connect_error", (err) => {
        if (err.message === 'password_invalid') {
          this.$notify(err.message, {
            icon: "error",
            toast: true
          });
        } else {
          this.$notify(err.message, {
            icon: "error",
          });
        }
      });

      apiSocketObject.on("notification", (notification) => {
          this.$notify(notification.message, {
              icon: "info",
              toast: true
          });
      });

      apiSocketObject.on("connected_guests", (data) => {
          this.room.count = data.count;
      });

      apiSocketObject.on("message", (data) => {
        if (!this.started) {
            this.startRoom();
        }

        let newPartial = data;
        if (!this.isOwnMessage(newPartial)) {
          console.log("not own");
          if (this.mode.mode !== 'default') {
            if (this.isSameLanguage(newPartial.sender.language, this.participant.language)) {
              this.pushPartial(newPartial);
              this.partialIncoming = true;
            } else {
              this.partialIncoming = true;
            }
          } else {
            this.pushPartial(newPartial);
            this.partialIncoming = true;
          }

        } else {
          console.log("own")
        }
        
        if (newPartial.is_final) {
          this.pushTranscript(newPartial);
          this.partialIncoming = false;

          if (this.isOwnMessage(newPartial)) {
            this.clearCurrenPartial();
          }
        }
      });

      apiSocketObject.on("room_was_closed", () => {

        this.closedRoomMessage();
        this.closeSocket();
      });

      apiSocketObject.on("tts", (data) => {
        if (this.settings.tts_muted || !permissions.can("tts", this.participant) || (this.settings.tts_same_muted && this.isSameLanguageAudio(data.message_id))) {
          return;
        }
        
        const audio = data.audio;
        if (audio) {
          this.pushTTSqueue(this.getTTSsrc(audio));
        }
      });


    },
    pushTTSqueue(ttsSrc)  {
      if (!this.recording) {
        this.ttsQueue.push(ttsSrc);
        this.playTTS();
      }
    },
    playTTS() {
      if (this.ttsQueue.length <= 0) {
        return false;
      }  
      if (!this.ttsPlaying) {
        const firstAudio = this.ttsQueue.shift();
        this.soundPlayerTTS.src = firstAudio;
        this.soundPlayerTTS.playbackRate = this.settings.tts_speed / 100;
        this.soundPlayerTTS.play();
        this.ttsPlaying = true;
        const context = this;
        this.soundPlayerTTS.onended = function(){
          context.ttsPlaying = false;
          context.playTTS();
        };
      }
    },
    stopTTS() {
      if (this.ttsPlaying) {
        this.soundPlayerTTS.pause();
        this.soundPlayerTTS.currentTime = 0;
      }
    },
    setParticipant(socketUser) {
      if (socketUser) {
        this.participant.features = socketUser.features;
        this.sessionFeatures.asr.allowed = this.participant.features.asr.allowed;
        return;
      }
      if (this.user && this.authorized) {
        this.participant = this.user;
        this.isGuest = false;
      } else {
        this.participant = this.guest;
        this.isGuest = true;
      }
    },
    setCustomTerms() {
      GlossaryService.getTerms(this.token).then((data) => {
        let termsObjectsArray = data.data.result;
        termsObjectsArray.forEach((term) => {
          this.custom_terms.push(term);
        })
      }).catch((error) => {
        console.log(error);
      })
    },
    updateParticipant(profile) {
      this.participant.language = profile.language;
      this.participant.name = profile.name;
      this.participant.voice = profile.voice;

      this.apiSocket.emit("update_profile", {
        display_name: this.participant.name ? this.participant.name : "Guest",
        language: this.participant.language,
        gender: this.participant.voice
      });

      this.openLanguageSelector = false;
    },
    setKeyboardShortcuts() {
      const spaceKey = 'Space'; // space
      const escapeKey = 'Escape';
      const altEscapeKey = "Esc";

      window.addEventListener('keydown', (e) => {
        if(this.$route.name == 'room') {
          if (e.code == spaceKey) {
            if (this.anyPopupShown || this.typing) {
              return false;
            }

            if (this.holding) {
              if (this.recording) {
                return false;
              } else {
                this.holding = false;
              }
            } else {
              this.holding = true;
            }

            if (this.isHoldToTalk()) {
              if (!this.micActivated) {
                this.startRecording();
              }
            } else {
              if (!this.micActivated) {
                this.startRecording();
              } else {
                this.stopRecording();
              }
            }

          }

          if (e.code == escapeKey || e.code == altEscapeKey) {
            if (this.recording) {
              this.stopRecording(false, true);
            } else if (this.typing) {
              //this.keyboardInput();
              this.keyboardInputValue = "";
            }
          }
        }
      });

      window.addEventListener('keyup', (e) => {
        if(this.$route.name == 'room') {
          if (e.code == spaceKey && this.recording) {
            
            if (this.isHoldToTalk()) {
              this.holding = false;
              this.stopRecording();
              
            } else {
              this.holding = false;
              
            }
            
          }
        }
      });
      
    },
    getTTSsrc(arrayBuffer) {
      let arrayBufferView = new Uint8Array(arrayBuffer);
      let blob = new Blob( [ arrayBufferView ], { type: 'audio/mp3' } );
      return URL.createObjectURL(blob);
    },
    _unlockAudio() {
      this.soundPlayerRecStart.play();
      this.soundPlayerRecStart.pause();
      this.soundPlayerRecStart.currentTime = 0;

      this.soundPlayerRecStop.play();
      this.soundPlayerRecStop.pause();
      this.soundPlayerRecStop.pause();

      this.soundPlayerTTS.play();
      this.soundPlayerTTS.pause();
      this.soundPlayerTTS.pause();

      this.setTutorialShown(true); // temp disable tutorial
      /*if (!this.tutorialShown && !this.anyPopupShown) {
        this.tour.start();
        this.setTutorialShown(true);
      }*/
      
      if (this.tutorialShown) {
        document.body.removeEventListener('click', this._unlockAudio)
        document.body.removeEventListener('touchstart', this._unlockAudio)
      }
    },
    resetScroller() {
      this.scroller = {
        scrolled: false,
        hasNew: false
      }
      this.scrollTranscripts();
    },
    scrollTranscripts() {
      if (this.scroller.scrolled) {
        return false;
      }
      let scrollEl = document.querySelector(".screen__scroll");
      scrollEl.scrollTop = scrollEl.scrollHeight;
    },
    restartIdleTimer(autostart) {
      const autostop_timeout = this.hidden_settings.autostop_timeout ?? 1800;

      const idleTime = new Date();
      idleTime.setSeconds(idleTime.getSeconds() + autostop_timeout);

      this.idleTimer = useTimer(idleTime, false);
      // Restarts to 5 minutes timer
      this.idleTimer.restart(idleTime, autostart);
    },
    stopIdleTimer() {
      if (this.idleTimer) {
        this.idleTimer.restart(false);
      }
    }
  },
  computed: {
    ...mapState('auth', ['agreed', 'authorized', 'token', 'tutorialShown']),
    ...mapState('lang', ['languages']),
    ...mapState('event', ['event', 'is_room_owner', 'guest', 'settings', 'preRoomPassed']),
    ...mapGetters('auth', [
      'udid',
    ]),
    ...mapState('nonpersist', ['user', 'asrTimeLeft', 'sessionPassword']),
    ...mapState('hidden', ['hidden_settings']),
    anyPopupShown() {
      return this.roomCodePopup || this.roomSettingsPopup || this.secureSessionPopup;
    },
    isAllowedToSpeak() {
      // TODO: will be refactored to acknowledge session roles
      return this.sessionFeatures.asr.allowed;
    },
    isAllowedToUseGreenScreen() {
      return permissions.can('green_screen', this.participant);
    },
    isAllowedToUseCC() {
      return permissions.can('closed_captioning', this.participant);
    },
    speechModeIcon() {
      let modeIcon = "holdtotalk";

      switch (this.settings.speech_mode) {
        case "custom_pause":
          modeIcon = "pausetotranslate";
          break;
        case "live_translate":
          modeIcon = "livetranslate";
          break;
      }
      return require(`@/assets/icons/${modeIcon}.svg`);
    },
    defaultText() {
      let modeTitle = "Hold To Talk";

      switch (this.settings.speech_mode) {
        case "custom_pause":
          modeTitle = "Click To Talk";
          break;
        case "live_translate":
          modeTitle = "Click To Talk";
          break;
      }

      return modeTitle;
    },
    transcriptsWidth() {
      return `calc(70% + ${this.fontSize * 8}px)`;
    },
    transcriptsJustify() {
      return this.mode.align === 'left' ? 'flex-start' : this.mode.align === 'right' ? 'flex-end' : 'center';
    },
    sessionDuration() {
      const diffMs = this.sessionEndTime - this.sessionStartTime;
      return Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    },
    enableAsrTimer() {
      return !this.event.is_countdown_disabled && !this.conference.is_countdown_disabled;
    }
  },
  watch:{
     'keyboardInputValue': function (){
       if (this.typing) {
        this.sendKeyboardMessage(false);
       }
     },
    'idleTimer.isExpired': function (value){
       if (!this.isHoldToTalk() && value) {
         this.stopIdleTimer();
         this.stopRecording();
       }
    }
  },
  async mounted() {
    this.setRoomToken();
    this.setParticipant();
    this.setCustomTerms();
    this.user_id = this.udid;
    
    if (this.user || this.guest) {
      this.setupAsrSocket();
      this.setupApiSocket();
    }
    this.setKeyboardShortcuts();

    document.querySelector('.screen__scroll').addEventListener("scroll", (e) => {
      const scroller = e.target;
      if (scroller.scrollTop + scroller.offsetHeight >= scroller.scrollHeight) {
        this.scroller.scrolled = false;
        this.scroller.hasNew = false;
      } else {
        if (scroller.scrollTop < this.scroller.scrollTop) {
          this.scroller.scrolled = true;
        }
      }
      this.scroller.scrollTop = scroller.scrollTop;
    })

    this.$refs.roomwrap.addEventListener("mouseup", () => {
      if (this.isHoldToTalk() && this.recording) {
        this.stopRecording();
      }
    })

    this.syncRoomSettings();

    if (this.enableAsrTimer) {
      const time = new Date();
      time.setSeconds(time.getSeconds() + this.asrTimeLeft);
      this.asrTimer = useTimer(time, false);
    }


    this.soundPlayerRecStart = this.$refs.start_rec_audio;
    this.soundPlayerRecStop = this.$refs.stop_rec_audio;
    this.soundPlayerTTS = this.$refs.tts_rec_audio;

    document.body.addEventListener('click', this._unlockAudio);
    document.body.addEventListener('touchstart', this._unlockAudio);

    if (!this.tutorialShown ) {
      this.tour = new Shepherd.Tour({
        useModalOverlay: true,
        defaultStepOptions: {
          classes: 'shadow-md bg-purple-dark',
          scrollTo: { behavior: "smooth", block: "center" },
          popperOptions: {
            modifiers: [{ name: "offset", options: { offset: [0, 32] } }]
          },
          modalOverlayOpeningPadding: 10,
          when: {
            show() {
              const currentStep = Shepherd.activeTour?.getCurrentStep();
              const currentStepElement = currentStep?.getElement();
              const footer = currentStepElement?.querySelector('.shepherd-footer');
              const progress = document.createElement('span');
              progress.className = 'shepherd-progress';
              progress.innerText = `${Shepherd.activeTour?.steps.indexOf(currentStep) + 1} of ${Shepherd.activeTour?.steps.length}`;
              footer?.insertBefore(progress, currentStepElement.querySelector('.shepherd-skip'));
            }
          }
        },
      });

      let steps = [];
      if (this.asrTimeLeft > 0 && this.isAllowedToSpeak) {
        steps.push({
          id: 'timer-step',
          text: this.$t('session_walkthrought_timer_desc'),
          attachTo: {
            element: '.timer',
            on: 'top'
          },
          classes: 'example-step-extra-class',
          buttons: [
            {
              text: this.$t('common_skip'),
              action: this.tour.complete,
              classes: "shepherd-skip"
            },
            {
              text: this.$t('common_next'),
              action: this.tour.next
            }
          ],
        });
      }

      if (!this.$isMobile()) {
        steps.push({
            id: 'record-step',
            text: this.$t('session_walkthrought_record_desc'),
            attachTo: {
              element: '.mic',
              on: 'top'
            },
            classes: 'example-step-extra-class',
            buttons: [
              {
                text: this.$t('common_skip'),
                action: this.tour.complete,
                classes: "shepherd-skip"
              },
              {
                text: this.$t('common_next'),
                action: this.tour.next
              }
            ],
        });

        steps.push({
            id: 'record-step2',
            text: this.$t('session_walkthrought_cancel_desc'),
            attachTo: {
              element: '.mic',
              on: 'top'
            },
            classes: 'example-step-extra-class',
            buttons: [
              {
                text: this.$t('common_skip'),
                action: this.tour.complete,
                classes: "shepherd-skip"
              },
              {
                text: this.$t('common_next'),
                action: this.tour.next
              }
            ],
        });
      }

      steps.push({
          id: 'lang-step',
          text: this.$t('session_walkthrought_language_desc'),
          attachTo: {
            element: '.language-tooltip',
            on: 'top'
          },
          classes: 'example-step-extra-class',
          buttons: [
            {
              text: this.$t('common_skip'),
              action: this.tour.complete,
              classes: "shepherd-skip"
            },
            {
              text: this.$t('common_next'),
              action: this.tour.next
            }
          ],
      });

      steps.push({
          id: 'audience-step',
          text: this.$t('session_walkthrought_audience_desc'),
          attachTo: {
            element: '.participants-info',
            on: 'top'
          },
          classes: 'example-step-extra-class',
          buttons: [
            {
              text: this.$t('common_skip'),
              action: this.tour.complete,
              classes: "shepherd-skip"
            },
            {
              text: this.$t('common_next'),
              action: this.tour.next
            }
          ],
      });
      if (!this.$isMobile()) {
        steps.push({
          id: 'settings-step',
          text: this.$t('session_walkthrought_settings_desc'),
          attachTo: {
            element: '.settings-control',
            on: 'top'
          },
          classes: 'example-step-extra-class',
          buttons: [
            {
              text: this.$t('common_skip'),
              action: this.tour.complete,
              classes: "shepherd-skip"
            },
            {
              text: this.$t('common_finish'),
              action: this.tour.complete
            }
          ],
        })
      } else {
        steps.push({
          id: 'settings-step',
          text: this.$t('session_walkthrought_mobile_mute'),
          attachTo: {
            element: '.mute-control',
            on: 'top'
          },
          classes: 'example-step-extra-class',
          buttons: [
            {
              text: this.$t('common_skip'),
              action: this.tour.complete,
              classes: "shepherd-skip"
            },
            {
              text: this.$t('common_finish'),
              action: this.tour.complete
            }
          ],
        })
      }

      this.tour.addSteps(steps);
    }

    this.eventData = {
      title: this.event.title,
      code: this.event.room_code,
      is_room_protected: this.event.is_room_protected,
      is_participating_disabled: this.event.is_participating_disabled,
      room_type: this.event.room_type
    };
  },
  created() {
    emitter.on("close_room", () => {
        this.apiSocket.emit("close_room", {});
        this.$store.dispatch("event/close");
        this.$router.replace("/");
        this.closeSocket();
    });

    emitter.on("quit_room", () => {
      this.$store.dispatch("event/close");
      this.$router.replace("/");
      this.closeSocket();
    });
  },
  beforeUnmount() {
    console.log("unmounting");
    this.closeSocket();
  }
}
</script>

<style lang="scss">
  
  .conference-meta {
    font-family: 'Inter', sans-serif;
    position: absolute;
    top: 50px;
    width: 100%;
    z-index: 5;

    &__title {
      font-weight: 700;
      font-size: 1.875rem;
      line-height: 2.2rem;
      text-transform: uppercase;
      text-align: center;

      /* White Audience */

      color: #FFFFFF;
      margin-bottom: 4px;
    }

    &__speaker {
      text-align: center;
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 1.7rem;
      text-transform: capitalize;

      color: #C2C2C2;
    }

    &__logo {
      display: flex;

      img {
        width: 125px;
      }
    }
  }

  .room {
    height: 100%;

    &.anyPopupShown {
      justify-content: center;
      align-items: center;
    }
    
    &-overlay {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background: rgb(0,0,0);
      background: linear-gradient(90deg, rgba(0,0,0,0.6727065826330532) 60%, rgba(0,0,0,1) 97%, rgba(0,0,0,1) 100%);
      z-index: 4;
    }
  }

  .screen {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-top: 30px;
    width: 70%;
    margin: auto;
    position: relative;

    @media all and (max-width: 1100px) {
      width: 100%;
    }

    &__scroll {
      min-height: 600px;
      max-height: 600px;
      width: 100%;
      margin: auto auto 0 auto;
      opacity: 0;
      visibility: hidden;
    
      &.visible {
        opacity: 1;
        visibility: visible;
        position: relative !important;
      }

      .scrollbar__content {
        width: 100%;
        height: 100%;
        min-height: 600px;
        display: flex;
      }

    }
    .screen__default {
      font-weight: 700;
      font-size: 3rem;
      line-height: 3.625rem;
      text-align: center;

      color: #FFFFFF;

      opacity: 0.2;
      margin: auto;
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      flex-direction: column;

      img {
        width: 5rem;
      }
    }
  }

  .toolbar {
    display: flex;
    margin-top: auto;
    width:100%;
    justify-content: space-between;
    padding: 0px 30px 24px;
    align-items: flex-end;

    &-left, &-right {
      display: flex;
    }

    &-filler {
      width: 75px;
      height: 75px;
      display: flex;
      margin: 0 20px;
    }

    .mute-control {
      border: 2px solid #464646;
      border-radius: 50%;
      width: 60px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .participants-info {
      background: rgba(43, 43, 43, 0.8);
      border: 2px solid #464646;
      border-radius: 50%;
      width: 75px;
      height: 75px;
      justify-content: center;
      align-items: center;

      @media (max-width: 1100px) {
        width: 60px;
        height: 60px;
      }

      &__icon {
        width: 32px;
        margin-bottom: 4px;
      }

      &__count {
        font-weight: 700;
        font-size: 0.875rem;
        line-height: 1.1rem;

        /* White Audience */

        color: #FFFFFF;

        @media all and (max-width: 1100px) {
          font-size: 0.65rem;
          line-height: 0.9rem;
        }
      }
    }

    .settings-control {
      width: 75px;
      height: 75px;
      
      
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      position: relative;
      z-index: 5;
      /*right: 30px;*/
      
      &__threedots {
        background: transparent;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 75px;
        
        outline: none;
        cursor: pointer;
      }
    }

    .settings-menu {
      flex-direction: column;
      justify-content: flex-end;
      width: 100%;
      background: rgba(43, 43, 43, 0.8);
      position: absolute;
      bottom: 0;
      border: 2px solid #464646;
      border-radius: 47px;

      &__options {
        padding-top: 26px;
      }

      &-item {
        display: flex;
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;

        &:first-child {
          margin-top: 0;
        }

        img {
          max-width: 42px;
        }

        &__text {
          width: 124px;
          text-align: right;
          font-weight: 400;
          font-size: 1.125rem;
          line-height: 1.7rem;
          color: #FFFFFF;
          position: absolute;
          left: -140px;
        }
      }

      .font-changer {
        background: #111111;
        border-radius: 27.4641px;
        display: flex;
        flex-direction: column;
        width: 54px;

        &__bigger {
          text-align: center;
          padding: 18px 0 15px;
          border-bottom: 2px solid rgba(255, 255, 255, 0.25);
          display: inline-flex;
          justify-content: center;
        }

        &__smaller {
          text-align: center;
          padding: 15px 0 18px;
          display: inline-flex;
          justify-content: center;
        }
      }
    }

    .speech-control {
      position: relative;

      &__status {
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 1rem;
        color: #FFFFFF;
        text-transform: uppercase;
        text-align: center;
        position: absolute;
        width: 100%;
        top: -28px;
      }

      .mic {
        width: 120px;
        height: 120px;
        justify-content: center;
        align-items: center;
        background: #FFFFFF;
        border-radius: 50%;
        cursor: pointer;
        margin: 0 24px;

        @media all and (max-width: 00px) {
          width: 85px;
          height: 85px;

          img {
            max-width: 30px;
          }
        }
      }

      .mic-stop, .mic.loader {
        background: #EA4335;
      }

      .mic-wave {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #EA4335;
      }

      #wave {
        height: 70px;
        width: 70px;
        fill: #fff;
      }

      @for $i from 1 through 9 {
        #Line_#{$i} {
          animation: micpulse 1s infinite;
          animation-delay: $i * .15s;
        }
      }

      @keyframes micpulse {
        0% {
          transform: scaleY(1);
          transform-origin: 50% 50%;
        }
        
        50% {
          transform: scaleY(.7);
          transform-origin: 50% 50%;
        }
        
        100% {
          transform: scaleY(1);
          transform-origin: 50% 50%;
        }
      }
      

      .timer {
        font-weight: 700;
        font-size: 1.375rem;
        line-height: 1.7rem;
        color: rgba(255, 255, 255, 0.5);
        text-align: center;
        margin-top: 10px;
        height: 27px;
        margin-bottom: 10px;

        span {
          min-width: 70px;
          
        }

        @media all and (max-width: 768px) {
          display: none;
        }
      }

      &.notAllowed {
        .mic {
          animation: none !important;
          opacity: 0.6;
          cursor: default;
        }

        .keyboard-input-btn {
          opacity: 0.6;
          cursor: default;
        }
      }
    }
  }

  .transcripts {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 30px;
    align-items: flex-end;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.125rem;
    min-height: 100%;

    @media all and (max-width: 1100px) {
      padding: 0 15px;
    }

    color: #FFFFFF;
    justify-content: flex-end;

    &__item {
      padding: 15px;
      border-radius: 15px;
      background: rgba(255, 255, 255, 0.05);
      border-radius: 10px;
      width: 100%;
      margin-bottom: 20px;
       position: relative;

      /*&:last-child {
        margin-bottom: 0;
      }*/

      &--partial {
        background: #FFFFFF;
        color: #000000;

        .transcript__sendername {
          color: #000000;
        }

        .transcript__flag {
          margin-top: 5px;
        }
      }

      &.keyboard-input {
        padding-right: 60px;
        position: relative;
        display: flex;

        .keyboard-input__send {
          width: 30px;
          display: flex;
          position: absolute;
          top: 0;
          right: 15px;
          align-items: center;
          height: 100%;

          img {
            max-width: 100%;
          }
        }
      }

      .transcript__original.noTranslations {
        opacity: 0.5;
        border-bottom: 1px solid grey;
        padding-bottom: 4px;

        .transcript__text {
          font-size:  v-bind('fontSize * 0.6 + fontDim');
          line-height: 150%;
        }

        .transcript__flag {
          width: v-bind('fontSize * 0.8 * 1.2 + fontDim');
          height: v-bind('fontSize * 0.8 * 1.2 + fontDim');
        }
      }

      .transcript__original {

        .transcript__read {
          width: 24px;
          position: absolute;
          right: 15px;
          bottom: 10px;
        }
      }

      .transcript__translation {
        margin-top: 0px;
      }
    }
  }

  .transcript__text {
    font-weight: 400;
    font-size: v-bind('fontSize + fontDim');
    line-height: 150%;
    word-break: break-word;

    &:first-letter {
      text-transform: uppercase;
    }
  }

  .transcript__time {
    position: absolute;
    right: 15px;
    font-weight: 400;
  }

  .transcript__sendername {
    font-weight: 700;
    margin-bottom: 10px;
    color: #fff;
    display: flex;
    align-items: center;
  }

  .transcript__flag {
    width: v-bind('fontSize * 1.2 + fontDim');
    height: v-bind('fontSize * 1.2 + fontDim');
    border-radius: 50%;
    display: flex;
    justify-content: center;
    overflow: hidden;
    margin-right: 10px;
    float: left;
  }

  .chevron-to-bottom-scroller {
    position: absolute;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    background: rgba(43, 43, 43, 0.8);
    right: 0;
    bottom: -26px;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      max-width: 70%;
    }

    .new-indicator {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      display: flex;
      background: #EA4335;
      overflow: hidden;
      position: absolute;
      top: -2px;
      right: 2px;
    }
  }

  .speech__wrap {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    /*@media all and (max-width: 768px) {
      display: none;
    }*/



    .keyboard-input-btn {
      width: 52px;
      cursor: pointer;

      @media all and (max-width: 1100px) {
        display: none;
      }

      img {
        width: 100%;
      }
    }

    .speech-mode-selector {
      cursor: pointer;
    }
  }

  .user-language-selector {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      overflow: hidden;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: 100%;
      }

      &--mobile {
        display: none;

        @media all and (max-width: 768px) {
          display: flex;
          width: 72px;
          height: 72px;
        }
      }
  }

  .room-modals {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 4;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .hidden-audio-players {
    display: none !important;
  }

  .pulsing {
    animation: micpulse 1.5s infinite;
  }

  .loader-4 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
  }
  .loader-4 .spinner {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .loader-4 .spinner .loader-circle-1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0.3rem #fff solid;
    border-radius: 50%;
    border-left: 0.3rem #46E0D4 solid;
    -webkit-animation: loaderspinner 1.5s infinite;
            animation: loaderspinner 1.5s infinite;
  }
  .loader-4 .spinner .loader-circle-2 {
    position: absolute;
    top: 10%;
    left: 10%;
    width: 80%;
    height: 80%;
    border: 0.3rem #fff solid;
    border-radius: 50%;
    border-left: 0.3rem #46E0D4 solid;
    -webkit-animation: loaderspinner 1.5s 200ms infinite;
            animation: loaderspinner 1.5s 200ms infinite;
  }
  .loader-4 .spinner .loader-circle-3 {
    position: absolute;
    top: 20%;
    left: 20%;
    width: 60%;
    height: 60%;
    border: 0.3rem #fff solid;
    border-radius: 50%;
    border-left: 0.3rem #46E0D4 solid;
    -webkit-animation: loaderspinner 1.5s 350ms infinite;
            animation: loaderspinner 1.5s 350ms infinite;
  }
  @-webkit-keyframes loaderspinner {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes loaderspinner {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  

  @media all and (max-height: 900px) {
    .screen {
      padding-top: 10px;
    }

    .screen__scroll {
      min-height: 480px;
      max-height: 480px;

      .scrollbar__content {
        min-height: 480px;
      }
    }

    .transcripts {

      &__item {
        margin-bottom: 16px;
        padding: 12px;
      }
    }

    .toolbar {
      padding: 0 16px 16px;
    }

    .conference-meta {
      top: 0;

      &.mobileMeta {
        z-index: 5;
      }
    }

    .mic {
      width: 100px;
      height: 100px;
    }

    .timer {
      margin-bottom: 0;
    }
  }

  @media all and (min-height: 1100px) {

    .screen__scroll {
      min-height: 850px;
      max-height: 850px;

      .scrollbar__content {
        min-height: 850px;
      }
    }
  }

  @media all and (max-height: 700px) {
    .screen {
      padding-top: 10px;
    }

    .screen__scroll {
      min-height: 300px;
      max-height: 300px;

      .scrollbar__content {
        min-height: 300px;
      }
    }
  }

  @media all and (max-width: 1100px) {
    .screen__scroll {
      min-height: 500px;
      max-height: 500px;

      .scrollbar__content {
        min-height: 500px;
      }
    }

    .meta-footer {
      background: #3E3E3E;
      border-bottom: 1px solid #C2C2C2;
      box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 16px;

      .conference-meta__title {
        font-size: 0.8rem;
        margin: 0;
      }

      .conference-meta__speaker {
        font-size: 0.75rem;
      }
    }
  }

  .mobile-meta-header {
    display: flex;
    justify-content: space-between;
    padding: 20px 16px;
    background: #202020;
    border-bottom: 1px solid #C2C2C2;
    box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.5);

    .mobile-info-btn {
      svg {
        width: 30px;
        height: 30px;
      }
    }
  }

  .forum-info-popup {
    font-family: "Inter";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    z-index: 9;
    background: #202020;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 32px;

    .conference-close {
      position: absolute;
      top: 16px;
      right: 16px;
    }

    .forum-info__text {
      color: #46E0D4;
      font-size: 1.2rem;
      text-align: center;
      margin-bottom: 32px;
    }

    .forum-info__links {
      display: flex;
      justify-content: space-between;
      margin-bottom: 32px;
    }

    .forum-info__footer {
      display: flex;
      flex-direction: column;
      row-gap: 24px;
      justify-content: center;
      text-align: center;

      b {
        color: #fff;
        font-size: 1.1rem;
      }

      span {
        color: #fff;
        font-size: 0.9rem;
        opacity: 0.6;
      }
    }
  }

  .loader {
    position: relative;
    overflow: hidden;
  }

  .default-03 .loader-container {
    width: 80px;
    height: 80px;
    animation: loaderspin 2.5s infinite cubic-bezier(0.17, 0.72, 0.55, 1.66);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .default-03 .ball {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    width: 11%;
    height: 50%;
    background-color: transparent;
    transform-origin: bottom center;
    overflow: visible;
  }
  .default-03 .ball:nth-of-type(2) {
    transform: translate(-50%, 0) rotate(36deg);
  }
  .default-03 .ball:nth-of-type(3) {
    transform: translate(-50%, 0) rotate(72deg);
  }
  .default-03 .ball:nth-of-type(4) {
    transform: translate(-50%, 0) rotate(108deg);
  }
  .default-03 .ball:nth-of-type(5) {
    transform: translate(-50%, 0) rotate(144deg);
  }
  .default-03 .ball:nth-of-type(6) {
    transform: translate(-50%, 0) rotate(180deg);
  }
  .default-03 .ball:nth-of-type(7) {
    transform: translate(-50%, 0) rotate(216deg);
  }
  .default-03 .ball:nth-of-type(8) {
    transform: translate(-50%, 0) rotate(252deg);
  }
  .default-03 .ball:nth-of-type(9) {
    transform: translate(-50%, 0) rotate(288deg);
  }
  .default-03 .ball:nth-of-type(10) {
    transform: translate(-50%, 0) rotate(324deg);
  }
  .default-03 .ball::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 56%;
    border-radius: 70px;
    background-color: #fff;
  }
  .default-03 .ball::after {
    position: absolute;
    content: "";
    bottom: -100;
    left: 0;
    width: 100%;
    height: 56%;
    border-radius: 70px;
    background-color: #ffc7bd;
    animation: default3 2.5s infinite linear;
    opacity: 0;
  }
  @keyframes loaderspin {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
  @keyframes default3 {
    20% {
      opacity: 1;
    }
    40% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  .scroll-area {
    position: relative;
    margin: auto;
    width: 600px;
    height: 400px;
  }

@keyframes micpulse
{
    0%
    {
        box-shadow: 0 0 0 0 rgba(255,255,255,.7), 0 0 0 0 rgba(255,255,255,.7);
    }
    
    100%
    {
        box-shadow: 0 0 0 0 rgba(255,255,255,.0), 0 0 0 100px rgba(255,255,255,0);
    }
}

/**
tutorial styles
*/

.shepherd-element {
  margin-top: -15px;
}

.shepherd-footer {
  align-items: center;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  justify-content: space-between;
  padding: 0 .75rem .75rem;
  
  .shepherd-button:last-child {
    margin-right: 0;
  }
    
  .shepherd-progress {
    font-size: .8rem;
    margin-right: auto;
    font-weight: bold;
  }

  .shepherd-button {
    background: #46E0D4;

    &:hover {
      background: #46E0D4 !important;
      opacity: 0.8;
    }
    
    &.shepherd-skip {
      background: #979797;
      
      &:hover {
        background: #979797 !important;
      }
    }

    
  }
}

.shepherd-element {
  border: 2px solid #46E0D4;
}

.shepherd-arrow:before {
  border: 2px solid #46E0D4;
    border-left: none;
    border-top: none;
}

#keyboard_input_field {
  caret-color: #000000 !important;
  outline: none !important;
  flex: 1;
  display: flex;
  word-break: break-word;
}

.clear,
.clear:before,
.clear:after{
	content:"";
	display:block;
	clear:both;
}

/* green screen */
  .room.chromakey {
    background: v-bind('settings.chroma_overlay_color');
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;

    .toolbar {
      display: none;
    }

    .screen__default {
      display: none;
    }

    .screen {
      overflow: hidden;
    }

    .screen__scroll {
      min-height: 90px;
      max-height: 90px;
      overflow: hidden;
      
      .scrollbar__content {
        min-height: 90px;
        overflow: hidden;
      }
    }

    .scrollbar__thumb--vertical {
      display: none;
    }

    .scrollbar__thumb {
      display: none;
    }

    .scrollbar__thumbPlaceholder {
      display: none;
    }

    .scrollbar__thumbPlaceholder--vertical {
      overflow: hidden;
      -ms-overflow-style: none;
      scrollbar-width: none;
      pointer-events: none;
      touch-action: none;
    }
    .scrollbar__thumbPlaceholder--vertical::-webkit-scrollbar {
      display: none;
    }

    .transcripts {
      &.narrower {
        padding-left: 20%;
        padding-right: 20%;
      }
    }

    .transcripts__item {
      text-align: v-bind('mode.align');
      background: transparent;
      display: inline-flex;
      align-items: flex-end;
      justify-content: v-bind(transcriptsJustify);
      margin-bottom: 0px;
      padding-bottom: 0px;
      padding-top: 0px;
      overflow: hidden;

      .text-clamp {
        overflow: hidden !important;
      }

      .text-clamp span span {
        background: #000000;
        padding: 0.5rem 1rem;
        filter: url('#goo');
        color: #FFFFFF;
        display: inline;
        box-decoration-break: clone;
        overflow: hidden;
        font-weight: 400;
        font-size: v-bind('fontSize + fontDim');
        line-height: 150%;
        word-break: break-word;

        &:first-letter {
          text-transform: uppercase;
        }
      }
    }

    .transcripts__item--partial--incoming {
      margin-top: -36px;
    }

    .cc-toolbar {
      background: #000000;

      svg path, svg rect {
        fill: white;
      }
    }
  }

  .chromakey-off {
    position: absolute;
    top: 3%;
    left: 3%;
    width: 40px;
    opacity: 0.5;
    z-index: 1000;

    img {
      width: 100%;
    }
  }

  /**
  Close Captioning
   */

  .room.cc {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;

    .room-overlay {
      background: #1E1E1E;
    }

    .toolbar {
      display: none;
    }

    .screen__default {
      display: none;
    }

    .transcripts {

      &.narrower {
        padding-left: 20%;
        padding-right: 20%;
      }

      &:before {
        content: '';
        z-index: 4;
        position: absolute;
        top: 0; left: 0; right: 0;
        height: 300px;

        background: -webkit-linear-gradient(
                rgba(30, 30, 30, 1),
                rgba(30, 30, 30, 0)
        );

        background: -moz-linear-gradient(
                rgba(30, 30, 30, 1),
                rgba(30, 30, 30, 0)
        );

        background: linear-gradient(
                rgba(30, 30, 30, 1),
                rgba(30, 30, 30, 0)
        );
      }
    }

    .transcripts__item {
      text-align: v-bind('mode.align');
      background: transparent;
      display: inline-flex;
      align-items: flex-end;
      justify-content: v-bind(transcriptsJustify);
      color: #D9D9D9;
      padding: 0;

      .text-clamp {
        overflow: visible !important;
      }

      .text-clamp span span {
        background: #000000;
        padding: 0.5rem 1rem;
        filter: url('#goo');
        color: #FFFFFF;
        display: inline;
        box-decoration-break: clone;

        font-weight: 400;
        font-size: v-bind('fontSize + fontDim');
        line-height: 150%;
        word-break: break-word;

        &:first-letter {
          text-transform: uppercase;
        }
      }

      &--final {
        opacity: 0.4;

        &:last-of-type {
          opacity: 1;

          .transcript__text {
            font-size:  v-bind('fontSize * 1.4 + fontDim');
          }
        }
      }

      &--partial {
        .transcript__text {
          font-size:  v-bind('fontSize * 1.4 + fontDim');
        }
      }
    }

    .transcripts__item--partial--incoming {
      margin-bottom: 50px;
    }

    .session-meta {
      position: absolute;
      left: 5%;
      top: 5%;
      display: flex;
      color: #D7D6D6;
      font-size: 30px;
      align-items: center;
      gap: 5px;

      &__title {
        font-weight: bold;
      }

      &__speaker {
      }
    }

    .toolbar-divider {
      color: #D7D6D6;
      font-size: 30px;
    }

    .incoming-line {
      height: 3px;
      width: 100%;
      background: #46E0D4;
      opacity: 0.2;
      transition: all 50ms linear;

      &.incoming {
        opacity: 1;
      }
    }

    &.black {
      background: #1E1E1E;
    }

    &.white {
      background: #FFFFFF;

      .cc-toolbar {
        background: #FFFFFF;

        svg path, svg rect {
          fill: black;
        }
      }

      .toolbar-divider {
        color: black;
      }

      .transcript__text, .session-meta {
        color: black;
      }

      .transcripts {
        &:before {
          content: '';
          z-index: 4;
          position: absolute;
          top: 0; left: 0; right: 0;
          height: 300px;

          background: -webkit-linear-gradient(
                  rgba(255, 255, 255, 1),
                  rgba(255, 255, 255, 0)
          );

          background: -moz-linear-gradient(
                  rgba(255, 255, 255, 1),
                  rgba(255, 255, 255, 0)
          );

          background: linear-gradient(
                  rgba(255, 255, 255, 1),
                  rgba(255, 255, 255, 0)
          );
        }
      }
    }

    .transcript__text {

    }

    .screen__scroll .scrollbar__content, .screen__scroll {
      height: calc(100vh - 120px);
      min-height: auto !important;
      max-height: none !important;
    }


  }

  .cc-toolbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #1E1E1E;
    height: 150px;
    z-index: 500;
    opacity: 0;
    transition: all 100ms linear;

    &.shown {
      opacity: 1;
    }

    &:hover {
      opacity: 1;
    }

    &-inner {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 40px;
    }

    &-item {
      display: flex;
      align-items: center;
      gap: 20px;
    }

    &-btn {
      &:active {
        opacity: 0.8;
      }
    }

    .cc-toolbar-exit-btn {
      img {
        height: 40px;
        width: 40px;
      }
    }

    .cc-toolbar-font-btn--decrease {
      img, svg {
        width: 24px;
        height: 24px;
      }
    }
  }
</style>
