export const hidden = {
    namespaced: true,
    state: {
        hidden_settings: {
            asr_version: "v1",
            autostop_timeout: 1800,
            wait_k: 1,
        },
    },
    actions: {
        setHiddenSettings({ commit }, settings) {
            commit('setHiddenSettings', settings);
            return Promise.resolve(settings);
        }
    },
    getters: {},
    mutations: {
        setHiddenSettings(state, settings) {
            state.hidden_settings = settings;
        },
        setAutostopTimeout(state, seconds) {
            state.hidden_settings.autostop_timeout = seconds;
        }
    }
}