<template>
  <div class="overlay">
    <video-background 
            src="/media/output.mp4"
            style="height: 100%;"
        >
        <div class="overlay__bg"></div>
  </video-background>
  </div>
  <div class="signin flex-centered flex-centered--home">
    <div class="center-logo">
      <img src="@/assets/sorenson-forum-logo.svg" alt="Sorenson">
    </div>
    <div class="signin__container auto-width black-centered orange-bordered">
      <header class="black-centered-header">
        <svg width="56" height="57" viewBox="0 0 56 57" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="confirmSuccess">
          <g id="icons/general/check-circle" clip-path="url(#clip0_582_807)">
            <g id="Vector">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M28 7.5C16.402 7.5 7 16.902 7 28.5C7 40.098 16.402 49.5 28 49.5C39.598 49.5 49 40.098 49 28.5C49 16.902 39.598 7.5 28 7.5ZM2.33333 28.5C2.33333 14.3247 13.8247 2.83333 28 2.83333C42.1753 2.83333 53.6667 14.3247 53.6667 28.5C53.6667 42.6753 42.1753 54.1667 28 54.1667C13.8247 54.1667 2.33333 42.6753 2.33333 28.5ZM40.1499 19.8501C41.0611 20.7613 41.0611 22.2387 40.1499 23.1499L26.1499 37.1499C25.2387 38.0611 23.7613 38.0611 22.8501 37.1499L15.8501 30.1499C14.9389 29.2387 14.9389 27.7613 15.8501 26.8501C16.7613 25.9389 18.2387 25.9389 19.1499 26.8501L24.5 32.2002L36.8501 19.8501C37.7613 18.9389 39.2387 18.9389 40.1499 19.8501Z" fill="white" fill-opacity="0.75"/>
            </g>
          </g>
          <defs>
            <clipPath id="clip0_582_807">
              <rect width="56" height="56" fill="white" transform="translate(0 0.5)"/>
            </clipPath>
          </defs>
        </svg>


        <svg width="56" height="56" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-else-if="confirmError">
          <g clip-path="url(#clip0_1888_30)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM8.29289 8.29289C8.68342 7.90237 9.31658 7.90237 9.70711 8.29289L12 10.5858L14.2929 8.29289C14.6834 7.90237 15.3166 7.90237 15.7071 8.29289C16.0976 8.68342 16.0976 9.31658 15.7071 9.70711L13.4142 12L15.7071 14.2929C16.0976 14.6834 16.0976 15.3166 15.7071 15.7071C15.3166 16.0976 14.6834 16.0976 14.2929 15.7071L12 13.4142L9.70711 15.7071C9.31658 16.0976 8.68342 16.0976 8.29289 15.7071C7.90237 15.3166 7.90237 14.6834 8.29289 14.2929L10.5858 12L8.29289 9.70711C7.90237 9.31658 7.90237 8.68342 8.29289 8.29289Z" fill="red"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12ZM8.29289 8.29289C8.68342 7.90237 9.31658 7.90237 9.70711 8.29289L12 10.5858L14.2929 8.29289C14.6834 7.90237 15.3166 7.90237 15.7071 8.29289C16.0976 8.68342 16.0976 9.31658 15.7071 9.70711L13.4142 12L15.7071 14.2929C16.0976 14.6834 16.0976 15.3166 15.7071 15.7071C15.3166 16.0976 14.6834 16.0976 14.2929 15.7071L12 13.4142L9.70711 15.7071C9.31658 16.0976 8.68342 16.0976 8.29289 15.7071C7.90237 15.3166 7.90237 14.6834 8.29289 14.2929L10.5858 12L8.29289 9.70711C7.90237 9.31658 7.90237 8.68342 8.29289 8.29289ZM12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1Z" fill="red"/>
          </g>
          <defs>
            <clipPath id="clip0_1888_30">
              <rect width="24" height="24" fill="white"/>
            </clipPath>
          </defs>
        </svg>

        <svg class="rotating" width="56" height="56" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-else>
          <g clip-path="url(#clip0_503_10428)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 1C12.5523 1 13 1.44772 13 2V6C13 6.55228 12.5523 7 12 7C11.4477 7 11 6.55228 11 6V2C11 1.44772 11.4477 1 12 1ZM4.21447 4.29284C4.60499 3.90231 5.23815 3.90231 5.62868 4.29284L8.45711 7.12126C8.84763 7.51179 8.84763 8.14495 8.45711 8.53548C8.06658 8.926 7.43342 8.926 7.04289 8.53548L4.21447 5.70705C3.82394 5.31652 3.82394 4.68336 4.21447 4.29284ZM19.7855 4.29284C20.1761 4.68336 20.1761 5.31652 19.7855 5.70705L16.9571 8.53548C16.5666 8.926 15.9334 8.926 15.5429 8.53548C15.1524 8.14495 15.1524 7.51179 15.5429 7.12126L18.3713 4.29284C18.7618 3.90231 19.395 3.90231 19.7855 4.29284ZM1 12C1 11.4477 1.44772 11 2 11H6C6.55228 11 7 11.4477 7 12C7 12.5523 6.55228 13 6 13H2C1.44772 13 1 12.5523 1 12ZM17 12C17 11.4477 17.4477 11 18 11H22C22.5523 11 23 11.4477 23 12C23 12.5523 22.5523 13 22 13H18C17.4477 13 17 12.5523 17 12ZM8.45711 15.5429C8.84763 15.9334 8.84763 16.5666 8.45711 16.9571L5.62868 19.7855C5.23815 20.1761 4.60499 20.1761 4.21447 19.7855C3.82394 19.395 3.82394 18.7618 4.21447 18.3713L7.04289 15.5429C7.43342 15.1524 8.06658 15.1524 8.45711 15.5429ZM15.5429 15.5429C15.9334 15.1524 16.5666 15.1524 16.9571 15.5429L19.7855 18.3713C20.1761 18.7618 20.1761 19.395 19.7855 19.7855C19.395 20.1761 18.7618 20.1761 18.3713 19.7855L15.5429 16.9571C15.1524 16.5666 15.1524 15.9334 15.5429 15.5429ZM12 17C12.5523 17 13 17.4477 13 18V22C13 22.5523 12.5523 23 12 23C11.4477 23 11 22.5523 11 22V18C11 17.4477 11.4477 17 12 17Z" fill="white" fill-opacity="0.75"/>
          </g>
          <defs>
            <clipPath id="clip0_503_10428">
              <rect width="24" height="24" fill="white"/>
            </clipPath>
          </defs>
        </svg>
      </header>
      <div class="black-centered-content">
        <h1>{{ heading }}</h1>
        <p v-html="text" v-if="!confirmError"></p>
        <p v-else><b>{{ $t('common_error')}} {{ error }}</b></p>
        <router-link v-if="confirmSuccess" to="/profile" class="btn">{{ $t('common_get_started')}}</router-link>
      </div>
    </div>
  </div>
  <main-footer></main-footer>
  
</template>

<script>
// @ is an alias to /src
import VideoBackground from 'vue-responsive-video-background-player';
import MainFooter from '@/components/MainFooter.vue';
import AuthService from '../services/auth.service';

export default {
  name: 'ForgotPwdView',
  components: {
    VideoBackground,
    MainFooter
  },
  data() {
    return {
      user: {},
      errors: {},
      confirmSuccess: false,
      confirmError: false,
      error: false,
      token: "",
      code: "",
      email: ""
    }
  },
  methods: {
  },
  computed: {
    heading: function () {
      return this.confirmSuccess ? this.$t('profile_account_activated') : this.$t('profile_account_activation_ongoing');
    },
    text: function () {
      return this.confirmSuccess ? this.$t('profile_account_activated_text') : this.$t('profile_account_activation_ongoing_text');
    }
  },
  mounted() {
    this.token = this.$route.query.token;
    this.code = this.$route.query.code;
    this.email = this.$route.query.email;

    AuthService.confirmAccount(this.code, this.token, this.email).then(
        (response) => {
          const token = response.data.result.token;
          this.$store.dispatch("auth/userMe", {
            token: token,
            room: false
          }).then(() => {
            this.confirmSuccess = true;
          });
        },
        (error) => {
          if (error.response.data.error === "user_is_already_activated") {
            this.confirmSuccess = true;
          } else {
            this.confirmError = true;
            this.error = error.response.data.error;
          }
        }
    );
  }
}
</script>

<style lang="scss">
  .black-centered {
    background: var(--layer-1, #0F161A) !important;
    border-radius: 20px;
    border: 1px solid var(--stroke-separator, rgba(255, 255, 255, 0.08)) !important;
    background: var(--layer-1, #0F161A);

    &-header {
      margin-bottom: 28px;
    }

    &-content {
      text-align: center;

      h1 {
        color: #FFFFFF;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px; /* 125% */
        letter-spacing: -0.48px;
        margin: 0 8px;
      }

      p {
        color: rgba(255, 255, 255, 0.75);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
      }
    }
  }

  .center-logo {
    max-width: 90%;

    img {
      max-width: 100%;
    }
  }
</style>
