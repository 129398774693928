import api from "@/services/api.service";

class LanguagesService {

  getArray(asr_version = "v1") {
    const asrVersion = asr_version === 'v1' ? 1 : 2;
    return api
        .get('/system-supported-languages', {
          params: {
            version: asrVersion
          }
        });
  }
}
export default new LanguagesService();