<template>
  <div class="wrapper" :class="[appClass, { 'bg-[#12181E]': isDarkMode, '!h-auto': isPricingPage }]" :style="{ zoom: zoom }">
      <main-header v-if="!isWebsite"></main-header>
      <mobile-header v-else-if="$isMobile() && isJoin"></mobile-header>
      <website-header v-else-if="isWebsite" :isDarkMode="isDarkMode"></website-header>
      
      <router-view v-if="prefetched"/>

      <hidden-menu v-if="hiddenMenuShown" :default-settings="hidden_settings" @closed="hiddenMenuClosed" @saved="hiddenMenuSaved"/>
  </div>
</template>

<script>
  import MainHeader from '@/components/MainHeader.vue';
  import MobileHeader from '@/components/mobile/MobileHeader.vue';
  import WebsiteHeader from '@/components/Website/WebsiteHeader.vue';
  import HiddenMenu from "@/components/HiddenMenu.vue";
  import { mapState } from 'vuex';
  import { loadLanguageAsync } from "@/plugins/i18n"; // eslint-ignore
  import hotkeys from 'hotkeys-js';

  export default {
    components: {
      MainHeader,
      MobileHeader,
      WebsiteHeader,
      HiddenMenu,
    },
    data() {
      return {
        zoom: 1,
        hiddenMenuShown: false,
      }
    },
    methods: {
      initBeaconJS() {
        !function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});
        window.Beacon('init', process.env.VUE_APP_BEACON_ID)
      },
      hiddenMenu() {
        this.hiddenMenuShown = !this.hiddenMenuShown;
      },
      hiddenMenuClosed() {
        this.hiddenMenuShown = false;
      },
      hiddenMenuSaved() {
        this.hiddenMenuClosed();
        location.reload(); // we reload the page so that network request go through again if values changed in dev menu
      }
    },
    computed: {
      ...mapState('auth', ['agreed', 'authorized', 'token']),
      ...mapState('nonpersist', ['user', 'prefetched']),
      ...mapState('locale', ['availableLocales', 'locale']),
      ...mapState('hidden', ['hidden_settings']),
      appClass() {
        let wrapperClass = "overlay-page";
        if (this.$route.meta && this.$route.meta.tpl) {
          wrapperClass = this.$route.meta.tpl;
        }
        return wrapperClass;
      },
      isMobile() {
        return screen.width < 1200
      },
      isWebsite() {
        return this.$route.meta.tpl && this.$route.meta.tpl == 'website';
      },
      isJoin() {
        return this.$route.name == 'join';
      },
      isDarkMode() {
        return ['plans'].includes(this.$route.name)
      },
      isPricingPage() {
        return ['plans'].includes(this.$route.name)
      }
    },
    async created() {
      await loadLanguageAsync(this.$i18n.locale);
      if (this.token) {
        await this.$store.dispatch("auth/userMe", {
          token: this.token,
          room: false
        })
      } else {
        this.$store.commit("nonpersist/setPrefetched", true);
      }

      await this.$store.dispatch("lang/get");
      const browserZoomLevel = Math.round(window.devicePixelRatio * 100);
      const wWidth = window.outerWidth;
      if (wWidth > 1024 && browserZoomLevel != 100 && browserZoomLevel != 200) {
        console.log(browserZoomLevel);
        this.zoom = 100 / browserZoomLevel;
      }
    },
    async mounted() {
      hotkeys('shift+alt+k, shift+command+k', () => this.hiddenMenu());
      this.initBeaconJS();

      const shortLang = this.locale ? this.locale : "en";

      window.pendo.identify({
        visitor: {
          id: this.user.email,
          "forum_language": shortLang,
        },
        account: {}
      });
    }
  }
</script>

<style lang="scss">
  :root {
    --toggle-width: 47px;
    --toggle-height: 27px;
    --toggle-bg-on: #46E0D4;
    --toggle-bg-off: #e5e7eb;
    --toggle-border-on: #46E0D4;
    --toggle-text-off: transparent;
    --toggle-text-on: transparent;

    --popper-theme-background-color: rgba(107, 107, 107, 1);
    --popper-theme-background-color-hover: #333333;
    --popper-theme-text-color: #ffffff;
    --popper-theme-border-width: 0px;
    --popper-theme-border-style: solid;
    --popper-theme-border-radius: 10px;
    --popper-theme-padding: 15px 10px;
    --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
  }

  * {
    box-sizing: border-box;
  }

  body {
    font-family: 'Inter', sans-serif;
  }

  a {
    text-decoration: none;
  }

  html, body {
    height: 100%;
  }

  html {
    font-size: 16px;
  }

  #app {
    height: 100%;

    @media all and (max-width: 1100px) {
      overflow: hidden;
    }
  }

  .wrapper {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;

    &.conference {
      background: rgba(0, 0, 0, 0.85);
    }
  }

  .flex-centered {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 100%;

    &--home {
      justify-content: center;

      gap: 56px;
    }
  }

  .flex-column {
    display: flex;
    flex-direction: column;
  }

  .flex-row {
    display: flex;
    flex-direction: row;
  }

  .orange-link {
    color: #46E0D4;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.875rem;
    display: flex;
    align-items: center;

    img {
      margin-right: 15px;
    }

    &.orange-link--inline {
      text-transform: none;
      font-family: 'Inter';
      font-weight: 400;
      justify-content: center;
    }
  }

  .orange-btn {
    min-width: 412px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 1.875rem;
    line-height: 2.8rem;
    text-align: center;
    padding: 0 30px;

    color: #FFFFFF;
    background: #46E0D4;
    box-shadow: 0px 5.01333px 5.01333px rgba(0, 0, 0, 0.25);
    border-radius: 47.6267px;
    height: 94px;
    cursor: pointer;

    img {
      margin-right: 20px;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  .or-divider {
    text-align: center;
    color: #FFFFFF;
    font-size: 1rem;
    line-height: 1.2rem;
    font-family: 'Inter';
    margin-top: 21px;
    margin-bottom: 14px;
  }

  button:disabled,
  button[disabled] {
    opacity: 0.5;
    touch-action: none;
    pointer-events: none;
    cursor: default;
  }

  .text-page {
    background: #202020;

    .overlay {
      display: none;
    }
  }

  .text-content {
    max-width: 800px;
    margin: 0 auto;
    color: #FFFFFF;
    height: 100%;
    overflow: auto;
  }

  .text-transform-none {
    text-transform: none !important;
  }

  .hidden-radio {
    position: absolute;
    opacity: 0;
  }

  .margined-submit {
    margin-top: 100px;
  }

  .back-arrow-btn {
    color: #46E0D4;
    font-size: 1rem;
    line-height: 1.18rem;
    text-decoration: none;
    padding-left: 20px;
    background: url(@/assets/icons/arrow-b.svg) left center no-repeat;
  }

  .padded-top-block {
    padding-top: 70px !important;

    @media all and (max-height: 800px) {
      padding-top: 35px !important;
    }
  }

  .centered-buttons {
    display: flex;
    justify-content: center;
    flex-direction: column;

    .back-arrow-btn {
      margin: 30px auto 0;
    }
  }


  .ps__rail-y, .ps__thumb-y {
    z-index: 100;
  }

  .transparenthidden {
    opacity: 0 !important;
  }

  .toggle-container:focus {
    box-shadow: none !important;
    outline: none;
  }

  .danger-btn {
    color: #EA4335;
    text-decoration: none;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;

    img {
      max-width: 20px;
      margin-right: 10px;
    }
  }

  .languages-scroll {
    height: 450px;
    border: 1px solid #505050;
    border-radius: 10px;
    padding: 0 10px 0 10px;
    background: #1D1C1C;
    position: relative;
  }

  .languages-scroll-light {
    background: #fff;
    border: 2px solid #505050;
    border-radius: 10px;
    height: 400px;

    .root-list__item {
      padding-left: 10px;

      &.all-language {
        padding-left: 16px;
      }
    }
  }

  .profile-scroll{
    height: 450px;
  }

  .scrollbar__thumbPlaceholder {
    right: -20px !important;
  }

  .start-slider img {
    max-width: 110px;
  }

  /**
  overriding plugin styles
   */

  html body.swal2-height-auto {
    height: 100% !important;
  }

  .swal2-popup.swal2-toast {
    border: 3px solid #46E0D4;

    .swal2-html-container {
      font-weight: 500;
    }
  }

  html.swal2-toast-shown .swal2-container {
    box-sizing: border-box;
    width: 360px;
    max-width: 100%;
    pointer-events: none;
  }

  body {
    .swal2-styled.swal2-confirm {
      background: #46E0D4;

      &:focus {
        box-shadow: 0 0 0 3px rgb(235 149 25 / 50%);
      }
    }
  }

  /** responsive */

  @media all and (max-height: 800px) {
    html {
      font-size: 12px;
    }

    .orange-bordered {
      height: 535px;
      width: 500px;
      padding: 30px 26px 30px;
    }

    .start {

      &-slider__item {
        img {
          max-width: 87px;
          margin-bottom: 30px !important;
        }
      }

      &__options {
        margin-top: 40px;
      }
    }

    .margined-submit {
      margin-top: 36px !important;
    }

    .languages-scroll, .profile-scroll {
      height: 350px;
    }
  }

  @media all and (max-width: 1100px) {
    .languages-scroll {
      height: 100% !important;
      border: none;
    }
  }

  .scrollbar__thumb{
    background-color: #aaa !important;
    border-radius: 6px;
    transition: background-color .2s linear, width .2s ease-in-out;
    -webkit-transition: background-color .2s linear, width .2s ease-in-out;
  }

  .conferenceBody #beacon-container {
    display: none !important;
  }

  .host .conference-close, .start .conference-close, .profile .conference-close {
    position: absolute;
    top: -7%;
    right: -7%;
  }


</style>
