<template>
  <div class="hidden-menu-wrap">
    <a href="javascript:;" class="icon-btn hidden-menu-close" @click="selfClose"><img src="@/assets/icons/xmark.svg" alt="Close"></a>
    <div class="hidden-menu">
      <h2>Advanced Dev Settings</h2>
      <custom-scrollbar  class="hidden-menu-scroll" :autoHide="false">
        <div class="settings-item">
          <div class="table-item">
            <div class="table-item__left">ASR Version</div>
            <div class="table-item__right">
              <Toggle class="toggle-with-labels" v-model="settings.asr_version" offLabel="v1" onLabel="v2" trueValue="v2" falseValue="v1" />
            </div>
          </div>
        </div>
        <div class="settings-item">
          <div class="table-item">
            <div class="table-item__left">Recording auto-stop timeout (seconds)</div>
            <div class="table-item__right">
              <input type="number" class="text-input" v-model="settings.autostop_timeout" />
            </div>
          </div>
        </div>
        <div class="settings-item">
          <div class="table-item">
            <div class="table-item__left">ASR wait_k parameter</div>
            <div class="table-item__right">
              <input type="number" class="text-input" v-model="settings.wait_k" />
            </div>
          </div>
        </div>
      </custom-scrollbar>
      <div class="">
        <a href="javascript:;" class="orange-btn form-btn" @click="selfCommit">{{ $t('common_save') }}</a>
      </div>
    </div>
    <div class="hidden-menu-overlay" @click="selfClose"></div>
  </div>
</template>

<script>
// @ is an alias to /src
import Toggle from '@vueform/toggle'

export default {
  name: 'HiddenMenu',
  data() {
    return {
      settings: this.defaultSettings,
    }
  },
  props: {
    defaultSettings: {
      type: Object,
      required: true
    },
  },
  components: {
    Toggle,
  },
  methods: {
    selfClose() {
      this.$emit('closed');
    },
    selfCommit() {
      this.$store.commit("hidden/setHiddenSettings", this.settings);

      this.$emit('saved', {});
    },
  },
  computed: {},
  mounted() {
    console.log(this.defaultSettings)
  }
}
</script>

<style src="@vueform/toggle/themes/default.css"></style>

<style lang="scss">
  .hidden-menu-overlay {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgb(0, 0, 0);
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.6727065826) 60%, rgb(0, 0, 0) 97%, rgb(0, 0, 0) 100%);
    z-index: 4;
  }

  .hidden-menu-close {
    position: absolute;
    z-index: 5;
    top: 30px;
    right: 30px;
  }

  .hidden-menu-wrap {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
  }

  .hidden-menu {
    background: #505050;
    border-radius: 35px;
    width: 60%;
    padding: 30px;
    position: absolute;
    z-index: 6;

    h2 {
      color: #FFFFFF;
    }

    .hidden-menu-scroll {
      height: 30rem;
      padding: 0;
    }
  }

  .table-item {}
</style>
