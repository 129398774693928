<template>
  <div class="page-wrapper page-wrapper--about">
    <div class="about-text">
      <h2 class="text-2xl text-black">App version:
        <span class="question__title text-black text-xl">{{ clientVersion }}</span></h2>
    </div>
  </div>
</template>

<script>
import { version } from "/package.json";
export default {
  name: 'VersionView',
  components: {

  },
  data() {
    return {
      clientVersion: version
    }
  }
}
</script>

<style lang="scss" scoped>
.page-wrapper {
  padding-top: 5rem;
  display: flex;
  flex-direction: column;

  &--about {
    justify-content: center;
    align-items: center;
    width: 70%;
    margin: 0 auto;
  }

}
</style>
