<template>
  <div :class="['grow shrink basis-0', cardClass, { 'border-[#ff78b2]': highlighted, 'border-white/10': !highlighted, 'hover:border-teal-400/50': !highlighted, 'hover:border-[#ff78b2]': highlighted }]" class="pricing-card-root rounded-[20px] border flex-col justify-start items-start inline-flex relative w-full md:w-fit h-fit hover:scale-101 hover:shadow-[0_0_30px_rgba(45,212,191,0.1)] transition-all duration-200 overflow-hidden group">
    <div v-if="highlighted" class="self-stretch h-14 flex-col justify-start items-start gap-3 flex overflow-hidden">
      <div class="self-stretch h-14 rounded-lg flex-col justify-center items-center gap-2 flex">
        <div class="self-stretch px-8 py-4 bg-[#ff78b2] justify-center items-center gap-2 inline-flex">
          <div class="text-black/90 text-base font-medium font-['Inter'] leading-normal">{{ recommended ? 'RECOMMENDED' : 'CURRENT'}}</div>
        </div>
      </div>
    </div>
    <div :class="['self-stretch', { 'rounded-t-[0px]': highlighted }]" class="p-6 bg-white/5 backdrop-blur-2xl flex-col justify-start items-start gap-6 flex h-[388px] w-full md:w-[322px] rounded-[20px]">
      <div class="self-stretch flex-col justify-start items-start gap-6 flex">
        <div class="self-stretch flex-col justify-start items-start flex">
          <div class="self-stretch justify-start items-start inline-flex">
            <div class="grow shrink basis-0 flex-col justify-start items-start inline-flex">
              <div class="text-white text-3xl font-semibold font-['Inter'] leading-[38px]">{{ title }}</div>
            </div>
          </div>
          <div class="w-[274px] text-white/75 text-sm font-medium font-['Inter'] leading-tight mt-[8px]">{{ description }}</div>
          <div class="h-[30px] flex-col justify-start items-start gap-4 inline-flex mt-[16px]">
            <div class="self-stretch text-white text-[19px] font-semibold font-['Inter'] leading-[30px]">{{ price }}</div>
          </div>
        </div>
      </div>
      <div class="self-stretch h-[0px] border border-white/10"></div>
      <div class="self-stretch h-[172px] flex-col justify-start items-start gap-4 flex">
        <slot name="features"></slot>
      </div>
      <div class="self-stretch h-[98px] flex-col justify-start items-start gap-4 flex">
        <button @click="onClick" :disabled="disabled" class=" hover:bg-[#c1f7ec] self-stretch px-5 py-2.5 bg-[#66ebe0] rounded-xl justify-center items-center gap-2 inline-flex overflow-hidden disabled:bg-[#ffffff0d] disabled:text-white text-black text-base font-medium font-['Inter'] leading-normal duration-160" :class="{ 'cursor-not-allowed': disabled, 'cursor-pointer': !disabled }">
          {{ buttonText }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PricingCard',
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    price: {
      type: String,
      required: true
    },
    buttonText: {
      type: String,
      required: true
    },
    features: {
      type: Array,
      required: true
    },
    recommended: {
      type: Boolean,
      default: false
    },
    current: {
      type: Boolean,
      default: false,
    },
    cardClass: {
      type: String,
      default: ''
    },
    onClick: {
      type: Function,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    highlighted() {
      return this.recommended || this.current
    }
  }
}
</script>

<style scoped>
/* Add any additional styles if needed */
</style>
